import {
  AccountNumberType,
  AccountType,
  addBankAccount,
  AddBankAccountState,
  BankAccountAssignment,
  BankAccountConstants,
  BankIdType,
  changeContributionAccount,
  changeDistributionAccount,
  hasPendingContribution,
  hasPendingDistribution,
  IBaseStore,
  nothing,
  openAlert,
  selectBankAccountCountries,
  selectBankAccountCurrencies,
  some,
  UserAddedBankAccount,
} from "common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { ElectionDialog } from "../../Elections/ElectionWorkflow/Shared/ElectionDialog/ElectionDialog";
import { AddBankAccountForm } from "./AddBankAccountForm/AddBankAccountForm";

export interface IAddBankAccountDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isContribution: boolean;
}

export const AddBankAccountDialog = (props: IAddBankAccountDialogProps) => {
  const { open, setOpen, isContribution } = props;

  const bankAccountCountries = useSelector((state: IBaseStore) =>
    selectBankAccountCountries(state)
  );

  const bankAccountCurrencies = useSelector((state: IBaseStore) =>
    selectBankAccountCurrencies(state)
  );

  const usCountryName = useMemo(() => {
    const usCountry = bankAccountCountries.find(
      (country) => country.id === BankAccountConstants.US_COUNTRY_ID_NUMBER
    );
    return usCountry ? usCountry.name : "";
  }, [bankAccountCountries]);

  const usCurrencyCode = useMemo(() => {
    const usCurrency =
      bankAccountCurrencies.find(
        (currency) =>
          currency.id === BankAccountConstants.USD_CURRENCY_ID_NUMBER
      ) ?? "";
    return usCurrency ? usCurrency.currencyCode : "";
  }, [bankAccountCurrencies]);

  const DEFAULT_ADD_ACCOUNT_VALUES: AddBankAccountState = useMemo(() => {
    return {
      bankAccountUniqueId: "",
      accountHolderName: "",
      accountType: AccountType.CHECKING,
      country: usCountryName,
      currency: usCurrencyCode,
      accountNumberType: AccountNumberType.ACCOUNT_NUMBER,
      accountNumber: "",
      bankIdType: BankIdType.ABA_ROUTING_NUMBER,
      bankId: "",
      confirmAccountId: "",
      bankName: "",
      hasIntermediaryAccount: false,
      intermediaryAccount: {
        bankAccountUniqueId: "",
        accountHolderName: "",
        accountType: AccountType.INTERMEDIARY,
        accountNumberType: AccountNumberType.ACCOUNT_NUMBER,
        accountNumber: "",
        bankIdType: BankIdType.ABA_ROUTING_NUMBER,
        bankId: "",
        confirmAccountId: "",
        country: "",
        currency: "",
        bankName: "",
      },
    };
  }, [usCountryName, usCurrencyCode]);

  const addBankAccountForm = useForm<AddBankAccountState>({
    mode: "onSubmit",
    defaultValues: DEFAULT_ADD_ACCOUNT_VALUES,
  });

  // needed to ensure default values are set to country and currency properly
  useEffect(() => {
    addBankAccountForm.reset(DEFAULT_ADD_ACCOUNT_VALUES);
  }, [DEFAULT_ADD_ACCOUNT_VALUES, addBankAccountForm]);

  const dispatch = useDispatch();

  // needed to ensure isDirty field is tracked properly
  addBankAccountForm.watch();

  const hasPendingContributionAccount = useSelector(hasPendingContribution);
  const hasPendingDistributionAccount = useSelector(hasPendingDistribution);

  /*
  helper function to determin if an added account is eligible for both assignments, according
    to the following rules:

    - If adding Distributions account and 
      - New Account is eligible for Contributions(is US checking account with no linked account) and
      - Contributions account is required(selected yes on has US bank account question) and
      - Contributions account is not under treasury review(no pending contribution accounts)
    - If adding Contributions account and
      - Distributions account is not under treasury review(no pending distribution accounts)
  */
  const getIsEligibleForBothAssignments = useCallback(
    (userAddedAccount: AddBankAccountState) => {
      if (isContribution && !hasPendingDistributionAccount) {
        return true;
      }
      if (!isContribution) {
        const isEligibleForContribution =
          userAddedAccount.accountType === AccountType.CHECKING &&
          userAddedAccount.country === usCountryName &&
          userAddedAccount.currency === usCurrencyCode;

        return (
          isEligibleForContribution &&
          !hasPendingContributionAccount &&
          !userAddedAccount.hasIntermediaryAccount
        );
      }
      return false;
    },
    [
      isContribution,
      hasPendingContributionAccount,
      hasPendingDistributionAccount,
      usCountryName,
      usCurrencyCode,
    ]
  );

  const [confirmClose, setConfirmClose] = useState<boolean>(false);

  // Only runs when there are pending changes, and
  // the user cancels the confirmation clicking "No"
  const cancelClose = () => {
    setConfirmClose(false);
  };

  const closeDialog = useCallback(() => {
    setOpen(false);
    addBankAccountForm.reset();
    setConfirmClose(false);
  }, [addBankAccountForm, setOpen]);

  // If there are any changes, confirmation state triggers and the modal
  // requires the user to confirm its closing. Otherwise it closes immedately.
  const handleClose = useCallback(() => {
    if (addBankAccountForm.formState.isDirty) {
      if (confirmClose) {
        closeDialog();
      } else {
        setConfirmClose(true);
      }
    } else {
      closeDialog();
    }
  }, [addBankAccountForm.formState.isDirty, closeDialog, confirmClose]);

  const handleContinue = useCallback(async () => {
    const convertAddedAccount = (
      userAddedAccount: AddBankAccountState,
      isElgibleForBothAssignments: boolean
    ): UserAddedBankAccount => {
      return {
        main: {
          ...userAddedAccount,
          bankAccountUniqueId: crypto.randomUUID(),
        },
        intermediary: userAddedAccount.hasIntermediaryAccount
          ? some(userAddedAccount.intermediaryAccount)
          : nothing,
        assignment: isContribution
          ? BankAccountAssignment.CONTRIBUTION
          : BankAccountAssignment.DISTRIBUTION,
        eligibleForBothAssignments: isElgibleForBothAssignments,
      };
    };

    const valid = await addBankAccountForm.trigger();

    if (!valid) {
      return;
    }

    const addedBankAccount: AddBankAccountState =
      addBankAccountForm.getValues();
    const isEligibleForBothAssignments =
      getIsEligibleForBothAssignments(addedBankAccount);
    const convertPendingAccount = convertAddedAccount(
      addedBankAccount,
      isEligibleForBothAssignments
    );
    dispatch(addBankAccount(convertPendingAccount));
    const dispatcherFn = isContribution
      ? changeContributionAccount
      : changeDistributionAccount;

    dispatch(
      dispatcherFn({
        bankAccountUniqueId: convertPendingAccount.main.bankAccountUniqueId,
        isEligibleForBothAssignments,
      })
    );

    setOpen(false);
    addBankAccountForm.reset(DEFAULT_ADD_ACCOUNT_VALUES);
    dispatch(
      openAlert({
        severity: "success",
        message: isEligibleForBothAssignments
          ? BankAccountConstants.BANK_ACCOUNT_ADDED_BOTH_PURPOSES_MESSAGE
          : BankAccountConstants.BANK_ACCOUNT_ADDED_MESSAGE,
      })
    );
  }, [
    addBankAccountForm,
    getIsEligibleForBothAssignments,
    dispatch,
    setOpen,
    DEFAULT_ADD_ACCOUNT_VALUES,
    isContribution,
  ]);

  return (
    <ElectionDialog
      title={BankAccountConstants.ADD_NEW_BANK_ACCOUNT}
      content={
        <AddBankAccountForm
          addBankAccountForm={addBankAccountForm}
          isContribution={isContribution}
          isConfirmClose={confirmClose}
        />
      }
      open={open}
      handleClose={handleClose}
      handleNext={confirmClose ? cancelClose : handleContinue}
      cancelButtonLabel={confirmClose ? "Yes, cancel" : "Cancel"}
      nextButtonLabel={confirmClose ? "No" : "Add Account"}
    />
  );
};
