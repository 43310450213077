import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css";

import { Box } from "@mui/material";
import { ColDef } from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import {
  BankAccountAssignment,
  extractable,
  IBankAccount,
  IBaseStore,
  isSomething,
  nothing,
  Optional,
  optionalDateUTCDashForNullFormatter,
  optionalStringDashForNullFormatter,
  some,
  useGridExtensions,
} from "common";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import styles from "./AccountAssignmentReadOnlyGrid.module.scss";

interface AccountAssignmentRow {
  purpose: BankAccountAssignment;
  bankAccount: Optional<string>;
  lastSubmitted: Optional<Date>;
}

export function obfuscateAccountNumber(accountNumber: string): string {
  let obfuscatedAccount = accountNumber;

  if (accountNumber.length > 4) {
    const obfuscatedDigits = "X".repeat(Math.max(0, accountNumber.length - 4));
    const lastFourDigits = accountNumber.slice(-4);
    obfuscatedAccount = obfuscatedDigits + lastFourDigits;
  }

  return obfuscatedAccount;
}

export function getBankAccount(
  account: Optional<IBankAccount>
): Optional<string> {
  const bankName = isSomething(account) ? account.value.bankName : "";
  const accountNumber = isSomething(account) ? account.value.accountNumber : "";

  return some(bankName + " - " + obfuscateAccountNumber(accountNumber));
}

export const AccountAssignmentReadOnlyGrid = () => {
  const { onGridReady, resizeColumns } = useGridExtensions();

  const { selectedContributionAccount, selectedDistributionAccount } =
    useSelector((store: IBaseStore) => store.bankAccounts);

  const columnDefs: ColDef<AccountAssignmentRow>[] = useMemo(() => {
    return [
      {
        field: "purpose",
        cellRenderer: (
          props: CustomCellRendererProps<
            AccountAssignmentRow,
            BankAccountAssignment
          >
        ) => {
          const { purpose } = props.data ?? {};
          return purpose ?? "-";
        },
      },
      {
        field: "bankAccount",
        valueFormatter: optionalStringDashForNullFormatter,
      },
      {
        field: "lastSubmitted",
        valueFormatter: optionalDateUTCDashForNullFormatter,
      },
    ];
  }, []);

  const rowData: AccountAssignmentRow[] = useMemo(() => {
    const extractableContribution = extractable(selectedContributionAccount);
    const extractableDistribution = extractable(selectedDistributionAccount);

    const lastSubmittedContribution: Optional<Optional<Date>> =
      extractableContribution.extractByKey("lastUpdated");
    const lastSubmittedDistribution: Optional<Optional<Date>> =
      extractableDistribution.extractByKey("lastUpdated");

    return [
      {
        purpose: BankAccountAssignment.CONTRIBUTION,
        bankAccount: getBankAccount(
          extractableContribution.extractByKey("main")
        ),
        lastSubmitted: isSomething(lastSubmittedContribution)
          ? lastSubmittedContribution.value
          : nothing,
      },
      {
        purpose: BankAccountAssignment.DISTRIBUTION,
        bankAccount: getBankAccount(
          extractable(selectedDistributionAccount).extractByKey("main")
        ),
        lastSubmitted: isSomething(lastSubmittedDistribution)
          ? lastSubmittedDistribution.value
          : nothing,
      },
    ];
  }, [selectedContributionAccount, selectedDistributionAccount]);

  return (
    <Box className={`ag-theme-alpine`} id={styles.accountGrid} width="100%">
      <AgGridReact<AccountAssignmentRow>
        defaultColDef={{
          resizable: false,
          sortable: false,
          suppressHeaderMenuButton: true,
          suppressMovable: true,
        }}
        columnDefs={columnDefs}
        rowData={rowData}
        domLayout="autoHeight"
        onGridReady={onGridReady}
        onRowDataUpdated={resizeColumns}
        onGridSizeChanged={resizeColumns}
        suppressRowDrag
        suppressCellFocus
      />
    </Box>
  );
};
