import { ElectionDocumentType, ElectionRoundType } from "../constants/enums";
import { ElectionsLabels } from "../constants/LabelAndTooltipConstants";
import { IUpdateWorkflowStateSource } from "../services/electionsService";
import { IUnrealizedValue } from "./dataTypes";
import { PageSize } from "./pageSizeTypes";
import { Optional } from "./typeUtils";

// TODO: this file in general has portions of the data models
//  we will neeed for elections. These models are not yet complete
//  but can be added to as more dev work is done

export type ClientId = number;

export type ElectionRoundId = string;

export interface IElectionIV {
  id: number;
  name: string;
}

export interface IGetElectionDetailsRequestPayload {
  investmentVehicleId: number;
  electionRoundId: string;
  isAdmin: boolean;
}

export interface IWriteElectionWorkflowStatePayload {
  investmentVehicleId: number;
  electionRoundId: string;
  isAdmin: boolean;
  targetState: IUpdateWorkflowStateSource;
}

export interface IGetElectionConfigurationRequestPayload {
  electionRoundId: string;
}

export interface IElectionPermissions {
  clientCapabilities: ElectionClientCapabilityMap;
  globalCapabilities: string[];
  adminOnlyScopedCapabilities: string[];
}

export type ElectionClientCapabilityMap = {
  [clientId: ClientId]: IElectionClient;
};

export interface IElectionClient {
  clientName: string;
  investmentVehicles: IElectionInvestmentVehicle[];
  capabilities: string[];
}

export interface IPermittedClient {
  clientId: ClientId;
  clientName: string;
  investmentVehicles: IElectionInvestmentVehicle[];
}

export interface IElectionRound {
  electionRoundId: ElectionRoundId;
}

export interface IElectionInvestmentVehicle {
  investmentVehicleId: number;
  name: string;
}

export interface IElectionsForClient {
  investmentVehicle: IElectionInvestmentVehicle;
  electionRoundId: string;
  version: number;
  electionRoundConfigurationVersion: number;
  ivConfigurationVersion: number;
  currentStage: ElectionWorkflowStageId;
  systemOpenDate: Date;
  systemCloseDate: Date;
  electionSubmissionDeadline: Optional<Date>;
  documents: IElectionDocument[];
  name: string;
  electedAmount: number;
}

export interface IElectionsForClientListItem {
  investmentVehicle: IElectionInvestmentVehicle;
  electionRoundId: string;
  version: number;
  electionRoundConfigurationVersion: number;
  ivConfigurationVersion: number;
  currentStage: ElectionWorkflowStageId;
  systemOpenDate: Date;
  systemCloseDate: Date;
  electionSubmissionDeadline: Date;
  documents: IElectionDocument[];
  name: string;
  electedAmount: number;
}

export interface IElectionsForElectionRound {
  investmentVehicle: IElectionInvestmentVehicle;
  mdmInvestmentVehicleId: number;
  currentStage: number;
  reopenedDates: Date[];
  submissionDateTimes: Date[];
}

export interface IElectionsForElectionRoundRow {
  mdmInvestmentVehicleId: number;
  name: string;
  currentStage: number;
  reopenedDate: Date | undefined;
  submissionDateTime: Date | undefined;
  investmentVehicleId: number;
}

export enum ElectionsListGridKeys {
  CURRENT_STAGE = "currentStage",
  DOCUMENTS = "documents",
  SUBMISSION_DEADLINE = "electionSubmissionDeadline",
  NAME = "name",
  INVESTMENT_VEHICLE = "investmentVehicle",
  ELECTED_AMOUNT = "electedAmount",
}

export enum ElectionWorkflowStageKeys {
  UNKNOWN = "unknown",
  NOT_STARTED = "notStarted",
  OVERVIEW = "overview",
  ELIGIBILITY = "eligibility",
  STRATEGIES = "strategies",
  ELECT = "elect",
  BANK_ACCOUNT = "bankAccount",
  REVIEW_AND_SIGN = "reviewAndSign",
  COMPLETED = "completed",
}

export enum ElectionWorkflowStageId {
  UNKNOWN = 0,
  NOT_STARTED = 1,
  OVERVIEW = 2,
  ELIGIBILITY = 3,
  STRATEGIES = 4,
  ELECT = 5,
  BANK_ACCOUNT = 6,
  REVIEW_AND_SIGN = 7,
  COMPLETED = 8,
}

export enum ElectionWorkflowLabels {
  UNKNOWN = "Unknown",
  NOT_STARTED = "Not Started",
  OVERVIEW = "1 - Overview",
  ELIGIBILITY = "2 - Eligibility",
  STRATEGIES = "3 - Strategies",
  ELECT = "4 - Elect",
  BANK_ACCOUNT = "5 - Bank Accounts",
  REVIEW_AND_SIGN = "6 - Review And Sign",
  COMPLETED = "Completed",
}

export const ElectionWorkflowStageOrder: ElectionWorkflowStageId[] = [
  ElectionWorkflowStageId.OVERVIEW,
  ElectionWorkflowStageId.ELIGIBILITY,
  ElectionWorkflowStageId.STRATEGIES,
  ElectionWorkflowStageId.ELECT,
  ElectionWorkflowStageId.BANK_ACCOUNT,
  ElectionWorkflowStageId.REVIEW_AND_SIGN,
];

export type ElectionWorkflowStageIdToKeyMap = {
  [key in ElectionWorkflowStageId]?: ElectionWorkflowStageKeys;
};

export type ElectionWorkflowStageIdToLabelMap = {
  [key in ElectionWorkflowStageId]?: ElectionWorkflowLabels;
};

export const ElectionWorkflowStageIdToKey: ElectionWorkflowStageIdToKeyMap = {
  [ElectionWorkflowStageId.OVERVIEW]: ElectionWorkflowStageKeys.OVERVIEW,
  [ElectionWorkflowStageId.ELIGIBILITY]: ElectionWorkflowStageKeys.ELIGIBILITY,
  [ElectionWorkflowStageId.STRATEGIES]: ElectionWorkflowStageKeys.STRATEGIES,
  [ElectionWorkflowStageId.ELECT]: ElectionWorkflowStageKeys.ELECT,
  [ElectionWorkflowStageId.BANK_ACCOUNT]:
    ElectionWorkflowStageKeys.BANK_ACCOUNT,
  [ElectionWorkflowStageId.REVIEW_AND_SIGN]:
    ElectionWorkflowStageKeys.REVIEW_AND_SIGN,
};

export const ElectionWorkflowStageIdToLabel: ElectionWorkflowStageIdToLabelMap =
  {
    [ElectionWorkflowStageId.UNKNOWN]: ElectionWorkflowLabels.UNKNOWN,
    [ElectionWorkflowStageId.NOT_STARTED]: ElectionWorkflowLabels.NOT_STARTED,
    [ElectionWorkflowStageId.OVERVIEW]: ElectionWorkflowLabels.OVERVIEW,
    [ElectionWorkflowStageId.ELIGIBILITY]: ElectionWorkflowLabels.ELIGIBILITY,
    [ElectionWorkflowStageId.STRATEGIES]: ElectionWorkflowLabels.STRATEGIES,
    [ElectionWorkflowStageId.ELECT]: ElectionWorkflowLabels.ELECT,
    [ElectionWorkflowStageId.BANK_ACCOUNT]: ElectionWorkflowLabels.BANK_ACCOUNT,
    [ElectionWorkflowStageId.REVIEW_AND_SIGN]:
      ElectionWorkflowLabels.REVIEW_AND_SIGN,
    [ElectionWorkflowStageId.COMPLETED]: ElectionWorkflowLabels.COMPLETED,
  };

export interface IStageConfiguration {
  helpBannerTittle: Optional<string>;
  helpBanner: Optional<string>;
  footnotes: Optional<string>;
}

export interface IRemainingCommitmentConfiguration {
  tooltip: Optional<string>;
  footnote: Optional<string>;
}

export interface IElectionProcessTemplate {
  introduction: Optional<string>;
  overview: Optional<string>;
  eligibility: Optional<string>;
  strategies: Optional<string>;
  elections: Optional<string>;
  bankAccount: Optional<string>;
  reviewAndSign: Optional<string>;
}

export interface OverviewStageConfiguration extends IStageConfiguration {
  legalAttestation: Optional<string>;
  remainingExpectedCommitment: IRemainingCommitmentConfiguration;
  electionProcess: IElectionProcessTemplate;
  offerAmountTooltip: Optional<string>;
}

export interface EligibilityStageConfiguration extends IStageConfiguration {
  residentialInformationTooltip: Optional<string>;
  accreditedInvestorStatusTooltip: Optional<string>;
  qualifiedPurchaserStatusTooltip: Optional<string>;
  notEligibleTooltip: Optional<string>;
}

export interface StrategiesStageConfiguration extends IStageConfiguration {
  overviewTooltip: Optional<string>;
  lpCommitmentTooltip: Optional<string>;
  blackstoneCommitmentTooltip: Optional<string>;
  totalSubjectToElectionTooltip: Optional<string>;
  forecastedDeploymentPercentageTooltip: Optional<string>;
  forecastedDeploymentTooltip: Optional<string>;
}

export interface ElectStageConfiguration extends IStageConfiguration {
  electionsCardTooltip: Optional<string>;
  remainingCommitmentTooltip: Optional<string>;
  mandatoryCommitmentTooltip: Optional<string>;
  totalForecastedInvestmentTooltip: Optional<string>;
  reallocationTooltip: Optional<string>;
  financingCardContent: Optional<string>;
}

export type BankAccountStageConfiguration = IStageConfiguration;

export interface ReviewAndSignStageConfiguration extends IStageConfiguration {
  submitMessage: Optional<string>;
}

export type StageConfigurations = {
  [Key in ElectionWorkflowStageKeys]: IStageConfiguration;
};

export interface IElectionStageConfigurations {
  unknown?: IStageConfiguration;
  notStarted?: IStageConfiguration;
  overview: OverviewStageConfiguration;
  eligibility: EligibilityStageConfiguration;
  strategies: StrategiesStageConfiguration;
  elect: ElectStageConfiguration;
  bankAccount: BankAccountStageConfiguration;
  reviewAndSign: ReviewAndSignStageConfiguration;
  completed?: IStageConfiguration;
}

export interface IFund {
  fundId: number;
  fundShortName: string;
  limitedPartnerCommitment: number;
  blackstoneCommitment: number;
  totalFundSize: number;
  investmentPeriodYears: Optional<number>;
  totalSubjectToElection: Optional<number>;
  forecastedDeployment: Optional<number>;
}

export interface IStrategyBase {
  strategyId: number;
  name: string;
}

export interface IStrategy extends IStrategyBase {
  funds: IFund[];
}

export interface IElectionRoundConfigurationBase {
  electionRoundId: string;
  version: number;
  name: string;
  type: ElectionRoundType;
  totalElectionMinimum: number;
  mandatoryCommitmentYear: number;
  annualElectionStartDate: Optional<Date>;
  annualElectionEndDate: Optional<Date>;
  taxFormsDueDate: Date;
  systemOpenDate: Date;
  systemCloseDate: Date;
  electionSubmissionDeadline: Date;
  ppmDocuments: IPPMDocument[];
  documents: IElectionDocument[];
  stages: IElectionStageConfigurations;
  electionTeam: IElectionTeamMember[];
}
export interface IElectionRoundConfiguration
  extends IElectionRoundConfigurationBase {
  strategies: IStrategy[];
}

export interface IElectionTeamMember {
  employeeId: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
}

export interface IPPMDocument {
  id: number;
  fundId: number;
  name: string;
}

export interface IElectionDocument {
  id: number;
  name: string;
  type: ElectionDocumentType;
}

export interface IElectionDecision {
  strategyId: number;
  electedAmount: Optional<number>;
}

export interface IOverviewStage {
  hasAcceptedLegalAttestation: boolean;
}

export interface IResidentialInformation {
  isUSResident: Optional<boolean>;
  isNYResident: Optional<boolean>;
}

export interface IAccreditedInvestorStatus {
  hasIndividualNetWorth: boolean;
  hasIndividualIncome: boolean;
  isProfessional: boolean;
}

export interface IQualifiedPurchaserStatus {
  hasInvestmentsInBlackstoneFunds: boolean;
  isCompany: boolean;
  isTrust: boolean;
  isIndividualInvestment: boolean;
}

export interface IEligibleStatus {
  accreditedInvestorStatus: IAccreditedInvestorStatus;
  qualifiedPurchaserStatus: IQualifiedPurchaserStatus;
  isNotEligible: boolean;
}

export interface IEligibilityStage {
  residentialInformation: IResidentialInformation;
  eligibleStatus: IEligibleStatus;
}

export interface IElectStage {
  elections: IElectionDecision[];
  useReallocation: Optional<boolean>;
}

export interface IBankAccountStage {
  hasUSBankAccount: Optional<boolean>;
}

export interface IReviewAndSignStage {
  signature: Optional<string>;
  submitDate: Optional<Date>;
}

export interface IElectionStagesState {
  overview: IOverviewStage;
  eligibility: IEligibilityStage;
  elect: IElectStage;
  bankAccount: IBankAccountStage;
  reviewAndSign: IReviewAndSignStage;
}

export interface IElectionWorkflowState {
  investmentVehicleId: number;
  electionRoundId: string;
  version: number;
  electionRoundConfigurationVersion: number;
  ivConfigurationVersion: number;
  userUpn: string;
  currentStage: ElectionWorkflowStageId;
  electionStages: IElectionStagesState;
  isAdmin: boolean;
}

export interface IUpdateWorkflowState {
  investmentVehicleId: number;
  electionRoundId: string;
  version: number;
  electionRoundConfigurationVersion: number;
  ivConfigurationVersion: number;
  targetStage: ElectionWorkflowStageId;
  electionStages: IElectionStagesState;
}

export interface IStageBannerExpandCollapse {
  stageId: ElectionWorkflowStageId;
  shouldExpand: boolean;
}

export interface IIVStrategy {
  strategyId: number;
  strategyName: Optional<string>;
  remainingCommitment: Optional<number>;
  mandatoryCommitment: Optional<number>;
  min: Optional<number>;
  max: Optional<number>;
}

export interface IElectionIVConfiguration {
  electionRoundId: string;
  version: number;
  canRequestAdditional: boolean;
  canUseFinancing: boolean;
  loanBalance: Optional<number>;
  loanLimit: Optional<number>;
  offeredAmount: Optional<number>;
  additionalRequestLimit: Optional<number>;
  financingPercentage: Optional<number>;
  strategies: IIVStrategy[];
  investmentVehicle: IElectionIV;
}

export interface IElectionInvestmentPortfolio {
  priorElectionsData: Optional<PriorElections>;
  unrealizedValuesData: Optional<UnrealizedValues>;
}

export interface PriorElections {
  asOfDate: Date;
  priorElections: PriorElection[];
}

export interface PriorElection {
  strategyId: number;
  strategyName: string;
  electedAmountsByYear: ElectedAmounts[];
}

export interface ElectedAmounts {
  year: number;
  electedAmount: number;
}

export interface UnrealizedValues {
  asOfDate: Date;
  unrealizedValues: UnrealizedValue[];
}

export interface UnrealizedValue {
  strategyId: number;
  strategyName: string;
  total: number;
  remainingCapitalInvested: number;
  gainLoss: number;
  carriedInterest: number;
  endDate: Date;
  fundData: IFundUnrealizedValue[];
}

export interface IFundUnrealizedValue {
  fund: IFundMetadata;
  unrealizedValue: IUnrealizedValue;
}

export interface IFundMetadata {
  fundID: number;
  fundName: string;
  fundShortName: string;
  mDMFundID: number;
  businessID: string;
}

export interface IStrategyUnrealizedValueExcelRow extends UnrealizedValue {
  fundName: string;
}

export enum ElectCardGridKeys {
  STRATEGY = "name",
  STRATEGY_MAX = "strategyMax",
  REMAINING_COMMITMENT = "remainingCommitment",
  MANDATORY_COMMITMENT = "mandatoryCommitment",
  OPTIONAL_ELECTION = "optionalElection",
  TOTAL_COMMITMENT = "totalCommitment",
  TOTAL_FORECAST_INVESTMENT = "totalForecastInvestment",
  FIRST_OPERATOR = "firstOperator",
  SECOND_OPERATOR = "secondOperator",
  THIRD_OPERATOR = "thirdOperator",
  FOURTH_OPERATOR = "fourthOperator",
}

export interface IElectCardGridRow {
  name: string;
  strategyId: number;
  strategyMin: Optional<number>;
  strategyMax: Optional<number>;
  remainingCommitment: number;
  mandatoryCommitment: number;
  optionalElection: Optional<number>;
  optionalElectionPercentage: number;
  totalCommitment: number;
  totalForecastInvestment: number;
  firstOperator: Optional<string>;
  secondOperator: Optional<string>;
  thirdOperator: Optional<string>;
  fourthOperator: Optional<string>;
}

export interface IElectCardData {
  electCardData: IElectCardGridRow[];
  totalRow: IElectCardGridRow;
  financing: IElectCardFinancing;
  canRequestAdditional: boolean;
  showTotalForcastedInvestment: boolean;
}

export interface IElectCardGridTooltips {
  remainingCommitmentTooltip: string;
  mandatoryCommitmentTooltip: string;
  totalForecastedInvestmentTooltip: string;
  mandatoryCommitmentYear: number;
  offeredAmount: Optional<number>;
}

export interface IElectCardFinancingValues {
  cash: number;
  financing: number;
  existingLoanBalance: number;
  projectedLoanBalance: number;
  loanLimit: number;
}

export interface IElectCardOperatorValues {
  cash: Optional<string>;
  financing: Optional<string>;
  existingLoanBalance: Optional<string>;
  projectedLoanBalance: Optional<string>;
  loanLimit: Optional<string>;
}

export interface IElectCardFinancing {
  financingPercent: number;
  totalCommitment: IElectCardFinancingValues;
  totalForecastedInvestment: IElectCardFinancingValues;
  thirdOperator: IElectCardOperatorValues;
  fourthOperator: IElectCardOperatorValues;
}

export interface IEligibilityTooltips {
  accreditedInvestorStatusTooltip: string;
  qualifiedPurchaserStatusTooltip: string;
  notEligibleTooltip: string;
  residentialInformationTooltip: string;
}

export enum FinancingRowKeys {
  CASH = "cash",
  FINANCING = "financing",
  EXISTING_LOAN_BALANCE = "existingLoanBalance",
  PROJECTED_LOAN_BALANCE = "projectedLoanBalance",
  LOAN_LIMIT = "loanLimit",
}

export const FinancingRowsOrder: FinancingRowKeys[] = [
  FinancingRowKeys.CASH,
  FinancingRowKeys.FINANCING,
  FinancingRowKeys.EXISTING_LOAN_BALANCE,
  FinancingRowKeys.PROJECTED_LOAN_BALANCE,
  FinancingRowKeys.LOAN_LIMIT,
];

export type FinancingRowKeysToTitleMap = {
  [key in FinancingRowKeys]: string;
};

export const FinancingRowKeysToTitles: FinancingRowKeysToTitleMap = {
  [FinancingRowKeys.CASH]: ElectionsLabels.CASH,
  [FinancingRowKeys.FINANCING]: ElectionsLabels.FINANCING,
  [FinancingRowKeys.EXISTING_LOAN_BALANCE]:
    ElectionsLabels.EXISTING_LOAN_BALANCE,
  [FinancingRowKeys.PROJECTED_LOAN_BALANCE]:
    ElectionsLabels.PROJECTED_LOAN_BALANCE,
  [FinancingRowKeys.LOAN_LIMIT]: ElectionsLabels.LOAN_LIMIT,
};

export enum ElectionAccordionObject {
  OVERVIEW_BANNER,
  OVERVIEW_PROCESS_OVERVIEW,
  OVERVIEW_PROCESS_ELIGIBILITY,
  OVERVIEW_PROCESS_STRATEGIES,
  OVERVIEW_PROCESS_ELECT,
  OVERVIEW_PROCESS_BANK_ACCOUNT,
  OVERVIEW_PROCESS_REVIEW_AND_SIGN,
  ELIGIBILITY_BANNER,
  STRATEGIES_BANNER,
  ELECT_BANNER,
  ELECT_STRATEGY_OVERVIEW,
  ELECT_FINANCING_OPTIONS,
  ELECT_CURRENT_INVESTMENT_PORTFOLIO,
  BANK_ACCOUNT_BANNER,
  REVIEW_AND_SIGN_BANNER,
  REVIEW_AND_SIGN_ELIGIBILITY,
  REVIEW_AND_SIGN_ELECT,
  REVIEW_AND_SIGN_BANK_ACCOUNT,
  NO_BANNER,
}

export type ElectionAccordionObjectStatusMap = {
  [key in ElectionAccordionObject]: boolean;
};

export const DEFAULT_ACCORDION_STATUSES: ElectionAccordionObjectStatusMap = {
  [ElectionAccordionObject.OVERVIEW_BANNER]: true,
  [ElectionAccordionObject.OVERVIEW_PROCESS_OVERVIEW]: false,
  [ElectionAccordionObject.OVERVIEW_PROCESS_ELIGIBILITY]: false,
  [ElectionAccordionObject.OVERVIEW_PROCESS_STRATEGIES]: false,
  [ElectionAccordionObject.OVERVIEW_PROCESS_ELECT]: false,
  [ElectionAccordionObject.OVERVIEW_PROCESS_BANK_ACCOUNT]: false,
  [ElectionAccordionObject.OVERVIEW_PROCESS_REVIEW_AND_SIGN]: false,
  [ElectionAccordionObject.ELIGIBILITY_BANNER]: true,
  [ElectionAccordionObject.STRATEGIES_BANNER]: true,
  [ElectionAccordionObject.ELECT_BANNER]: true,
  [ElectionAccordionObject.ELECT_STRATEGY_OVERVIEW]: false,
  [ElectionAccordionObject.ELECT_FINANCING_OPTIONS]: true,
  [ElectionAccordionObject.ELECT_CURRENT_INVESTMENT_PORTFOLIO]: false,
  [ElectionAccordionObject.BANK_ACCOUNT_BANNER]: true,
  [ElectionAccordionObject.REVIEW_AND_SIGN_BANNER]: true,
  [ElectionAccordionObject.REVIEW_AND_SIGN_ELIGIBILITY]: false,
  [ElectionAccordionObject.REVIEW_AND_SIGN_ELECT]: false,
  [ElectionAccordionObject.REVIEW_AND_SIGN_BANK_ACCOUNT]: false,
  [ElectionAccordionObject.NO_BANNER]: false,
};

export interface IAccordionUpdate {
  accordionObject: ElectionAccordionObject;
  status: boolean;
}

export interface IElectionProcessAccordionStage {
  accordionId: ElectionAccordionObject;
  accordionSummary: string;
  accordionDetails: string;
}

export enum ElectStageWarningType {
  EXCEEDS_OFFER_AMOUNT,
  EXCEEDS_STRATEGY_MAX,
  EXCEEDS_OFFER_AMOUNT_AND_STRATEGY_MAX,
  EXCEEDS_LOAN_LIMIT,
}

export interface IStrategyElection {
  strategyId: number;
  strategyName: string;
  electedAmount: Optional<number>;
  max: number;
}

export interface IElectStageWarning {
  warningTypes: ElectStageWarningType[];
  exceededStrategies: IStrategyElection[];
}

export interface IStrategyForecastedDeploymentPercentage {
  strategyId: number;
  strategyName: string;
  percentage: number;
}

export enum AllocationGridKeys {
  STRATEGY_ID = "strategyId",
  STRATEGY_NAME = "strategyName",
  PERCENTAGE = "percentage",
}

export interface ISubmissionResponse {
  savedWorkflowStateResponse: IElectionWorkflowState;
  electionRoundConfiguration: IElectionRoundConfiguration;
  electionIVConfiguration: IElectionIVConfiguration;
}

export interface IEntityWithForecastedDeploymentPercentage {
  totalSubjectToElection: Optional<number>;
  forecastedDeployment: Optional<number>;
  forecastedDeploymentPercentage: number;
}

export interface IFundModel
  extends IFund,
    IEntityWithForecastedDeploymentPercentage {}

export interface IStrategyModel
  extends IStrategyBase,
    IEntityWithForecastedDeploymentPercentage {
  funds: IFundModel[];
}

export interface IElectionRoundConfigurationModel
  extends IElectionRoundConfigurationBase {
  strategies: IStrategyModel[];
}

export interface IResultEligibleStatus extends IEligibleStatus {
  isAccredited: boolean;
  isQualified: boolean;
}

export interface IElectionByStrategy {
  strategyId: number;
  strategyName: string;
  strategyMax: Optional<number>;
  electedAmount: Optional<number>;
  overMax: Optional<number>;
}

export interface IElectionUser {
  upn: string;
  email: string;
  fullName: string;
}

export interface IElectionSubmission {
  submitDateTimeUTC: Optional<Date>;
  user: Optional<IElectionUser>;
}

export interface IElectionResultTotals {
  offeredAmount: Optional<number>;
  electedAmount: Optional<number>;
  additionalRequestedAmount: Optional<number>;
}

export interface IResultResponse {
  mdmInvestmentVehicleId: number;
  investmentVehicleName: string;
  canReallocate: boolean;
  eligibleStatus: IResultEligibleStatus;
  electionsByStrategy: IElectionByStrategy[];
  electionSubmission: IElectionSubmission;
  electionResultTotals: IElectionResultTotals;
}

export interface IElectionResultRow {
  ivId: number;
  ivName: string;
  isAccredited: boolean;
  isAccreditedIndividualNet: boolean;
  isAccreditedIndividual: boolean;
  isAccreditedProfessional: boolean;
  isQualified: boolean;
  isQualifiedIndividualNet: boolean;
  isQualifiedCompany: boolean;
  isQualifiedTrust: boolean;
  isQualifiedIndividual: boolean;
  notQualifiedAccredited: boolean;
  canReallocate: boolean;
  strategyName: string;
  offeredAmount?: number;
  additionalLimit?: number | undefined | null;
  strategyMax?: number | undefined | null;
  electedAmount: number | undefined | null;
  additionalRequested?: number | undefined | null;
  overMax?: number | undefined | null;
  isSubmitted: boolean;
  submitDate: string;
  userEmail: string;
  userName: string;
  userUPN: string;
}

export interface IElectionClientMapping {
  axiomClientId: number;
  axiomInvestmentVehicleId: number;
  mdmClientId: number;
  mdmInvestmentVehicleId: number;
  clientName: string;
  clientShortName: string;
  investmentVehicleName: string;
  investmentVehicleShortName: string;
}

export interface IElectionsGridOptions {
  page: number;
  pageSize: PageSize;
  searchTerm: Optional<string>;
}
