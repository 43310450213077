import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { BankAccountConstants, EntityType, GPInvestmentVehicle } from "common";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import { ESignDisclosureBox } from "./ESignDisclosureBox/ESignDisclosureBox";
import styles from "./ReviewAndSignForm.module.scss";
import { TextFieldInput } from "./TextFieldInput/TextFieldInput";

export interface ReviewAndSignState {
  investmentVehicle?: string;
  contributionDebitAccount: string;
  distributionDepositAccount: string;
  signature: string;
  signatureInMyCapacityAs: string;
  phoneNumber: string;
  agreesToTerms: boolean;
}

export interface ReviewAndSignFormState {
  reviewAndSign: ReviewAndSignState;
}

interface ReviewAndSignFormProps {
  reviewAndSignForm: UseFormReturn<ReviewAndSignFormState>;
  investmentVehicle?: GPInvestmentVehicle;
}

export const ReviewAndSignForm = (props: ReviewAndSignFormProps) => {
  const { reviewAndSignForm, investmentVehicle } = props;
  const { register } = reviewAndSignForm;

  return (
    <FormControl className={styles.reviewAndSignForm}>
      <Controller
        control={reviewAndSignForm.control}
        name={"reviewAndSign"}
        render={({ field }) => (
          <Grid container className={styles.container}>
            <Grid item>
              <Typography variant="body1">
                {BankAccountConstants.REVIEW_AND_SIGN_FIRST_PARAGRAPH}
              </Typography>
            </Grid>
            <Grid item className={styles.bankAccountsInfo}>
              <TextFieldInput
                field={field}
                register={register}
                attributeName="investmentVehicle"
                label={BankAccountConstants.INVESTMENT_VEHICLE}
                disabled
              />
              <TextFieldInput
                field={field}
                register={register}
                attributeName="contributionDebitAccount"
                label={BankAccountConstants.CONTRIBUTION_DEBIT_ACCOUNT}
                shouldObfuscate={
                  field.value.contributionDebitAccount !==
                  BankAccountConstants.NO_UPDATE
                }
                disabled
              />
              <TextFieldInput
                field={field}
                register={register}
                attributeName="distributionDepositAccount"
                label={BankAccountConstants.DISTRIBUTION_CREDIT_ACCOUNT}
                shouldObfuscate={
                  field.value.distributionDepositAccount !==
                  BankAccountConstants.NO_UPDATE
                }
                disabled
              />
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {BankAccountConstants.REVIEW_AND_SIGN_SECOND_PARAGRAPH}
              </Typography>
            </Grid>
            {investmentVehicle &&
              investmentVehicle.entityType === EntityType.ORGANIZATION && (
                <Typography variant="body1">
                  {BankAccountConstants.SIGN_AUTHORIZATION_ON_BEHALF_OF}
                  {investmentVehicle.name}
                  {"."}
                </Typography>
              )}
            <Grid item>
              <FormControlLabel
                className={styles.termsAndConditionsCheckbox}
                control={
                  <Checkbox
                    {...register("reviewAndSign.agreesToTerms", {
                      required: true,
                    })}
                    checked={field.value.agreesToTerms}
                    onChange={(e) => {
                      field.onChange({
                        ...field.value,
                        agreesToTerms: e.currentTarget.checked,
                      });
                    }}
                  />
                }
                label={BankAccountConstants.TERMS_AND_CONDITIONS_CHECKBOX}
              />
            </Grid>
            <Grid item>
              <ESignDisclosureBox />
            </Grid>
            <Grid item>
              <Stack className={styles.signature}>
                <Typography variant="body2">
                  {
                    BankAccountConstants.TERMS_AND_CONDITIONS_SIGNATURE_TEXT_LINE
                  }
                </Typography>
                <TextFieldInput
                  register={register}
                  id={styles.signatureField}
                  field={field}
                  attributeName="signature"
                  label={BankAccountConstants.E_SIGNATURE}
                  placeHolderText={BankAccountConstants.SIGN_HERE}
                  required
                />
                {investmentVehicle &&
                  investmentVehicle.entityType === EntityType.ORGANIZATION && (
                    <TextFieldInput
                      register={register}
                      field={field}
                      attributeName="signatureInMyCapacityAs"
                      label={BankAccountConstants.SIGNING_IN_MY_CAPACITY_AS}
                      placeHolderText={
                        BankAccountConstants.SIGNING_IN_MY_CAPACITY_AS_PLACEHOLDER
                      }
                      required
                    />
                  )}
                <TextFieldInput
                  register={register}
                  field={field}
                  attributeName="phoneNumber"
                  label={BankAccountConstants.PHONE}
                  placeHolderText={BankAccountConstants.PHONE_PLACEHOLDER}
                  required
                />
              </Stack>
            </Grid>
          </Grid>
        )}
      />
    </FormControl>
  );
};
