import { Info, WarningRounded as WarningIcon } from "@mui/icons-material";
import { Alert, AlertTitle, Stack, Typography } from "@mui/material";
import {
  BankAccountAssignment,
  BankAccountConstants,
  DataCard,
  FormCardHeader,
  IBankAccountStageFormState,
  IBaseStore,
  isEmptyResponse,
  isInProgress,
  isSomething,
  isUnsuccessful,
  nothing,
  reqBankAccountCountries,
  reqBankAccountCurrencies,
  reqBankAccounts,
  selectCanUseFinancing,
  selectHasUSBankAccount,
  some,
  useFetchDatasetIfIdDefined,
  useFetchDatasetWithoutId,
} from "common";
import React, { useCallback, useMemo, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { AccountAssignmentGrid } from "../../../../BankAccounts/AccountAssignmentGrid/AccountAssignmentGrid";
import styles from "./BankInfoCard.module.scss";

interface IBankInfoCardProps {
  form: UseFormReturn<IBankAccountStageFormState>;
  hasErrors: boolean;
  isAdmin: boolean;
}

export const BankInfoCard = (props: IBankInfoCardProps) => {
  const { form, hasErrors, isAdmin } = props;

  const { investmentVehicleId, mdmInvestmentVehicleId } = useParams();

  const { activeElection } = useSelector(
    (store: IBaseStore) => store.selectedElection
  );

  const requestBankAccountsPayload = useMemo(() => {
    // in investor mode, investmentVehicleId is in path
    if (
      !isAdmin &&
      investmentVehicleId &&
      !isNaN(parseInt(investmentVehicleId))
    ) {
      return some({
        id: parseInt(investmentVehicleId),
        isAdmin: isAdmin,
      });
    }
    // in admin mode, mdmInvestmentVehicleId is in path, so need to get axiom Id from loaded election
    if (
      isAdmin &&
      mdmInvestmentVehicleId &&
      !isNaN(parseInt(mdmInvestmentVehicleId)) &&
      isSomething(activeElection)
    ) {
      return some({
        id: activeElection.value.investmentVehicleId,
        isAdmin: isAdmin,
      });
    }
    return nothing;
  }, [investmentVehicleId, mdmInvestmentVehicleId, activeElection, isAdmin]);

  const bankAccountsLoadStatus = useSelector(
    (store: IBaseStore) => store.bankAccounts.bankAccountsLoadStatus
  );
  const { pendingBankAccounts } = useSelector(
    (store: IBaseStore) => store.bankAccounts.serverData
  );

  const canUseFinancing = useSelector(selectCanUseFinancing);

  const hasUSBankAccount = useSelector((state: IBaseStore) =>
    selectHasUSBankAccount(state)
  );

  const { countriesLoadStatus, currenciesLoadStatus } = useSelector(
    (state: IBaseStore) => state.bankAccounts
  );

  const [purposesWithError, setPurposesWithError] = useState<
    BankAccountAssignment[]
  >([]);

  const retryFunction = useFetchDatasetIfIdDefined(
    reqBankAccounts,
    requestBankAccountsPayload,
    bankAccountsLoadStatus
  );

  const retryCountries = useFetchDatasetWithoutId(
    reqBankAccountCountries,
    countriesLoadStatus
  );
  const retryCurrencies = useFetchDatasetWithoutId(
    reqBankAccountCurrencies,
    currenciesLoadStatus
  );

  const {
    selectedDistributionAccount,
    selectedContributionAccount,
    unsubmittedChanges,
  } = useSelector((store: IBaseStore) => store.bankAccounts);

  const validate = useCallback(() => {
    const unAssignedPurposes: BankAccountAssignment[] = [];
    if (!isSomething(selectedContributionAccount)) {
      /* Prevent having 'nothing' as a contribution account 
         if the user hasn't specified they don't have a US Bank Account 
         */
      if (
        !isSomething(hasUSBankAccount) ||
        (isSomething(hasUSBankAccount) && hasUSBankAccount.value === true)
      ) {
        unAssignedPurposes.push(BankAccountAssignment.CONTRIBUTION);
      }
    }
    if (!isSomething(selectedDistributionAccount)) {
      unAssignedPurposes.push(BankAccountAssignment.DISTRIBUTION);
    }
    setPurposesWithError(unAssignedPurposes);
    if (unAssignedPurposes.length > 0) {
      return BankAccountConstants.PLEASE_SUBMIT_BEFORE_CONTINUING;
    }
    if (Object.values(unsubmittedChanges).some(Boolean)) {
      return BankAccountConstants.PLEASE_SUBMIT_CHANGES_TO_PROCEED;
    }
    return true;
  }, [
    hasUSBankAccount,
    unsubmittedChanges,
    selectedContributionAccount,
    selectedDistributionAccount,
  ]);

  return (
    <Controller
      control={form.control}
      name={"bankAccountAssignments"}
      rules={{ validate: validate }}
      render={() => (
        <DataCard
          className={hasErrors ? styles.cardError : ""}
          loading={isInProgress(bankAccountsLoadStatus)}
          noData={isEmptyResponse(bankAccountsLoadStatus)}
          failed={isUnsuccessful(bankAccountsLoadStatus)}
          onReload={[retryFunction, retryCountries, retryCurrencies]}
        >
          <FormCardHeader
            title="Bank Information"
            required
            hasError={hasErrors}
          />
          <Stack id={styles.disclaimers}>
            {canUseFinancing ? (
              <Typography className={styles.legalText}>
                {BankAccountConstants.THIRD_PARTY_FINANCING_WARNING}
              </Typography>
            ) : null}
            <Typography className={styles.legalText}>
              {BankAccountConstants.AUTHORIZATION}
            </Typography>
            {pendingBankAccounts.length > 0 && (
              <Alert
                variant="standard"
                icon={<Info />}
                severity="info"
                id={styles.pendingAccountsAlert}
              >
                <Stack direction="row" gap={0.5}>
                  <Typography variant="body2">
                    {BankAccountConstants.UNDER_REVIEW_WARNING_1}
                  </Typography>
                  <Typography variant="body1">
                    {BankAccountConstants.UNDER_REVIEW_WARNING_2}
                  </Typography>
                </Stack>
              </Alert>
            )}

            {isSomething(hasUSBankAccount) &&
            hasUSBankAccount.value === false ? (
              <Alert
                variant="standard"
                icon={<WarningIcon />}
                severity="warning"
                className={styles.confirmAlert}
              >
                <AlertTitle className={styles.title}>
                  {BankAccountConstants.NO_US_ACCOUNT_WARNING}
                </AlertTitle>
              </Alert>
            ) : null}
          </Stack>
          <AccountAssignmentGrid
            purposesWithError={purposesWithError}
            retryFunction={retryFunction}
          />
        </DataCard>
      )}
    />
  );
};
