import { ActionReducerMapBuilder, createReducer } from "@reduxjs/toolkit";

import { StockSymbol, UISupportedStocks } from "../../constants/enums";
import { DEFAULT_ACCORDION_STATUSES } from "../../types/electionDataTypes";
import { InvestorUIViewDataStore } from "../../types/storeTypes";
import { isSomething } from "../../types/typeGuards";
import { nothing } from "../../types/typeUtils";
import {
  requestStageValidation,
  resetStageValidation,
  setAccordionStatus,
  setIsSBSElectionSaveEnabled,
  setUnacknowledgedElectStageWarnings,
} from "../actions/electionsActions";
import {
  closeAlert,
  errPageLoad,
  openAlert,
} from "../actions/entitlementActions";
import {
  recvEquityData,
  setSelectedStockSymbol,
} from "../actions/equityActions";
import { setPerformanceDetailsFilter } from "../actions/internalInvestmentActions";

export const defaultViewDataState: InvestorUIViewDataStore = {
  alert: {
    message: "",
    severity: "success",
    open: false,
  },
  isSBSElectionSaveEnabled: false,
  pageLoadStatus: 200,
  performanceDetailsFilter: undefined,
  selectedStockSymbol: StockSymbol.BX,
  electionAccordionCardStatuses: DEFAULT_ACCORDION_STATUSES,
  stageValidationRequested: false,
  unAcknowledgedElectStageWarnings: nothing,
};

export const defaultViewDataBuilder = (
  builder: ActionReducerMapBuilder<InvestorUIViewDataStore>
): void => {
  builder.addCase(openAlert, (state, action) => {
    state.alert = {
      ...action.payload,
      open: true,
    };
  });
  builder.addCase(closeAlert, (state) => {
    state.alert.open = false;
  });
  builder.addCase(errPageLoad, (state, action) => {
    state.pageLoadStatus = action.payload;
  });
  builder.addCase(setPerformanceDetailsFilter, (state, action) => {
    state.performanceDetailsFilter = action.payload;
  });
  builder.addCase(setSelectedStockSymbol, (state, action) => {
    state.selectedStockSymbol = action.payload;
  });
  builder.addCase(recvEquityData, (state, action) => {
    const firstExistingSymbol = UISupportedStocks.find((symbol) =>
      isSomething(action.payload[symbol])
    );
    if (firstExistingSymbol !== undefined) {
      state.selectedStockSymbol = firstExistingSymbol;
    }
  });
  builder.addCase(setAccordionStatus, (state, action) => {
    state.electionAccordionCardStatuses[action.payload.accordionObject] =
      action.payload.status;
  });
  builder.addCase(requestStageValidation, (state) => {
    state.stageValidationRequested = true;
  });
  builder.addCase(resetStageValidation, (state) => {
    state.stageValidationRequested = false;
  });
  builder.addCase(setIsSBSElectionSaveEnabled, (state, action) => {
    state.isSBSElectionSaveEnabled = action.payload;
  });
  builder.addCase(setUnacknowledgedElectStageWarnings, (state, action) => {
    state.unAcknowledgedElectStageWarnings = action.payload;
  });
};
export const investorUIViewDataReducer = createReducer<InvestorUIViewDataStore>(
  defaultViewDataState,
  (builder) => {
    defaultViewDataBuilder(builder);
  }
);
