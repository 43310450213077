export interface EnvironmentVariables {
  REACT_APP_OIDC_AUTHORITY_URL_BASE: string;
  REACT_APP_OIDC_CLIENT_ID: string;
  REACT_APP_OIDC_SCOPE: string;
  REACT_APP_EQUITY_URL_BASE: string;
  REACT_APP_DOCUMENTS_URL_BASE: string;
  REACT_APP_ELECTIONS_URL_BASE: string;
  REACT_APP_BANK_ACCOUNTS_URL_BASE: string;
  REACT_APP_INTERNAL_INVESTMENTS_URL_BASE: string;
  REACT_APP_AUTHORIZATION_URL_BASE: string;
  REACT_APP_PROFILE_IMAGES_URL_BASE: string;
  REACT_APP_BXACCESS_URL: string;
  REACT_APP_GOOGLE_ANALYTICS_TRACKER: string;
  REACT_APP_ENV: string;
  REACT_APP_PASSIVE_CHECK_URL: string;
  REACT_APP_ELECTION_ROUND_ID: string;
  RESTRICTED_EQUITY_FEATURE_FLAG: boolean;
  RESTRICTED_EQUITY_ADMIN_FEATURE_FLAG: boolean;
}

const DEFAULT_ENV_VARS: EnvironmentVariables = {
  REACT_APP_OIDC_AUTHORITY_URL_BASE: "",
  REACT_APP_OIDC_CLIENT_ID: "",
  REACT_APP_OIDC_SCOPE: "",
  REACT_APP_EQUITY_URL_BASE: "",
  REACT_APP_DOCUMENTS_URL_BASE: "",
  REACT_APP_ELECTIONS_URL_BASE: "",
  REACT_APP_BANK_ACCOUNTS_URL_BASE: "",
  REACT_APP_INTERNAL_INVESTMENTS_URL_BASE: "",
  REACT_APP_AUTHORIZATION_URL_BASE: "",
  REACT_APP_PROFILE_IMAGES_URL_BASE: "",
  REACT_APP_BXACCESS_URL: "",
  REACT_APP_GOOGLE_ANALYTICS_TRACKER: "",
  REACT_APP_ENV: "",
  REACT_APP_PASSIVE_CHECK_URL: "",
  REACT_APP_ELECTION_ROUND_ID: "",
  RESTRICTED_EQUITY_FEATURE_FLAG: false,
  RESTRICTED_EQUITY_ADMIN_FEATURE_FLAG: false,
};

export class EnvironmentResolver {
  static ENV: EnvironmentVariables = DEFAULT_ENV_VARS;

  public static async configureEnvironment() {
    if (window.fetch) {
      return window
        .fetch("/env.json")
        .then((data) => data.json())
        .then((data: EnvironmentVariables) => {
          EnvironmentResolver.ENV = {
            ...data,
          };
        })
        .catch((e) => {
          console.error(
            "Something went terribly wrong! Confirm that 'public/env.json' exists.",
            e
          );
        });
    }
  }
}
