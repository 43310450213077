import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";

import { EntityType } from "../../constants/enums";
import {
  BankAccountsResponseDto,
  getBankAccountCountries,
  getBankAccountCurrencies,
  getBankAccountsForClient,
  getBankAccountsForIV,
  ICountryResponseDto,
  ICurrencyResponseDto,
} from "../../services/bankAccountsService";
import {
  AccountNumberType,
  AccountType,
  BankAccountsResponse,
  BankIdType,
  GPBankAccount,
  GPInvestmentVehicle,
  GPPendingBankAccount,
  IBankAccount,
  WSSBankAccount,
  WSSBankAccountDetail,
} from "../../types/bankAccountDataTypes";
import { IPermittedClient } from "../../types/electionDataTypes";
import { Json, LoadError, Maybe, nothing, some } from "../../types/typeUtils";
import { convertToDateObject, convertToOptional } from "../../utils/converters";
import {
  errBankAccountCountries,
  errBankAccountCurrencies,
  errBankAccounts,
  recvBankAccountCountries,
  recvBankAccountCurrencies,
  recvBankAccounts,
  recvBankAccountsForClient,
  recvNoBankAccountCountries,
  recvNoBankAccountCurrencies,
  recvNoBankAccounts,
  ReqBankAccountBody,
} from "../reducers/bankAccountsReducer";

const convertWSSBankAccountDetailsDto = (
  dto: Json<WSSBankAccountDetail>
): WSSBankAccountDetail => {
  return {
    ...dto,
    accountType: dto.accountType as AccountType,
    accountNumberType: dto.accountNumberType as AccountNumberType,
    bankIdType: dto.bankIdType as BankIdType,
    lastModified: convertToDateObject(dto.lastModified),
  };
};

const convertWssBankAccountDto = (
  dto: Json<WSSBankAccount>
): WSSBankAccount => {
  return {
    main: convertWSSBankAccountDetailsDto(dto.main),
    intermediary: dto.intermediary
      ? some(convertWSSBankAccountDetailsDto(dto.intermediary))
      : nothing,
    assignments: dto.assignments,
  };
};

const convertGpBankAccountDto = (dto: Json<GPBankAccount>): GPBankAccount => {
  return {
    investmentVehicle: dto.investmentVehicle,
    bankAccount: convertWssBankAccountDto(dto.bankAccount),
  };
};

const convertBankAccountDto = (dto: Json<IBankAccount>): IBankAccount => {
  return {
    ...dto,
    accountType: dto.accountType as AccountType,
    accountNumberType: dto.accountNumberType as AccountNumberType,
    bankIdType: dto.bankIdType as BankIdType,
  };
};

const convertGpPendingBankAccountDto = (
  dto: Json<GPPendingBankAccount>
): GPPendingBankAccount => {
  return {
    ...dto,
    main: convertBankAccountDto(dto.main),
    intermediary: dto.intermediary
      ? some(convertBankAccountDto(dto.intermediary))
      : nothing,
    effectiveDate: convertToOptional(
      convertToDateObject(dto.effectiveDate ?? "")
    ),
    submittedDate: convertToOptional(
      convertToDateObject(dto.submittedDate ?? "")
    ),
    requestId: convertToOptional(dto.requestId),
  };
};

const convertGpInvestmentVehicleDto = (
  dto: Json<GPInvestmentVehicle>
): GPInvestmentVehicle => {
  return {
    ...dto,
    entityType: dto.entityType as EntityType,
  };
};

const convertBankAccountsResponseDto = (
  dto: BankAccountsResponseDto
): BankAccountsResponse => {
  return {
    bankAccounts: dto.bankAccounts.map(convertGpBankAccountDto),
    pendingBankAccounts: dto.pendingBankAccounts.map(
      convertGpPendingBankAccountDto
    ),
    investmentVehicles: dto.investmentVehicles.map(
      convertGpInvestmentVehicleDto
    ),
  };
};

export function* fetchBankAccountsForClient(
  action: PayloadAction<IPermittedClient>
) {
  try {
    const bankAccountsResponse: Maybe<BankAccountsResponseDto> = yield call(
      getBankAccountsForClient,
      action.payload.clientId
    );

    if (bankAccountsResponse === undefined) {
      put(recvNoBankAccounts());
    } else {
      yield put(
        recvBankAccountsForClient(
          convertBankAccountsResponseDto(bankAccountsResponse)
        )
      );
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errBankAccounts());
    }
  }
}

export function* fetchBankAccountsForIV(
  action: PayloadAction<ReqBankAccountBody>
) {
  try {
    const bankAccountsResponse: Maybe<BankAccountsResponseDto> = yield call(
      getBankAccountsForIV,
      action.payload.id,
      action.payload.isAdmin
    );

    if (bankAccountsResponse === undefined) {
      put(recvNoBankAccounts());
    } else {
      yield put(
        recvBankAccounts(convertBankAccountsResponseDto(bankAccountsResponse))
      );
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errBankAccounts());
    }
  }
}

export function* fetchBankAccountsCountries() {
  try {
    const bankAccountsCountriesResponse: Maybe<ICountryResponseDto[]> =
      yield call(getBankAccountCountries);

    if (bankAccountsCountriesResponse === undefined) {
      put(recvNoBankAccountCountries());
    } else {
      yield put(recvBankAccountCountries(bankAccountsCountriesResponse));
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errBankAccountCountries());
    }
  }
}

export function* fetchBankAccountsCurrencies() {
  try {
    const bankAccountsCurrenciesResponse: Maybe<ICurrencyResponseDto[]> =
      yield call(getBankAccountCurrencies);

    if (bankAccountsCurrenciesResponse === undefined) {
      put(recvNoBankAccountCurrencies());
    } else {
      yield put(recvBankAccountCurrencies(bankAccountsCurrenciesResponse));
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errBankAccountCurrencies());
    }
  }
}
