import { IElectionsForElectionRoundRow, isSomething, Optional } from "common";

/**
 * Searches and return matches from current searchTerm.
 * Elections can be searched by exact mdmInvestmentVehicleId match, mdmInvestmentVehicleId beginning match,
 * mdmInvestmentVehicleId containing match, or by the investment vehicle's name with the same criteria.
 *
 * @param elections An array of elections to search and sort.
 * @param searchTerm The string used to search for elections.
 * @returns An object that contains property for each type of match (exactMatches, beginsWithMatches, containingMatches).
 */
export const searchElections = (
  electionRounds: IElectionsForElectionRoundRow[],
  searchTerm: Optional<string>
): {
  exactMatches: IElectionsForElectionRoundRow[];
  beginsWithMatches: IElectionsForElectionRoundRow[];
  containingMatches: IElectionsForElectionRoundRow[];
} => {
  // if no search term, return all results
  if (!isSomething(searchTerm) || searchTerm.value === "")
    return {
      exactMatches: electionRounds,
      beginsWithMatches: [],
      containingMatches: [],
    };

  // Convert search string to lowercase for case-insensitive comparison
  const searchStringLowerCase = searchTerm.value.toLowerCase();

  // Arrays to store different types of matches
  const exactMatches: IElectionsForElectionRoundRow[] = [];
  const beginsWithMatches: IElectionsForElectionRoundRow[] = [];
  const containingMatches: IElectionsForElectionRoundRow[] = [];

  // Iterate through each election to find matches
  electionRounds.forEach((electionRound) => {
    const mdmIdString = electionRound.mdmInvestmentVehicleId.toString();
    const nameLowerCase = electionRound.name.toLowerCase();

    if (mdmIdString === searchStringLowerCase) {
      exactMatches.push(electionRound);
    } else if (nameLowerCase === searchStringLowerCase) {
      exactMatches.push(electionRound);
    } else if (nameLowerCase.startsWith(searchStringLowerCase)) {
      beginsWithMatches.push(electionRound);
    } else if (nameLowerCase.includes(searchStringLowerCase)) {
      containingMatches.push(electionRound);
    }
  });

  // Combine the arrays, prioritizing the order of match types
  return { exactMatches, beginsWithMatches, containingMatches };
};
