import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import React from "react";
import { FieldError } from "react-hook-form";

import FieldLabelWithTooltip from "../../../../../components/FieldLabelWithTooltip/FieldLabelWithTooltip";
import styles from "./DropdownInput.module.scss";

export interface IDropdownInputProps {
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  label: string;
  options: string[];
  disabled: boolean;
  tooltip?: string | React.ReactNode;
  error?: FieldError;
  warningMessage?: string;
}

export const DropdownInput = (props: IDropdownInputProps) => {
  const { value, onChange, options, disabled, error, warningMessage } = props;

  return (
    <Stack className={styles.stack}>
      <FieldLabelWithTooltip {...props} />
      <FormControl>
        <Select
          color="secondary"
          className={warningMessage ? styles.warning : ""}
          disabled={disabled}
          variant={"outlined"}
          value={value}
          onChange={onChange}
          error={error !== undefined}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            sx: {
              maxHeight: 300,
              maxWidth: 200,
            },
          }}
        >
          {options.map((value: string, index: number) => {
            return (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
        {error !== undefined && (
          <FormHelperText>{error.message}</FormHelperText>
        )}
      </FormControl>
    </Stack>
  );
};
