import { CellFocusedEvent, ColDef } from "ag-grid-community";
import {
  currencyFormatterForOptionalNumberWithMiddleDashDefault,
  currencyFormatterWithZeroDefault,
  ElectCardGridKeys,
  ElectionsLabels,
  GridHeaderWithTooltip,
  IElectCardData,
  IElectCardGridRow,
  IElectCardGridTooltips,
  IElectStage,
  isSomething,
  Markdown,
  MultiLineHeader,
  nothing,
  operatorFormatterForOptionalStringWithEmptyDefault,
  selectElectCardData,
  some,
  updateOptionalElectionByStrategy,
  zeroIfNothing,
} from "common";
import React from "react";
import { useCallback, useMemo, useState } from "react";
import { Control } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { IElectStageError } from "../../../ElectStage";
import ElectCardUserInputCell from "../ElectCardUserInputCell/ElectCardUserInputCell";
import { FinancingColumnsCellRenderer } from "../FinancingColumnsCellRenderer/FinancingColumnsCellRenderer";
import { OperatorColumnsCellRenderer } from "../OperatorColumnsCellRenderer/OperatorColumnsCellRenderer";
import { OperatorHeader } from "../OperatorHeader/OperatorHeader";

export const useElectCardGrid = ({
  tooltips,
  control,
  error,
}: {
  tooltips: IElectCardGridTooltips;
  control: Control<IElectStage>;
  error?: IElectStageError;
}) => {
  const [cellKeyDownFocusIndex, setCellKeyDownFocusIndex] = useState<
    number | null
  >(null);
  const dispatch = useDispatch();
  const electCardData: IElectCardData = useSelector(selectElectCardData);
  const financing = electCardData.financing;

  const { data, totalRows } = useMemo(() => {
    return {
      data: electCardData.electCardData,
      totalRows: [electCardData.totalRow],
    };
  }, [electCardData]);

  const onCellFocused = useCallback(
    (event: CellFocusedEvent) => {
      const focusedCell = event.api.getFocusedCell();
      const newIndex =
        event.rowPinned === "bottom"
          ? electCardData.electCardData.length - 1
          : focusedCell != null
          ? focusedCell.rowIndex
          : 0;
      setCellKeyDownFocusIndex(newIndex);
    },
    [electCardData, setCellKeyDownFocusIndex]
  );

  const onCellFocusChange = useCallback(
    (value: number, strategyId: number) => {
      const currentStrategyValue = electCardData.electCardData.find(
        (x) => x.strategyId === strategyId
      );

      if (!currentStrategyValue) {
        return;
      }

      if (
        !isNaN(value) &&
        (!isSomething(currentStrategyValue.optionalElection) ||
          value !== zeroIfNothing(currentStrategyValue.optionalElection))
      ) {
        dispatch(
          updateOptionalElectionByStrategy({
            strategyId: strategyId,
            electedAmount: some(value),
          })
        );
      }
    },
    [electCardData, dispatch]
  );

  const onCellClear = useCallback(
    (strategyId: number) => {
      const currentStrategyValue = electCardData.electCardData.find(
        (x) => x.strategyId === strategyId
      );

      if (!currentStrategyValue) {
        return;
      }

      dispatch(
        updateOptionalElectionByStrategy({
          strategyId: strategyId,
          electedAmount: nothing,
        })
      );
    },
    [electCardData, dispatch]
  );

  const onCellKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>, rowIndex: number) => {
      const { key, shiftKey } = event;
      if (key === "Tab" && rowIndex !== null) {
        setCellKeyDownFocusIndex(
          shiftKey ? (rowIndex > 0 ? rowIndex - 1 : null) : rowIndex + 1
        );
      }
    },
    []
  );

  const colDefs = useMemo(() => {
    const totalRow = electCardData.totalRow;
    const showTotalForcastedInvestment =
      electCardData.showTotalForcastedInvestment;
    const electCardColumnDefs: ColDef<IElectCardGridRow>[] = [];
    electCardColumnDefs.push({
      field: ElectCardGridKeys.STRATEGY,
      headerName: ElectionsLabels.STRATEGY,
      minWidth: 236,
      pinned: "left",
    });

    isSomething(totalRow.strategyMax) &&
      totalRow.strategyMax.value > 0 &&
      electCardColumnDefs.push({
        field: ElectCardGridKeys.STRATEGY_MAX,
        headerName: ElectionsLabels.STRATEGY_MAXIMUM,
        valueFormatter: currencyFormatterForOptionalNumberWithMiddleDashDefault,
        minWidth: 156,
        type: "rightAligned",
      });

    if (totalRow.remainingCommitment > 0) {
      electCardColumnDefs.push({
        field: ElectCardGridKeys.REMAINING_COMMITMENT,
        headerName: ElectionsLabels.ANTICIPATED_PRIOR_PERIODS_CAPITAL_CALLS,
        headerComponent: GridHeaderWithTooltip,
        headerComponentParams: {
          labelClass: "ag-sub-header-group-cell-label",
          textClass: "ag-sub-header-group-text",
          tooltipContent: (
            <Markdown text={tooltips.remainingCommitmentTooltip} />
          ),
        },
        valueFormatter: currencyFormatterWithZeroDefault,
        minWidth: 216,
        type: "rightAligned",
      });

      electCardColumnDefs.push({
        field: ElectCardGridKeys.FIRST_OPERATOR,
        headerComponent: OperatorHeader,
        headerComponentParams: {
          line: ElectionsLabels.PLUS_SIGN,
        },
        valueFormatter: operatorFormatterForOptionalStringWithEmptyDefault,
        maxWidth: 50,
      });
    }

    if (totalRow.mandatoryCommitment > 0) {
      electCardColumnDefs.push({
        field: ElectCardGridKeys.MANDATORY_COMMITMENT,
        headerName: `${tooltips.mandatoryCommitmentYear} ${ElectionsLabels.MANDATORY_COMMITMENT}`,
        headerComponent: GridHeaderWithTooltip,
        headerComponentParams: {
          labelClass: "ag-sub-header-group-cell-label",
          textClass: "ag-sub-header-group-text",
          tooltipContent: [tooltips.mandatoryCommitmentTooltip],
        },
        valueFormatter: currencyFormatterWithZeroDefault,
        minWidth: 170,
        type: "rightAligned",
      });

      electCardColumnDefs.push({
        field: ElectCardGridKeys.SECOND_OPERATOR,
        headerComponent: OperatorHeader,
        headerComponentParams: {
          line: ElectionsLabels.PLUS_SIGN,
        },
        valueFormatter: operatorFormatterForOptionalStringWithEmptyDefault,
        maxWidth: 50,
      });
    }

    electCardColumnDefs.push({
      field: ElectCardGridKeys.OPTIONAL_ELECTION,
      headerComponent: MultiLineHeader,
      headerComponentParams: {
        lines: ElectionsLabels.OPT_ELECTION_HEADER_LINES(tooltips),
      },
      type: "rightAligned",
      cellRenderer: ElectCardUserInputCell,
      cellRendererParams: {
        onFocusChange: onCellFocusChange,
        onKeyDown: onCellKeyDown,
        onCellClear: onCellClear,
        control: control,
        error: error,
        financing: financing,
        canRequestAdditional: electCardData.canRequestAdditional,
        focusIndex: cellKeyDownFocusIndex,
      },
      minWidth: 270,
      suppressNavigable: (params) => {
        return !!params.node.rowPinned;
      },
      valueFormatter: (params) => {
        if (!params.data) {
          return "";
        }
        return zeroIfNothing(params.data.optionalElection).toString();
      },
    });

    electCardColumnDefs.push({
      field: ElectCardGridKeys.THIRD_OPERATOR,
      headerComponent: OperatorHeader,
      headerComponentParams: {
        line: ElectionsLabels.EQUAL_SIGN,
      },
      cellRenderer: OperatorColumnsCellRenderer,
      cellRendererParams: {
        operatorValues: financing.thirdOperator,
      },
      maxWidth: 50,
    });

    electCardColumnDefs.push({
      field: ElectCardGridKeys.TOTAL_COMMITMENT,
      headerName: ElectionsLabels.TOTAL_COMMITMENT,
      cellRenderer: FinancingColumnsCellRenderer,
      cellRendererParams: {
        financingValues: financing.totalCommitment,
      },
      minWidth: 156,
      type: "rightAligned",
    });

    showTotalForcastedInvestment &&
      electCardColumnDefs.push({
        field: ElectCardGridKeys.TOTAL_FORECAST_INVESTMENT,
        headerName: ElectionsLabels.TOTAL_FORECASTED_INVESTMENT,
        headerComponent: GridHeaderWithTooltip,
        headerComponentParams: {
          labelClass: "ag-sub-header-group-cell-label",
          textClass: "ag-sub-header-group-text",
          tooltipContent: [tooltips.totalForecastedInvestmentTooltip],
        },
        cellRenderer: FinancingColumnsCellRenderer,
        cellRendererParams: {
          financingValues: financing.totalForecastedInvestment,
        },
        minWidth: 236,
        type: "rightAligned",
      });
    return electCardColumnDefs;
  }, [
    electCardData.totalRow,
    electCardData.showTotalForcastedInvestment,
    electCardData.canRequestAdditional,
    tooltips,
    onCellFocusChange,
    onCellKeyDown,
    onCellClear,
    control,
    error,
    financing,
    cellKeyDownFocusIndex,
  ]);

  const defaultColDef: ColDef<IElectCardGridRow> = useMemo(
    () => ({
      resizable: false,
      filter: false,
      sortable: false,
      minWidth: 110,
      menuTabs: [],
      suppressNavigable: true,
      suppressKeyboardEvent: () => true,
      suppressHeaderMenuButton: true,
    }),
    []
  );

  return {
    colDefs,
    defaultColDef,
    onCellFocused,
    onCellClear,
    data,
    totalRows,
    financing,
  };
};
