import { createTheme } from "@mui/material";
import type {} from "@mui/x-date-pickers/themeAugmentation";

import { CustomAccordionTheme } from "./components/accordion";
import { CustomAlertTheme } from "./components/alert";
import { CustomAutocompleteTheme } from "./components/autocomplete";
import { CustomBadgeTheme } from "./components/badge";
import { CustomBreadcrumbsTheme } from "./components/breadcrumbs";
import { CustomButtonTheme } from "./components/button";
import { CustomCardHeaderTheme, CustomCardTheme } from "./components/card";
import { CustomCheckboxTheme } from "./components/checkbox";
import { CustomChipTheme } from "./components/chip";
import { CustomDayCalendarTheme } from "./components/dayCalendar";
import {
  CustomDialogActionsTheme,
  CustomDialogContentTheme,
  CustomDialogTitleTheme,
} from "./components/dialog";
import { CustomFabTheme } from "./components/floatingActionButton";
import { CustomFormControlTheme } from "./components/formControl";
import { CustomFormControlLabelTheme } from "./components/formControlLabel";
import { CustomMuiFormHelperTextTheme } from "./components/formHelper";
import { CustomFormLabelTheme } from "./components/formLabel";
import { CustomIconButtonTheme } from "./components/iconButton";
import { CustomInputBaseTheme } from "./components/inputBase";
import { CustomLinkTheme } from "./components/link";
import { CustomMenuTheme } from "./components/menu";
import { CustomMenuItemTheme } from "./components/menuItem";
import { CustomPickersCalendarHeaderTheme } from "./components/pickerCalendarHeader";
import { CustomPickersDayTheme } from "./components/pickersDay";
import { CustomRadioTheme } from "./components/radio";
import { CustomSelectTheme } from "./components/select";
import {
  CustomStepLabelTheme,
  CustomStepperTheme,
  CustomStepTheme,
} from "./components/stepper";
import { CustomSwitchTheme } from "./components/switch";
import { CustomTableCellTheme } from "./components/tableCell";
import { CustomTabsTheme, CustomTabTheme } from "./components/tabs";
import { CustomTextFieldTheme } from "./components/textField";
import {
  CustomToggleButtonGroup,
  CustomToggleButtonTheme,
} from "./components/toggleButton";
import { CustomTooltipTheme } from "./components/tooltip";
import { Font } from "./sharedStyleConstants";

const colors = {
  copper: "#a95228",
  avocado: "#4c9f8b",
  red: "#b03f2f",
  warning_yellow: "#f1c21b",
  cobalt: "#006492",
  bx_primary_grey_text: "#797c81",
  black: "#000000",
};

export const SharedCustomTheme = createTheme({
  palette: {
    primary: {
      main: colors.copper ?? "#000000",
    },
    secondary: {
      main: colors.black ?? "#000000",
    },
    success: {
      main: colors.avocado ?? "#000000",
    },
    error: {
      main: colors.red ?? "#000000",
    },
    warning: {
      main: colors.warning_yellow ?? "#000000",
    },
    info: {
      main: colors.cobalt ?? "#000000",
    },
  },
  typography: {
    body1: {
      fontFamily: Font.Family.GUARDIAN_SANS,
      fontSize: Font.Size.STANDARD,
      fontWeight: Font.Weight.STANDARD,
      lineHeight: "24px",
    },
    body2: {
      fontFamily: Font.Family.GUARDIAN_SANS,
      fontSize: Font.Size.STANDARD,
      fontWeight: Font.Weight.MEDIUM,
      lineHeight: "24px",
    },
    h1: {
      fontFamily: Font.Family.GUARDIAN_SANS,
      fontSize: Font.Size.LARGE,
      fontWeight: Font.Weight.MEDIUM,
      lineHeight: "24px",
    },
    h2: {
      fontFamily: Font.Family.GUARDIAN_SANS,
      fontSize: "18px",
      fontWeight: Font.Weight.SEMI_BOLD,
      lineHeight: "24px",
    },
    h3: {
      fontFamily: Font.Family.GUARDIAN_SANS,
      fontSize: "18px",
      fontWeight: Font.Weight.STANDARD,
      lineHeight: "24px",
    },
    h4: {
      fontFamily: Font.Family.GUARDIAN_SANS,
      fontSize: "14px",
      fontWeight: Font.Weight.MEDIUM,
    },
    h5: {
      fontFamily: Font.Family.GUARDIAN_SANS,
      fontSize: "14px",
      fontWeight: Font.Weight.STANDARD,
    },
    h6: {
      fontFamily: Font.Family.GUARDIAN_SANS,
      fontSize: "12px",
      fontWeight: Font.Weight.STANDARD,
    },
    subtitle1: {
      color: colors.bx_primary_grey_text,
      fontFamily: Font.Family.GUARDIAN_SANS,
      fontSize: Font.Size.STANDARD,
      fontWeight: Font.Weight.STANDARD,
      lineHeight: "19px",
    },
    subtitle2: {
      fontFamily: Font.Family.GUARDIAN_SANS,
      fontSize: Font.Size.STANDARD,
      fontWeight: Font.Weight.STANDARD,
      marginBottom: "14px",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
  },
  components: {
    MuiAccordion: CustomAccordionTheme,
    MuiAlert: CustomAlertTheme,
    MuiAutocomplete: CustomAutocompleteTheme,
    MuiBadge: CustomBadgeTheme,
    MuiBreadcrumbs: CustomBreadcrumbsTheme,
    MuiButton: CustomButtonTheme,
    MuiCard: CustomCardTheme,
    MuiCardHeader: CustomCardHeaderTheme,
    MuiCheckbox: CustomCheckboxTheme,
    MuiChip: CustomChipTheme,
    MuiDayCalendar: CustomDayCalendarTheme,
    MuiDialogActions: CustomDialogActionsTheme,
    MuiDialogContent: CustomDialogContentTheme,
    MuiDialogTitle: CustomDialogTitleTheme,
    MuiFab: CustomFabTheme,
    MuiFormControl: CustomFormControlTheme,
    MuiFormControlLabel: CustomFormControlLabelTheme,
    MuiFormHelperText: CustomMuiFormHelperTextTheme,
    MuiInputBase: CustomInputBaseTheme,
    MuiLink: CustomLinkTheme,
    MuiMenu: CustomMenuTheme,
    MuiMenuItem: CustomMenuItemTheme,
    MuiPickersCalendarHeader: CustomPickersCalendarHeaderTheme,
    MuiPickersDay: CustomPickersDayTheme,
    MuiSelect: CustomSelectTheme,
    MuiStep: CustomStepTheme,
    MuiStepLabel: CustomStepLabelTheme,
    MuiStepper: CustomStepperTheme,
    MuiSwitch: CustomSwitchTheme,
    MuiToggleButton: CustomToggleButtonTheme,
    MuiToggleButtonGroup: CustomToggleButtonGroup,
    MuiTableCell: CustomTableCellTheme,
    MuiTab: CustomTabTheme,
    MuiTabs: CustomTabsTheme,
    MuiTextField: CustomTextFieldTheme,
    MuiTooltip: CustomTooltipTheme,
    MuiRadio: CustomRadioTheme,
    MuiFormLabel: CustomFormLabelTheme,
    MuiIconButton: CustomIconButtonTheme,
  },
});
