import {
  BankAccountConstants,
  EntityType,
  hasUnsubmittedChangesContribution,
  hasUnsubmittedChangesDistribution,
  IBaseStore,
  IESignature,
  isSomething,
  LoadingIndicator,
  SelectableBankAccount,
  StringConstants,
  submitBankAccountChanges,
} from "common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { postSubmitBankAccounts } from "../../../services/bankAccountsService";
import { ElectionDialog } from "../../Elections/ElectionWorkflow/Shared/ElectionDialog/ElectionDialog";
import { DownloadAgreementDialog } from "../DownloadAgreementDialog/DownloadAgreementDialog";
import styles from "./ReviewAndSignDialog.module.scss";
import {
  ReviewAndSignForm,
  ReviewAndSignFormState,
} from "./ReviewAndSignForm/ReviewAndSignForm";
import { SubmissionErrorDialog } from "./SubmissionErrorDialog/SubmissionErrorDialog";

export interface ReviewAndSignDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  retryFunction: () => void;
}

export const ReviewAndSignDialog = (props: ReviewAndSignDialogProps) => {
  const { open, setOpen, retryFunction } = props;
  const dispatch = useDispatch();
  const [submissionError, setSubmissionError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true);
  const [eSignature, setESignature] = useState<IESignature>();
  const [isDownloadAgreementDialogOpen, setIsDownloadAgreementDialogOpen] =
    useState<boolean>(false);
  const handleCloseDownloadModal = () => {
    reviewAndSignForm.reset();
    setInitialFormValues();
    setIsDownloadAgreementDialogOpen(false);
    retryFunction();
  };
  const unsubmittedContribution = useSelector(
    hasUnsubmittedChangesContribution
  );
  const unsubmittedDistribution = useSelector(
    hasUnsubmittedChangesDistribution
  );

  const {
    isBankAccountsAdminMode,
    selectedContributionAccount,
    selectedDistributionAccount,
    bankAccountsInvestmentVehicleId,
  } = useSelector((store: IBaseStore) => store.bankAccounts);

  const { investmentVehicles } = useSelector(
    (store: IBaseStore) => store.bankAccounts.serverData
  );

  const investmentVehicle = useMemo(() => {
    if (isSomething(bankAccountsInvestmentVehicleId)) {
      return investmentVehicles.find(
        (iv) => iv.axiomId === bankAccountsInvestmentVehicleId.value
      );
    }
  }, [bankAccountsInvestmentVehicleId, investmentVehicles]);

  const reviewAndSignForm = useForm<ReviewAndSignFormState>({
    mode: "onChange",
    defaultValues: {
      reviewAndSign: {
        investmentVehicle: investmentVehicle?.name,
        contributionDebitAccount: BankAccountConstants.NO_UPDATE,
        distributionDepositAccount: BankAccountConstants.NO_UPDATE,
        signature: "",
        phoneNumber: "",
        signatureInMyCapacityAs: "",
        agreesToTerms: false,
      },
    },
  });
  const watchAllFields = reviewAndSignForm.watch();

  const setInitialFormValues = useCallback(() => {
    if (unsubmittedContribution && isSomething(selectedContributionAccount)) {
      reviewAndSignForm.setValue(
        "reviewAndSign.contributionDebitAccount",
        selectedContributionAccount.value.main.accountNumber
      );
    } else {
      reviewAndSignForm.setValue(
        "reviewAndSign.contributionDebitAccount",
        BankAccountConstants.NO_UPDATE
      );
    }
    if (unsubmittedDistribution && isSomething(selectedDistributionAccount)) {
      reviewAndSignForm.setValue(
        "reviewAndSign.distributionDepositAccount",
        selectedDistributionAccount.value.main.accountNumber
      );
    } else {
      reviewAndSignForm.setValue(
        "reviewAndSign.distributionDepositAccount",
        BankAccountConstants.NO_UPDATE
      );
    }
  }, [
    reviewAndSignForm,
    selectedContributionAccount,
    selectedDistributionAccount,
    unsubmittedContribution,
    unsubmittedDistribution,
  ]);

  useEffect(() => {
    setInitialFormValues();
  }, [setInitialFormValues]);

  const checkRequiredFieldsCompleted = useCallback(() => {
    reviewAndSignForm
      .trigger([
        "reviewAndSign.agreesToTerms",
        "reviewAndSign.signature",
        "reviewAndSign.phoneNumber",
        "reviewAndSign.signatureInMyCapacityAs",
      ])
      .then((allCompleted) => setNextButtonDisabled(!allCompleted));
  }, [reviewAndSignForm]);

  useEffect(() => {
    if (open && !loading) {
      checkRequiredFieldsCompleted();
    }
  }, [checkRequiredFieldsCompleted, open, loading, watchAllFields]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setNextButtonDisabled(true);
    reviewAndSignForm.reset();
    setInitialFormValues();
  }, [reviewAndSignForm, setInitialFormValues, setOpen]);

  const [selectedBankAccounts, setSelectedBankAccounts] = useState<
    SelectableBankAccount[]
  >([]);

  const handleNext = useCallback(async () => {
    if (investmentVehicle) {
      setLoading(true);
      setNextButtonDisabled(true);
      const { reviewAndSign: reviewAndSignState } =
        reviewAndSignForm.getValues();

      const eSignature: IESignature = {
        phone: reviewAndSignState.phoneNumber,
        signature: reviewAndSignState.signature,
        agreesToTerms: reviewAndSignState.agreesToTerms,
        alternateSignatoryCapacity:
          investmentVehicle.entityType === EntityType.ORGANIZATION
            ? reviewAndSignState.signatureInMyCapacityAs
            : undefined,
      };

      const bankAccounts: SelectableBankAccount[] = [];
      if (unsubmittedContribution && isSomething(selectedContributionAccount)) {
        bankAccounts.push(selectedContributionAccount.value);
      }
      if (unsubmittedDistribution && isSomething(selectedDistributionAccount)) {
        bankAccounts.push(selectedDistributionAccount.value);
      }
      setSelectedBankAccounts(bankAccounts);

      postSubmitBankAccounts(
        investmentVehicle.mdmId,
        bankAccounts,
        eSignature,
        isBankAccountsAdminMode
      )
        .then(() => {
          setESignature(eSignature);
          setOpen(false);
          setIsDownloadAgreementDialogOpen(true);
          setInitialFormValues();
          dispatch(submitBankAccountChanges());
        })
        .catch(() => {
          setNextButtonDisabled(false);
          setSubmissionError(true);
        })
        .finally(() => setLoading(false));
    }
  }, [
    investmentVehicle,
    reviewAndSignForm,
    unsubmittedContribution,
    selectedContributionAccount,
    unsubmittedDistribution,
    selectedDistributionAccount,
    setOpen,
    setInitialFormValues,
    dispatch,
    isBankAccountsAdminMode,
  ]);

  return (
    <>
      <ElectionDialog
        title={BankAccountConstants.REVIEW_AND_SIGN_BANK_ACCOUNT}
        content={
          <ReviewAndSignForm
            reviewAndSignForm={reviewAndSignForm}
            investmentVehicle={investmentVehicle}
          />
        }
        open={open}
        handleClose={handleClose}
        handleNext={handleNext}
        cancelButtonLabel={StringConstants.CANCEL}
        nextButtonLabel={BankAccountConstants.AGREE_AND_SUBMIT_TO_TREASURY}
        nextButtonDisabled={
          nextButtonDisabled ||
          !(unsubmittedContribution || unsubmittedDistribution)
        }
        showOverlayComponent={loading}
        overlayComponent={<LoadingIndicator className={styles.loading} />}
      />
      <DownloadAgreementDialog
        open={isDownloadAgreementDialogOpen}
        onClose={handleCloseDownloadModal}
        eSignature={eSignature}
        investmentVehicle={investmentVehicle}
        bankAccounts={selectedBankAccounts}
      />
      <SubmissionErrorDialog
        open={submissionError}
        handleClose={() => setSubmissionError(false)}
      />
    </>
  );
};
