import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { Grid } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import {
  hideTooltips,
  IElectCardGridRow,
  IElectCardGridTooltips,
  useGridExtensions,
} from "common";
import React from "react";
import { useSelector } from "react-redux";

import { selectCanUseFinancing } from "../../../../../../redux/selectors";
import { IElectStageFormProps } from "../../ElectStage";
import styles from "./ElectCardGrid.module.scss";
import { useElectCardGrid } from "./Hooks/useElectCardGrid";

interface IElectCardGridProps extends IElectStageFormProps {
  tooltips: IElectCardGridTooltips;
}

export const ElectCardGrid: React.FC<IElectCardGridProps> = ({
  control,
  error,
  tooltips,
}) => {
  const { colDefs, defaultColDef, onCellFocused, data, totalRows, financing } =
    useElectCardGrid({
      control,
      error,
      tooltips,
    });

  const { setHeaderHeight, resizeColumns, onGridReady } = useGridExtensions();

  const canUseFinancing = useSelector(selectCanUseFinancing);

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} className={`ag-theme-alpine`} id={styles.dataGrid}>
        <AgGridReact<IElectCardGridRow>
          rowData={data}
          pinnedBottomRowData={totalRows}
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          domLayout="autoHeight"
          onFirstDataRendered={setHeaderHeight}
          onColumnResized={setHeaderHeight}
          cacheQuickFilter={true}
          suppressAggFuncInHeader={true}
          suppressContextMenu={true}
          suppressCellFocus={false}
          onRowDataUpdated={resizeColumns}
          onGridSizeChanged={resizeColumns}
          onDisplayedColumnsChanged={resizeColumns}
          onBodyScroll={hideTooltips}
          suppressMenuHide={true}
          getRowHeight={(params) => {
            if (params.node.rowPinned && canUseFinancing) {
              if (
                financing.totalCommitment.loanLimit > 0 ||
                financing.totalForecastedInvestment.loanLimit > 0
              ) {
                return 280;
              }
              return 235;
            }
            return 50;
          }}
          suppressDragLeaveHidesColumns={true}
          suppressMovableColumns={true}
          suppressHeaderFocus={true}
          onGridReady={onGridReady}
          reactiveCustomComponents={true}
          onCellFocused={onCellFocused}
        />
      </Grid>
    </Grid>
  );
};
