import { call, put } from "redux-saga/effects";

import { DataLoadStatus, PeriodHash } from "../../constants/enums";
import { BANK_ACCOUNT_PERMISSIONS } from "../../constants/permissions";
import {
  getAdminEntitlements,
  getAllEntitlements,
} from "../../services/entitlementDataService";
import {
  IBankAccountInvestmentVehicle,
  IBankAccountPermittedClient,
  IBankAccountsCapabilities,
} from "../../types/bankAccountDataTypes";
import {
  IInvestmentVehicleByClientByPeriod,
  IPermittedEntities,
} from "../../types/dataTypes";
import {
  ClientId,
  IElectionPermissions,
  IPermittedClient,
} from "../../types/electionDataTypes";
import {
  AdminEntitlementSummary,
  ViewElectionResultPage,
} from "../../types/storeTypes";
import { nothing, some } from "../../types/typeUtils";
import { reqDocumentClients } from "../actions/documentActions";
import { recvPermittedElectionClients } from "../actions/electionsActions";
import {
  errAdminEntitlements,
  errCommitmentsEntitlements,
  errDocumentsEntitlements,
  errEquityEntitlements,
  errInternalInvestmentDataPermittedEntities,
  errRestrictedEquityEntitlement,
  errStockEntitlements,
  recvAdminEntitlements,
  recvBankAccountsEntitlements,
  recvCanViewElectionResultPage,
  recvCommitmentsEntitlement,
  recvDocumentsEntitlement,
  recvElectionAdminEntitlements,
  recvEquityEntitlement,
  recvInternalInvestmentDataPermittedEntities,
  recvRestrictedEquityEntitlement,
  recvStockEntitlement,
} from "../actions/entitlementActions";
import { reqEquityEmployees } from "../actions/equityActions";
import {
  reqInternalInvestmentClients,
  setInternalInvestmentDataFilters,
} from "../actions/internalInvestmentActions";

/**
 * Gets the permitted entity that'll be shown
 * Behavior:
 * - If a BXWealth user has only one Investment Vehicle,
 * option for Investment Vehicle selection is not available
 * and screen will default to Investment Vehicle view.
 * - If a BXWealth user has more than one Investment Vehicle,
 * default view will be Client-level data.
 * @param permittedEntities - A list of permitted entities
 * @returns An investment vehicle by client by period.
 */
const getActiveViewPermittedEntity = (
  permittedEntities: IPermittedEntities
): IInvestmentVehicleByClientByPeriod => {
  // for now there is only one client being returned
  const firstClient = permittedEntities.clients[0];
  const clientId = firstClient.id;
  const investmentVehicleId = undefined;

  return {
    clientId,
    investmentVehicleId,
    periodId: PeriodHash.LATEST,
  } as IInvestmentVehicleByClientByPeriod;
};

function* resolveInvestmentDataPermittedEntities(
  permittedEntities: IPermittedEntities
) {
  if (permittedEntities != undefined) {
    // depending on output, put permitted entities to the store
    if (permittedEntities.clients.length === 0) {
      yield put(recvInternalInvestmentDataPermittedEntities(nothing));
    } else {
      const activePermittedEntity =
        getActiveViewPermittedEntity(permittedEntities);
      yield put(
        recvInternalInvestmentDataPermittedEntities(some(permittedEntities))
      );
      yield put(setInternalInvestmentDataFilters(activePermittedEntity));
    }
  } else {
    yield put(
      errInternalInvestmentDataPermittedEntities(DataLoadStatus.UNSUCCESSFUL)
    );
  }
}

function* resolveEquityDataEntitlements(equityDataEntitlements: string[]) {
  if (equityDataEntitlements != undefined) {
    //filter out the admin level from equity data entitlement, if it exists for this user
    const equityAdminIndex = equityDataEntitlements.indexOf("");
    if (equityAdminIndex !== -1) {
      equityDataEntitlements.splice(equityAdminIndex, 1);
    }
    // depending on output, put equity Data and stocks Entitlements to store
    if (
      equityDataEntitlements === undefined ||
      equityDataEntitlements.length === 0
    ) {
      yield put(recvEquityEntitlement([]));
      yield put(recvStockEntitlement(false));
    } else {
      yield put(recvEquityEntitlement(equityDataEntitlements));
      yield put(recvStockEntitlement(true));
    }
  } else {
    yield put(errEquityEntitlements(DataLoadStatus.UNSUCCESSFUL));
    yield put(errStockEntitlements(DataLoadStatus.UNSUCCESSFUL));
  }
}

// For MVP this returns the lowest ID the employee is permissioned to
// This means a user can only be permissioned to one dataset at a time.
export const hasDocumentViewEntitlement = (entitlements: string[]): boolean => {
  if (entitlements === undefined || entitlements.length === 0) {
    return false;
  }
  return entitlements.includes("documents-page");
};

function* resolveDocumentsEntitlements(documentsEntitlements: string[]) {
  if (documentsEntitlements != undefined) {
    // depending on output, put documents Entitlements to store
    if (
      documentsEntitlements === undefined ||
      documentsEntitlements.length === 0
    ) {
      yield put(recvDocumentsEntitlement(nothing));
    } else {
      const hasViewDocEntitlement = hasDocumentViewEntitlement(
        documentsEntitlements
      );
      yield put(recvDocumentsEntitlement(some(hasViewDocEntitlement)));
      yield put(reqDocumentClients(hasViewDocEntitlement));
    }
  } else {
    yield put(errDocumentsEntitlements(DataLoadStatus.UNSUCCESSFUL));
  }
}

function* resolveCommitmentsEntitlement(commitmentEntitlements: string[]) {
  if (commitmentEntitlements != undefined) {
    // check if any entitlements
    if (commitmentEntitlements.length === 0) {
      yield put(recvCommitmentsEntitlement(nothing));
    } else {
      // check it list of entitlement includes commitments view page
      const hasViewCommitmentsEntitlement =
        commitmentEntitlements.includes("commitments-page");
      yield put(
        recvCommitmentsEntitlement(some(hasViewCommitmentsEntitlement))
      );
    }
  } else {
    yield put(errCommitmentsEntitlements(DataLoadStatus.UNSUCCESSFUL));
  }
}

function* resolveRestrictedEquityEntitlement(entitlements: string[]) {
  if (entitlements === undefined || entitlements.length === 0) {
    yield put(errRestrictedEquityEntitlement(DataLoadStatus.EMPTY_RESPONSE));
  }

  yield put(
    recvRestrictedEquityEntitlement(
      some(entitlements.includes("restricted-equity"))
    )
  );
}

// For MVP this returns the lowest ID the employee is permissioned to
// This means a user can only be permissioned to one dataset at a time.
export const hasElectionsViewEntitlement = (
  entitlements: string[]
): boolean => {
  if (entitlements === undefined || entitlements.length === 0) {
    return false;
  }
  return entitlements.includes("elections-page");
};

const requiredClientCapabilities = [
  "read-election-iv-configuration",
  "read-election-workflow-state",
  "write-election-workflow-state",
  "read-investment-portfolio",
];

const requiredGlobalCapabilities = [
  "read-election-round-configuration",
  "read-election-document",
];

const requiredElectionAdminCapabilities = [
  "read-election-round-configuration",
  "write-election-round-configuration",
  "read-election-iv-configuration",
  "write-election-iv-configuration",
  "read-election-workflow-state",
  "read-investment-portfolio",
  "read-strategies",
  "write-strategies",
  "read-election-document",
  "write-election-document",
  "read-election-permission",
  "write-election-permission",
  "read-election-result",
];

const requiredAdminOnlyScopedCapabilities = ["write-election-workflow-state"];

function* resolveElectionsEntitlements(
  electionsEntitlements: IElectionPermissions
) {
  if (
    electionsEntitlements === undefined ||
    Object.keys(electionsEntitlements.clientCapabilities).length === 0
  ) {
    yield put(recvPermittedElectionClients(nothing));
  } else {
    const permittedClients: IPermittedClient[] = [];
    const foundClientIds: ClientId[] = Object.keys(
      electionsEntitlements.clientCapabilities
    ).map(Number);
    const hasAllGlobalReqs = requiredGlobalCapabilities.every(
      (permission: string) =>
        electionsEntitlements.globalCapabilities.includes(permission)
    );
    if (hasAllGlobalReqs) {
      foundClientIds.forEach((clientId: ClientId) => {
        const clientPermissions: string[] =
          electionsEntitlements.clientCapabilities[clientId].capabilities;
        const hasAllClientLevelReqs = requiredClientCapabilities.every(
          (permission: string) => clientPermissions.includes(permission)
        );
        if (hasAllClientLevelReqs) {
          permittedClients.push({
            clientId: clientId,
            clientName:
              electionsEntitlements.clientCapabilities[clientId].clientName,
            investmentVehicles:
              electionsEntitlements.clientCapabilities[clientId]
                .investmentVehicles,
          });
        }
      });
    }

    if (permittedClients.length === 0) {
      yield put(recvPermittedElectionClients(nothing));
    } else {
      yield put(recvPermittedElectionClients(some(permittedClients)));
    }
  }
}

const requiredBankAccountsPermissions = [
  BANK_ACCOUNT_PERMISSIONS.READ.BANK_ACCOUNTS,
];

function* resolveBankAccountsEntitlements(
  bankAccountsEntitlements: IBankAccountsCapabilities
) {
  const permittedClients: IBankAccountPermittedClient[] = [];
  const clientIds: number[] = Object.keys(
    bankAccountsEntitlements?.clientCapabilities || {}
  ).map(Number);
  const hasAllGlobalReqs = requiredBankAccountsPermissions.every(
    (permission: string) =>
      bankAccountsEntitlements?.globalCapabilities?.includes(permission)
  );

  clientIds.forEach((clientId: ClientId) => {
    const permittedInvestmentVehicles: IBankAccountInvestmentVehicle[] = [];
    const client = bankAccountsEntitlements.clientCapabilities[clientId];
    client.investmentVehicles.forEach((iv: IBankAccountInvestmentVehicle) => {
      const hasReqPermissions = requiredBankAccountsPermissions.every(
        (permission: string) => iv.capabilities.includes(permission)
      );
      if (hasReqPermissions) {
        permittedInvestmentVehicles.push(iv);
      }
    });
    if (permittedInvestmentVehicles.length > 0) {
      permittedClients.push({
        clientId,
        clientName:
          bankAccountsEntitlements.clientCapabilities[clientId].clientName,
        investmentVehicles: permittedInvestmentVehicles,
      });
    }
  });

  yield put(
    recvBankAccountsEntitlements(
      some({
        globalCapabilities: hasAllGlobalReqs
          ? requiredBankAccountsPermissions
          : [],
        permittedBankAccountClients: permittedClients,
      })
    )
  );
}

export const hasInternalInvestmentsAdminEntitlement = (
  entitlements: string[]
): boolean => {
  return entitlements.includes("");
};

function* resolveAdminEntitlements(adminEntitlements: AdminEntitlementSummary) {
  if (adminEntitlements != undefined) {
    yield put(recvAdminEntitlements(some(adminEntitlements)));
    if (adminEntitlements.canViewAllInternalInvestmentData) {
      yield put(reqInternalInvestmentClients());
    }
    if (adminEntitlements.canViewAllEquityData) {
      yield put(reqEquityEmployees());
    }
  } else {
    yield put(errAdminEntitlements(DataLoadStatus.UNSUCCESSFUL));
  }
}

function* resolveElectionAdminEntitlements(
  electionsEntitlements: IElectionPermissions,
  canViewElectionResultPage: boolean
) {
  if (
    electionsEntitlements != undefined &&
    electionsEntitlements.globalCapabilities.length > 0 &&
    electionsEntitlements.adminOnlyScopedCapabilities.length > 0
  ) {
    const isElectionsAdmin =
      requiredElectionAdminCapabilities.every((capabilty: string) =>
        electionsEntitlements.globalCapabilities.includes(capabilty)
      ) &&
      requiredAdminOnlyScopedCapabilities.every((capabilty: string) =>
        electionsEntitlements.adminOnlyScopedCapabilities.includes(capabilty)
      );
    yield put(recvElectionAdminEntitlements(isElectionsAdmin));
  } else {
    yield put(recvElectionAdminEntitlements(false));
  }
  yield put(recvCanViewElectionResultPage(canViewElectionResultPage));
}

export function* fetchViewEntitlements() {
  const [
    investmentDataPermittedEntities,
    equityDataEntitlements,
    bxwealthServiceEntitlements,
    electionsEntitlements,
    bankAccountsEntitlements,
  ]: [
    IPermittedEntities,
    string[],
    string[],
    IElectionPermissions,
    IBankAccountsCapabilities
  ] = yield call(getAllEntitlements);
  yield resolveInvestmentDataPermittedEntities(investmentDataPermittedEntities);
  yield resolveEquityDataEntitlements(equityDataEntitlements);
  yield resolveDocumentsEntitlements(bxwealthServiceEntitlements);
  yield resolveCommitmentsEntitlement(bxwealthServiceEntitlements);
  yield resolveElectionsEntitlements(electionsEntitlements);
  yield resolveBankAccountsEntitlements(bankAccountsEntitlements);
  yield resolveRestrictedEquityEntitlement(bxwealthServiceEntitlements);
}

export function* fetchAdminEntitlements() {
  const [adminEntitlements, viewElectionResultPage, electionsEntitlements]: [
    AdminEntitlementSummary,
    ViewElectionResultPage,
    IElectionPermissions
  ] = yield call(getAdminEntitlements);
  yield resolveAdminEntitlements(adminEntitlements);
  yield resolveElectionAdminEntitlements(
    electionsEntitlements,
    viewElectionResultPage.canViewElectionResultPage
  );
}
