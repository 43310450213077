import dayjs from "dayjs";

import { QuarterDate } from "../types/dataTypes";
import { isSomething } from "../types/typeGuards";
import { nothing, Optional, some } from "../types/typeUtils";

// Rounds a number to nearest integer
// If the value is not a number or it is zero or near-zero it will return zero.
// Otherwise, it will round the number to the nearest integer.
export const convertToRoundedValidNumber = (
  value: number | string | undefined
): number => {
  const numberValue = Number(value);
  if (isNaN(numberValue) || Math.abs(numberValue) < 0.001) return 0;
  return Math.round(parseFloat(numberValue.toFixed(2)));
};

// Rounds a number to nearest 2-digit deicmal
export const convertToTwoDigitRoundedDecimal = (
  value: number | string | undefined
): string => {
  const numberValue = Number(value);
  if (isNaN(numberValue)) return "0.00";
  return numberValue.toFixed(2);
};

// Converts a date string to a Date object
export const convertToDateObject = (dateString: string): Date => {
  return dayjs(dateString).toDate();
};

// Strips the date of its timezone information and converts to new date
export const convertToShortDateObject = (dateString: string): Date => {
  const dateSplit = dateString.split("+")[0].split(".")[0];
  return new Date(dateSplit.replaceAll("-", "/").replace("T", " "));
};

export const convertToQuarterDateObject = (dateString: string): QuarterDate => {
  return new QuarterDate(dateString.replaceAll("-", "/"));
};

// Converts a CST to a New york time
export const convertToNYTimeZoneDateObject = (dateString: string): Date => {
  const nyOffset = new Date()
    .toLocaleTimeString("en-US", {
      timeZone: "America/New_York",
      timeZoneName: "longOffset",
    })
    .split(" ")[2];
  const formattedDate = dateString.replaceAll("-", "/").replace("T", " ");
  const nyFormattedDate = `${formattedDate} ${nyOffset}`;
  return new Date(nyFormattedDate);
};

export const convertToOptional = <T>(
  value: T | undefined | null
): Optional<T> => {
  if (value === undefined || value === null) {
    return nothing;
  } else {
    return some(value);
  }
};

export const convertFromOptional = <T>(value: Optional<T>): T | null => {
  return isSomething(value) ? value.value : null;
};

export const convertToOptionalDateObject = (
  sourceValue: string | null | undefined
): Optional<Date> => {
  if (sourceValue) {
    return some(convertToDateObject(sourceValue));
  }
  return nothing;
};

export const convertFromOptionalDateObject = (
  sourceValue: Optional<Date>
): string | null => {
  return isSomething(sourceValue) ? sourceValue.value.toDateString() : null;
};

export const obfuscateDigits = (value: string) => {
  const digitsToObfuscate = value.slice(0, -4);
  const obfuscatedValue = "X".repeat(digitsToObfuscate.length);
  return obfuscatedValue + value.slice(-4);
};
