import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { InputAdornment, Typography } from "@mui/material";
import { ICellRendererParams } from "ag-grid-enterprise";
import {
  convertElectionInputToNumber,
  convertElectionInputToRoundedNumber,
  formatPercentToOneDecimal,
  IElectCardFinancing,
  IElectStage,
  isSomething,
  nothing,
  Optional,
  setIsSBSElectionSaveEnabled,
  some,
  TextFieldWithClear,
  validateElectionForStrategy,
  zeroIfNothing,
} from "common";
import React from "react";
import {
  Control,
  Controller,
  ControllerRenderProps,
  useFieldArray,
} from "react-hook-form";
import { useDispatch } from "react-redux";

import { IElectStageError } from "../../../ElectStage";
import { NumericFormatInput } from "../../NumericFormatInput/NumericFormatInput";
import { ElectionTotalRow } from "../ElectionTotalRow/ElectionTotalRow";
import styles from "./ElectCardUserInputCell.module.scss";

interface IElectCardUserInputCellRendererParams extends ICellRendererParams {
  onFocusChange: (value: number, strategyOId: number) => void;
  onKeyDown: (
    e: React.KeyboardEvent<HTMLInputElement>,
    rowIndex: number | null
  ) => void;
  control: Control<IElectStage>;
  error: IElectStageError;
  financing: IElectCardFinancing;
  canRequestAdditional: boolean;
  focusIndex: number | null;
  onCellClear: (strategyOId: number) => void;
}

export const ElectCardUserInputEditableCell = React.forwardRef(
  (
    props: IElectCardUserInputCellRendererParams,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const strategyId = props.node.data.strategyId;
    const strategyMin: Optional<number> = props.node.data.strategyMin;
    const strategyMax: number = props.node.data.strategyMax;
    const canRequestAdditional: boolean = props.canRequestAdditional;
    const optElectionPct: number = props.node.data.optionalElectionPercentage;
    const rowIndex = props.node.rowIndex;
    const autoFocus = props.focusIndex === rowIndex;

    const dispatch = useDispatch();

    // function to be called when grid values should be refreshed based on user's input
    // happens when user loses context with a text field or hits the 'x' on a text field
    const handleUpdateGridWithNewElection = (
      electedAmount: Optional<number>
    ) => {
      // update stored election with new value
      props.onFocusChange(zeroIfNothing(electedAmount), strategyId);
    };

    const clearElection = () => props.onCellClear(strategyId);

    /**
     * Sets the value of the text field and enables save button
     */
    const onChange = (
      field: ControllerRenderProps<
        IElectStage,
        `elections.${number}.electedAmount`
      >
    ) => {
      return (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setIsSBSElectionSaveEnabled(true));
        //Validate if user removes the amount by pressing "backspace" key,
        //instead of showing a 0 will cancel the elections
        if (event.target.value.length === 0) {
          cancelElection(field);
        } else {
          const finalVal = convertElectionInputToNumber(event.target.value);
          field.onChange(some(finalVal));
        }
      };
    };

    /**
     * Sets the value of the text field and saves value to store
     */
    const onBlur = (
      field: ControllerRenderProps<
        IElectStage,
        `elections.${number}.electedAmount`
      >
    ) => {
      return (event: React.FocusEvent<HTMLInputElement>) => {
        //Check if the value of the input is a number,
        //otherwise we won't trigger changes in the input
        const electedAmount = parseInt(event.target.value);
        if (!isNaN(electedAmount)) {
          const finalVal = convertElectionInputToRoundedNumber(
            event.target.value
          );
          field.onChange(some(finalVal));
          handleUpdateGridWithNewElection(some(finalVal));
        }
      };
    };

    const cancelElection = (
      field: ControllerRenderProps<
        IElectStage,
        `elections.${number}.electedAmount`
      >
    ) => {
      field.onChange(nothing);
      clearElection();
      dispatch(setIsSBSElectionSaveEnabled(true));
    };

    const GetFormRowIdxByStrategyId = (strategyId: number) => {
      const { fields } = useFieldArray({
        control: props.control,
        name: "elections",
      });

      const idx = fields.findIndex((el) => el.strategyId === strategyId);
      return idx;
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      props.onKeyDown(e, rowIndex);
    };

    return (
      <div className={styles.controller} ref={ref}>
        <Controller
          control={props.control}
          name={`elections.${GetFormRowIdxByStrategyId(
            strategyId
          )}.electedAmount`}
          render={({ field, fieldState }) => {
            // if the individual input field has an error, use that
            //  otherwise, check if there is a total elect card error and
            //  display that if so
            const errorMsg: string | undefined =
              fieldState.error?.message ??
              (props.error && props.error.isTotalError
                ? props.error.errorMessage
                : undefined);

            return (
              <TextFieldWithClear
                inputProps={{ className: styles.textField }}
                value={isSomething(field.value) ? field.value.value : undefined}
                onChange={onChange(field)}
                handleClear={() => cancelElection(field)}
                errorMsg={errorMsg}
                showErrorAsTooltip={true}
                customOnBlur={onBlur(field)}
                onKeyDown={onKeyDown}
                inputComponent={NumericFormatInput}
                startAdornment={
                  <InputAdornment position="start">{"$"}</InputAdornment>
                }
                fullWidth={false}
                hasValue={isSomething(field.value)}
                autoFocus={autoFocus}
              />
            );
          }}
          rules={{
            validate: (data: Optional<number>) => {
              const electionAmount = zeroIfNothing(data);
              return validateElectionForStrategy(
                electionAmount,
                strategyMin,
                strategyMax,
                canRequestAdditional
              );
            },
          }}
        />
        <Typography className={styles.percent}>
          {formatPercentToOneDecimal(optElectionPct)}
        </Typography>
      </div>
    );
  }
);

ElectCardUserInputEditableCell.displayName = "ElectCardUserInputEditableCell";

export const ElectCardUserInputCell = React.forwardRef(
  (
    props: IElectCardUserInputCellRendererParams,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const isTotalRow = props.node.rowPinned;

    const optElectionPct: number = props.node.data.optionalElectionPercentage;

    if (isTotalRow) {
      return (
        <ElectionTotalRow
          value={props.value}
          financing={props.financing}
          optElectionPct={optElectionPct}
        />
      );
    }

    return <ElectCardUserInputEditableCell {...props} ref={ref} />;
  }
);

ElectCardUserInputCell.displayName = "ElectCardUserInputCell";

export default ElectCardUserInputCell;
