import { ColDef } from "ag-grid-community";
import {
  DataLoadStatus,
  dateAndTimeFormatterWithEmpty,
  ElectorViewLabels,
  formatDateAndTimeMMMDDYYYY,
  GridHeaderWithFilterAndSort,
  IElectionRound,
  IElectionsForElectionRound,
  IElectionsForElectionRoundRow,
  ISortOptions,
  isSomething,
  LinkCellRenderer,
  nothing,
  Optional,
  reqElectionsForElectionRound,
  some,
  SortDirection,
  updateElectorsGridPage,
  updateElectorsGridSearchTerm,
  useDownloadElectionAgreement,
  useFetchDatasetIfIdDefined,
} from "common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { RelativePath } from "../../../constants/Paths";
import { AdminUIStore } from "../../../redux/store";
import { searchElections } from "../../../utils/electorsGridUtils";
import CurrentStageChip from "../ElectionAdminPage/CurrentStageChip";

enum ElectorViewGridColumn {
  mdmInvestmentVehicleId = "mdmInvestmentVehicleId",
  name = "name",
  currentStage = "currentStage",
  submissionDateTime = "submissionDateTime",
  reopenedDate = "reopenedDate",
}

const DEFAULT_SORTING = {
  sortBy: ElectorViewGridColumn.name,
  orderBy: SortDirection.DESC,
};

export const useElectorViewGrid = () => {
  const dispatch = useDispatch();
  const { electionRoundId } = useParams();
  const { columnDefs, currentSort } =
    useActiveElectionGridDefinition(electionRoundId);
  const { currentPageItems, totalRows, pageSize, page, onSearch } =
    useActiveElectionRows(currentSort);

  const { electionsForElectionRoundLoadStatus, currentElectionRoundId } =
    useSelector((state: AdminUIStore) => state.elections);

  // This effect is to check when the page is loaded if the current data is for the same electionRoundId that the ElectionRoundId in the query param
  useEffect(() => {
    if (electionsForElectionRoundLoadStatus !== DataLoadStatus.SUCCESSFUL) {
      return;
    }

    if (
      electionRoundId &&
      isSomething(currentElectionRoundId) &&
      currentElectionRoundId.value !== electionRoundId
    ) {
      dispatch(reqElectionsForElectionRound({ electionRoundId }));
    }
  }, [
    electionRoundId,
    electionsForElectionRoundLoadStatus,
    dispatch,
    currentElectionRoundId,
  ]);

  const electionRound: Optional<IElectionRound> = electionRoundId
    ? some({ electionRoundId })
    : nothing;

  useFetchDatasetIfIdDefined(
    reqElectionsForElectionRound,
    electionRound,
    electionsForElectionRoundLoadStatus
  );

  return {
    electionRoundId,
    columnDefs,
    currentPageItems,
    totalRows,
    pageSize,
    page,
    onSearch,
  };
};

export const useActiveElectionGridDefinition = (
  electionRoundId: string | undefined
) => {
  const [currentSort, setCurrentSort] = useState<
    Optional<ISortOptions<ElectorViewGridColumn>>
  >(some(DEFAULT_SORTING));

  const handleGetLink = useCallback(
    (data: IElectionsForElectionRoundRow) => {
      if (electionRoundId && data.mdmInvestmentVehicleId) {
        const path = RelativePath.ELECTION.replace(
          ":electionRoundId",
          electionRoundId
        ).replace(
          ":mdmInvestmentVehicleId",
          data.mdmInvestmentVehicleId.toString()
        );

        return path;
      }

      return undefined;
    },
    [electionRoundId]
  );

  const downloadElectionAgreement = useDownloadElectionAgreement();

  const downloadPDF = useCallback(
    async (data: IElectionsForElectionRoundRow) => {
      if (electionRoundId && data.investmentVehicleId) {
        await downloadElectionAgreement(
          electionRoundId,
          data.investmentVehicleId,
          false
        );
      }
    },
    [electionRoundId, downloadElectionAgreement]
  );

  const handleSort = (
    newSortOptions: Optional<ISortOptions<ElectorViewGridColumn>>
  ) => {
    setCurrentSort(newSortOptions);
  };

  const columnDefs: ColDef<IElectionsForElectionRoundRow>[] = useMemo(
    () => [
      {
        field: "mdmInvestmentVehicleId",
        headerName: ElectorViewLabels.INVESTMENT_VEHICLE_ID,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon: false,
          fieldToSortBy: ElectorViewGridColumn.mdmInvestmentVehicleId,
          handleSort: handleSort,
          currentSortOptions: currentSort,
        },
      },
      {
        field: "name",
        headerName: ElectorViewLabels.INVESTMENT_VEHICLE_NAME,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon: false,
          fieldToSortBy: ElectorViewGridColumn.name,
          handleSort: handleSort,
          currentSortOptions: currentSort,
        },
        cellRenderer: (params: {
          value: string;
          data: IElectionsForElectionRoundRow;
        }) => (
          <LinkCellRenderer
            value={params.value}
            data={params.data}
            getLink={handleGetLink}
            target="_blank"
          />
        ),
      },
      {
        field: "currentStage",
        headerName: ElectorViewLabels.CURRENT_STEP,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon: false,
          fieldToSortBy: ElectorViewGridColumn.currentStage,
          handleSort: handleSort,
          currentSortOptions: currentSort,
        },
        cellRenderer: (params: {
          value: number;
          data: IElectionsForElectionRoundRow;
        }) => <CurrentStageChip activeStageId={params.value} />,
      },
      {
        field: "submissionDateTime",
        headerName: ElectorViewLabels.SUBMITTED_DATE,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon: false,
          fieldToSortBy: ElectorViewGridColumn.submissionDateTime,
          handleSort: handleSort,
          currentSortOptions: currentSort,
        },
        cellRenderer: (params: {
          value: string;
          data: IElectionsForElectionRoundRow;
        }) => {
          if (!params.data || !params.data.submissionDateTime) return;

          const valueFormatted = formatDateAndTimeMMMDDYYYY(
            params.data.submissionDateTime
          );

          return !!params.data.submissionDateTime ? (
            <LinkCellRenderer
              value={valueFormatted}
              data={params.data}
              onClick={downloadPDF}
            />
          ) : (
            valueFormatted
          );
        },
      },
      {
        field: "reopenedDate",
        headerName: ElectorViewLabels.REOPENED_DATE,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon: false,
          fieldToSortBy: ElectorViewGridColumn.reopenedDate,
          handleSort: handleSort,
          currentSortOptions: currentSort,
        },
        valueFormatter: dateAndTimeFormatterWithEmpty,
      },
    ],
    [currentSort, handleGetLink, downloadPDF]
  );

  return { columnDefs, currentSort };
};

export const useActiveElectionRows = (
  currentSort: Optional<ISortOptions<ElectorViewGridColumn>>
) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<{
    items: IElectionsForElectionRoundRow[];
    totalRows: number;
  }>({ items: [], totalRows: 0 });

  const { electionsForElectionRound, electorsGridOptions } = useSelector(
    (state: AdminUIStore) => state.elections
  );

  const { page, pageSize, searchTerm } = electorsGridOptions;

  const getLatestDate = (dates: Date[]): Date | undefined => {
    if (dates.length === 0) {
      return undefined;
    }

    return dates
      .map((date) => new Date(date))
      .reduce((latestDate, currentDate) => {
        return latestDate > currentDate ? latestDate : currentDate;
      });
  };

  const sortByName = useCallback(
    (
      election1: IElectionsForElectionRoundRow,
      election2: IElectionsForElectionRoundRow,
      sortDirection?: SortDirection
    ) =>
      !sortDirection || sortDirection === SortDirection.DESC
        ? election1.name.localeCompare(election2.name)
        : election2.name.localeCompare(election1.name),
    []
  );

  const sortByNumber = useCallback(
    (field1: number, field2: number, sortDirection: SortDirection): number =>
      sortDirection === SortDirection.ASC ? field1 - field2 : field2 - field1,
    []
  );

  const sortByOptionalDate = useCallback(
    (
      field1: Date | undefined,
      field2: Date | undefined,
      sortDirection: SortDirection
    ): number => {
      if (sortDirection === SortDirection.ASC) {
        if (!field1) {
          return -1;
        }
        if (!field2) {
          return 1;
        }

        return field1 > field2 ? 1 : -1;
      }
      if (!field1) {
        return 1;
      }
      if (!field2) {
        return -1;
      }

      return field2 > field1 ? 1 : -1;
    },
    []
  );

  const sortElectionsForElectionRound = useCallback(
    (
      election1: IElectionsForElectionRoundRow,
      election2: IElectionsForElectionRoundRow,
      currentSort: Optional<ISortOptions<ElectorViewGridColumn>>
    ) => {
      if (!isSomething(currentSort)) {
        return sortByName(election1, election2);
      }

      switch (currentSort.value.sortBy) {
        case ElectorViewGridColumn.name:
          return sortByName(election1, election2, currentSort.value.orderBy);
        case ElectorViewGridColumn.mdmInvestmentVehicleId:
          return election1.mdmInvestmentVehicleId !==
            election2.mdmInvestmentVehicleId
            ? sortByNumber(
                election1.mdmInvestmentVehicleId,
                election2.mdmInvestmentVehicleId,
                currentSort.value.orderBy
              )
            : sortByName(election1, election2);
        case ElectorViewGridColumn.currentStage:
          return election1.currentStage !== election2.currentStage
            ? sortByNumber(
                election1.currentStage,
                election2.currentStage,
                currentSort.value.orderBy
              )
            : sortByName(election1, election2);
        case ElectorViewGridColumn.reopenedDate:
          return election1.reopenedDate !== election2.reopenedDate
            ? sortByOptionalDate(
                election1.reopenedDate,
                election2.reopenedDate,
                currentSort.value.orderBy
              )
            : sortByName(election1, election2);
        case ElectorViewGridColumn.submissionDateTime:
          return election1.submissionDateTime !== election2.submissionDateTime
            ? sortByOptionalDate(
                election1.submissionDateTime,
                election2.submissionDateTime,
                currentSort.value.orderBy
              )
            : sortByName(election1, election2);
        default:
          throw "Order not implemented";
      }
    },
    [sortByOptionalDate, sortByName, sortByNumber]
  );

  const onSearch = useCallback(
    (term: Optional<string>) => {
      dispatch(updateElectorsGridSearchTerm(term));
      // send user back to page 1 if they search
      dispatch(updateElectorsGridPage(1));
    },
    [dispatch]
  );

  // This effect set the current Page on initialLoad
  useEffect(() => {
    const mappedElections = electionsForElectionRound.map(
      (mdmIV: IElectionsForElectionRound): IElectionsForElectionRoundRow => ({
        investmentVehicleId: mdmIV.investmentVehicle.investmentVehicleId,
        mdmInvestmentVehicleId: mdmIV.mdmInvestmentVehicleId,
        name: mdmIV.investmentVehicle.name,
        currentStage: mdmIV.currentStage,
        submissionDateTime: getLatestDate(mdmIV.submissionDateTimes),
        reopenedDate: getLatestDate(mdmIV.reopenedDates),
      })
    );

    const { exactMatches, beginsWithMatches, containingMatches } =
      searchElections(mappedElections, searchTerm);

    const sortArray = (array: IElectionsForElectionRoundRow[]) =>
      array.sort((election1, election2) =>
        sortElectionsForElectionRound(election1, election2, currentSort)
      );

    const pageItems = [
      ...sortArray(exactMatches),
      ...sortArray(beginsWithMatches),
      ...sortArray(containingMatches),
    ].slice((page - 1) * pageSize, page * pageSize);
    return setData({
      items: pageItems,
      totalRows: electionsForElectionRound.length,
    });
  }, [
    searchTerm,
    currentSort,
    electionsForElectionRound,
    page,
    pageSize,
    sortElectionsForElectionRound,
  ]);

  return {
    currentPageItems: data.items,
    totalRows: data.totalRows,
    pageSize,
    page,
    onSearch,
  };
};
