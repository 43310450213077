import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { a11yProps } from "common";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { HasEntitlementContainer } from "../../../common/components/HasEntitlementContainer/HasEntitlementContainer";
import {
  ClientLevelPageName,
  ClientLevelPages,
  ClientTabDisplayOrder,
} from "../../../constants/Pages/Pages";
import { AdminUIStore } from "../../../redux/store";
import { findInternalInvestmentClientForClientId } from "../../../utils/adminClientUtils";
import { isPageVisible } from "../../../utils/routingUtils";
import styles from "./NavigationTabs.module.scss";

export const NavigationTabs = () => {
  const { internalInvestmentClients } = useSelector(
    (store: AdminUIStore) => store.clients
  );

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  // Extract the clientId from the pathname
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId;
  const clientFromPath = findInternalInvestmentClientForClientId(
    clientId,
    internalInvestmentClients
  );

  /*
    helper function which uses current url path and tab display order 
    to determine tab index number. needed for showing correct tab as selected
  */
  const determineTabIndex = useCallback(
    (path: string): number => {
      let curIndex = 0;
      ClientTabDisplayOrder.forEach(
        (tabName: ClientLevelPageName, index: number) => {
          if (
            path.includes(ClientLevelPages[tabName]?.getPath(clientId) ?? "")
          ) {
            curIndex = index;
          }
        }
      );
      return curIndex;
    },
    [clientId]
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(
      `${ClientLevelPages[ClientTabDisplayOrder[newValue]]?.getPath(clientId)}`
    );
  };

  const [currentTabIndex, setCurrentTabIndex] = useState(
    determineTabIndex(pathname)
  );

  useEffect(() => {
    setCurrentTabIndex(determineTabIndex(pathname));
  }, [determineTabIndex, pathname]);

  return clientFromPath !== undefined ? (
    <HasEntitlementContainer
      entitlementLoadStatusName="internalInvestmentAdminEntitlementLoadStatus"
      entitlementName="hasInternalInvestmentAdminEntitlement"
      noDataChildren={<></>}
    >
      <Box className={styles.navTabBar}>
        <Tabs
          value={currentTabIndex}
          onChange={handleTabChange}
          className={styles.navTabs}
          TabIndicatorProps={{
            className: styles.indicator,
          }}
        >
          {ClientTabDisplayOrder.filter((tab: ClientLevelPageName) => {
            return isPageVisible(tab, false);
          }).map((tab, index) => (
            <Tab
              label={ClientLevelPages[tab]?.name}
              key={ClientLevelPages[tab]?.name}
              value={index}
              className={styles.navTab}
              {...a11yProps(index)}
            ></Tab>
          ))}
        </Tabs>
      </Box>
    </HasEntitlementContainer>
  ) : (
    <></>
  );
};
