import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, Label, XAxis, YAxis } from "recharts";

import { StockSymbol } from "../../constants/enums";
import {
  DashboardLabel,
  EquityLabel,
  UpcomingVestingEventsLabel,
} from "../../constants/LabelAndTooltipConstants";
import { MUIConstants } from "../../constants/MUIConstants";
import {
  chartMarginsWithAxisLabel,
  xAxisLabelDy,
  xAxisTick,
  yAxisLabelDx,
  yAxisTick,
} from "../../constants/RechartsSVGStyles";
import colors from "../../styles/_colors.scss";
import { IBXEquityCardData } from "../../types/dataTypes";
import { ChartTickInformation, getTicksAndLabels } from "../../utils/charting";
import { getTotalsRowNamesAndSubTotals } from "../../utils/EquityRowUtils";
import {
  formatDateMMMDDYYYYOrNull,
  getCurrencyFormattedTwoDPValueWithZeroDefault,
  getCurrencyFormattedValue,
} from "../../utils/formatters";
import { DashboardCardTable } from "../DashboardCardTable/DashboardCardTable";
import { ResponsiveRechartsWrapper } from "../ResponsiveRechartsWrapper/ResponsiveRechartsWrapper";
import styles from "./EquityRow.module.scss";

interface IEquityRowProps {
  equityCardData: IBXEquityCardData;
  selectedStockSymbol: StockSymbol;
  headerCols: string[];
  hasViewRestrictedEquity: boolean;
}

export const EquityRow = (props: IEquityRowProps) => {
  const {
    equityCardData,
    headerCols,
    selectedStockSymbol,
    hasViewRestrictedEquity,
  } = props;

  const [tickInfo, setTickInfo] = useState<ChartTickInformation>();
  const [yDomainMax, setYDomainMax] = useState<string>("dataMax");

  useEffect(() => {
    const values = equityCardData.chartData.map((item) => item.value);
    const ticks: ChartTickInformation = getTicksAndLabels(values);
    setTickInfo(ticks);
    // determine if need to extend y-axis so that label above bar shows in full
    const maxValue = Math.max(...values, 0);
    // compute difference between each tick value and also 1/5 of it
    //    there should be a minimum of 7 values, so next line should not fail
    const tickDifference = ticks.tickValues[1] - ticks.tickValues[0];
    const oneFifthTick = tickDifference / 5;
    if (
      ticks.tickValues[ticks.tickValues.length - 1] - maxValue <
      oneFifthTick
    ) {
      // if the maximum data value is within 1/5 of the difference between each tick
      //    value from the max tick value, then we should add one more tick value to
      //    the chart so the label doesn't get cut off
      setYDomainMax(`dataMax + ${tickDifference}`);
    } else {
      setYDomainMax(`dataMax`);
    }
  }, [equityCardData, setTickInfo]);

  const formatYAxisTicks = (value: number, index: number): string => {
    return tickInfo?.tickLabels[index] ?? "";
  };

  // States to controll the Row Expand/Collapse of the DashboardCardTable
  //Set expand/collapse for total unvested
  const [isTotalRowOpen, setTotalRowOpen] = useState(false);
  //Set expand/collapse for number of unvested
  const [isTotalUnitsRowOpen, setTotalUnitsRowOpen] = useState(false);
  //Set expand/collapse for bx nyse
  const [isBXNYSERowOpen, setBXNYSERowOpen] = useState(false);
  //Set expand/collapse for next vesting
  const [isNextVestingRowOpen, setNextVestingRowOpen] = useState(false);

  const { totalValue, totalUnits } = getTotalsRowNamesAndSubTotals(
    equityCardData,
    selectedStockSymbol,
    hasViewRestrictedEquity
  );

  const rows = [
    {
      name: totalValue.name,
      value: totalValue.value,
      isOpen: isTotalRowOpen,
      setIsOpen: setTotalRowOpen,
      disableCollapse: true,
      children:
        totalValue.subItems.length !== 0 ? totalValue.subItems : undefined,
    },
    {
      name: totalUnits.name,
      value: totalUnits.value,
      isOpen: isTotalUnitsRowOpen,
      setIsOpen: setTotalUnitsRowOpen,
      disableCollapse: true,
      children:
        totalUnits.subItems.length !== 0 ? totalUnits.subItems : undefined,
    },
    {
      name: EquityLabel.STOCK_NYSE(selectedStockSymbol),
      value: getCurrencyFormattedTwoDPValueWithZeroDefault(
        equityCardData.latestBXStockValue.value
      ),
      tooltipParagraphs: [
        DashboardLabel.Equity.STOCK_PRICE_TOOLTIP(
          equityCardData.latestBXStockValue.asOfDate
        ),
      ],
      isOpen: isBXNYSERowOpen,
      setIsOpen: setBXNYSERowOpen,
    },
    {
      name: EquityLabel.NEXT_VESTING_DATE,
      value: formatDateMMMDDYYYYOrNull(equityCardData.nextVestingDate),
      isOpen: isNextVestingRowOpen,
      setIsOpen: setNextVestingRowOpen,
    },
  ];

  return (
    <Grid
      container
      columnSpacing={MUIConstants.DASHBOARD_WIDGET_COLUMN_SPACING}
    >
      <Grid
        item
        xs={12}
        md={MUIConstants.DASHBOARD_WIDGET_LEFT_ITEM_SIZE}
        className={styles.bar}
      >
        <ResponsiveRechartsWrapper>
          <BarChart
            data={equityCardData.chartData}
            barGap={30}
            maxBarSize={50}
            margin={chartMarginsWithAxisLabel}
            stackOffset={"sign"}
          >
            <CartesianGrid
              vertical={false}
              strokeDasharray="2 2"
              stroke={colors.slate}
            />
            <XAxis
              axisLine={true}
              dataKey="year"
              tick={xAxisTick}
              tickLine={false}
              height={40}
            >
              <Label
                value={UpcomingVestingEventsLabel.FUTURE_VESTING_DATES}
                position={"insideBottom"}
                dy={xAxisLabelDy}
              ></Label>
            </XAxis>
            <YAxis
              axisLine={true}
              domain={["dataMin", yDomainMax]}
              tickLine={false}
              tickFormatter={formatYAxisTicks}
              tick={yAxisTick}
              ticks={
                /* When no data is selected, it will render $0 in the middle of the chart by default, 
                this will force the chart to render $0 at the bottom */
                tickInfo?.tickValues.length === 1 &&
                tickInfo?.tickValues[0] === 0
                  ? [0, 1]
                  : tickInfo?.tickValues
              }
              type="number"
            >
              <Label value={"Value ($)"} angle={270} dx={yAxisLabelDx}></Label>
            </YAxis>
            <Bar
              dataKey="value"
              fill={colors.avocado}
              label={{
                position: "top",
                formatter: getCurrencyFormattedValue,
                offset: 10,
              }}
              radius={[2, 2, 0, 0]}
              maxBarSize={30}
            ></Bar>
          </BarChart>
        </ResponsiveRechartsWrapper>
      </Grid>
      <Grid item xs={12} md={MUIConstants.DASHBOARD_WIDGET_RIGHT_ITEM_SIZE}>
        <DashboardCardTable
          header={EquityLabel.UNVESTED_EQUITY_OVERVIEW}
          headerCols={headerCols}
          rows={rows}
        />
      </Grid>
    </Grid>
  );
};
