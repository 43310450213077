import { Grid } from "@mui/material";
import {
  ElectionsLabels,
  IOverviewStage,
  openAlert,
  resetStageValidation,
} from "common";
import React, { useCallback, useEffect, useRef } from "react";
import { useForm, useFormState } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { useMoveStages } from "../../../../hooks/electionHooks";
import { selectOverviewStage } from "../../../../redux/selectors";
import { IBaseStore } from "../../../../redux/store";
import { OfferAmountCard } from "../Shared/OfferAmountCard/OfferAmountCard";
import { ElectionDocumentsCard } from "./ElectionDocuments/ElectionDocumentsCard";
import { ElectionPeriodAndDeadlinesCard } from "./ElectionPeriodAndDeadlinesCard/ElectionPeriodAndDeadlinesCard";
import { ElectionProcessCard } from "./ElectionProcessCard/ElectionProcessCard";
import { ElectionContactTeamCard } from "./ElectionsContactTeamCard/ElectionsContactTeamCard";
import { LegalAttestationCard } from "./LegalAttestationCard/LegalAttestationCard";
import { LegalAttestationForm } from "./LegalAttestationCard/LegalAttestationForm/LegalAttestationForm";
import { RemainingExpectedCommitmentsCard } from "./RemainingExpectedCommitmentsCard/RemainingExpectedCommitmentsCard";

interface IOverviewStageProps {
  canReadBankAccounts: boolean;
}

export const OverviewStage = (props: IOverviewStageProps) => {
  const { canReadBankAccounts } = props;

  // gets overview stage from election workflow state
  const overviewStage = useSelector((state: IBaseStore) =>
    selectOverviewStage(state)
  );

  const { stageValidationRequested } = useSelector(
    (state: IBaseStore) => state.viewData
  );

  // sets the the legal attestation react hook form
  // not setting a form mode since we wanna trigger the validation manually
  const legalAttestationFormMethods = useForm<IOverviewStage>({
    defaultValues: overviewStage,
    shouldFocusError: true,
  });

  // access to form errors
  const { errors } = useFormState({
    control: legalAttestationFormMethods.control,
  });

  const legalAttestationFormRef = useRef<HTMLFormElement>(null);

  const dispatch = useDispatch();

  const setErrorToast = useCallback(
    (message: string) =>
      dispatch(
        openAlert({
          severity: "error",
          message,
          hideDuration: 5000,
        })
      ),
    [dispatch]
  );

  const moveStages = useMoveStages();

  useEffect(() => {
    // condition to make useEffect hook not run on initial render
    // we must run the validation only when next button/tab was clicked
    // so, stageValidationRequested must be set to 0 after switching to next stage again
    if (stageValidationRequested) {
      // declaring the fn inside the hook since we would'nt have it as dependency
      const triggerFormValidations = async () => {
        // triggers the form validation
        const result = await legalAttestationFormMethods.trigger();
        // checks if the form has a valid state so that data can be saved, then allowed to go to next stage
        // there could be more than one form to be validated in other stages
        if (result) {
          //this will be done after saving to the api successfully later
          moveStages();
        } else {
          setErrorToast(ElectionsLabels.PLEASE_COMPLETE_LEGAL_ATTESTATION);
          //scroll to the bottom where the error was thrown
          legalAttestationFormRef.current?.scrollIntoView({
            behavior: "smooth",
          });
        }
      };
      dispatch(resetStageValidation());
      triggerFormValidations();
    }
  }, [
    dispatch,
    legalAttestationFormMethods,
    setErrorToast,
    stageValidationRequested,
    moveStages,
  ]);

  return (
    <Grid container spacing={4}>
      <Grid item md={7} flexDirection="column">
        <ElectionProcessCard canReadBankAccounts={canReadBankAccounts} />
        <LegalAttestationCard
          form={
            <LegalAttestationForm
              control={legalAttestationFormMethods.control}
              ref={legalAttestationFormRef}
            />
          }
          hasErrors={errors.hasAcceptedLegalAttestation?.message !== undefined}
        />
      </Grid>
      <Grid item md={5} flexDirection="column">
        <OfferAmountCard />
        <ElectionPeriodAndDeadlinesCard />
        <RemainingExpectedCommitmentsCard />
        <ElectionDocumentsCard />
        <ElectionContactTeamCard />
      </Grid>
    </Grid>
  );
};
