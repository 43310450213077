import FlashOnOutlinedIcon from "@mui/icons-material/FlashOnOutlined";
import { Button } from "@mui/material";
import {
  ElectionsLabels,
  FormCardHeader,
  IElectionDecision,
  isSomething,
  validateFullElectionGrid,
} from "common";
import colors from "common/src/styles/_colors.scss";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";

import {
  selectCanRequestAdditional,
  selectElectCardGridTooltips,
  selectElectCardTooltip,
  selectElectionMinimum,
  selectElectionsLocalState,
  selectHasIVMoreThanOneStrategy,
  selectOfferAmount,
} from "../../../../../redux/selectors";
import { IBaseStore } from "../../../../../redux/store";
import { ElectionCard } from "../../Shared/ElectionCard";
import { IElectStageFormProps } from "../ElectStage";
import { AutoAllocateDialog } from "./AutoAllocateDialog/AutoAllocateDialog";
import { ElectCardGrid } from "./ElectCardGrid/ElectCardGrid";

export const ElectCard = (props: IElectStageFormProps) => {
  const { control, trigger, error } = props;

  const { electionIVConfigurationLoadStatus } = useSelector(
    (state: IBaseStore) => state.selectedElection
  );

  const hasIVMoreThanOneStrategy = useSelector((state: IBaseStore) =>
    selectHasIVMoreThanOneStrategy(state)
  );

  const tooltip = useSelector(selectElectCardTooltip);

  const canUseAdditional = useSelector(selectCanRequestAdditional);

  const electionMinimum = useSelector(selectElectionMinimum);

  const hasElectedAmount: boolean = useSelector(selectElectionsLocalState).some(
    (e) => isSomething(e.electedAmount)
  );

  const offerAmount = useSelector(selectOfferAmount);

  const gridTooltips = useSelector((state: IBaseStore) =>
    selectElectCardGridTooltips(state)
  );

  const [isAutoAllocateOpen, setIsAutoAllocateOpen] = useState<boolean>(
    !hasElectedAmount
  );

  const getAllocationToolBtn = (): JSX.Element => {
    if (hasIVMoreThanOneStrategy) {
      return (
        <Button
          onClick={() => setIsAutoAllocateOpen(true)}
          endIcon={
            <FlashOnOutlinedIcon
              style={{ fill: colors.black, stroke: colors.white }}
            />
          }
        >
          {ElectionsLabels.AUTO_ALLOCATION_TOOL}
        </Button>
      );
    }
    return <></>;
  };

  return (
    <Controller
      control={control}
      name={`elections`}
      render={() => {
        return (
          <ElectionCard
            error={error !== undefined}
            additionalDataLoadStatuses={[electionIVConfigurationLoadStatus]}
          >
            <FormCardHeader
              title={ElectionsLabels.ELECTIONS}
              tooltip={tooltip}
              hasError={error !== undefined}
              actionElement={getAllocationToolBtn()}
            />
            <ElectCardGrid
              control={control}
              trigger={trigger}
              error={error}
              tooltips={gridTooltips}
            />
            <AutoAllocateDialog
              open={isAutoAllocateOpen}
              setOpen={setIsAutoAllocateOpen}
              control={control}
            />
          </ElectionCard>
        );
      }}
      rules={{
        validate: (electionDecisions: IElectionDecision[]) => {
          return validateFullElectionGrid(
            electionDecisions,
            electionMinimum,
            offerAmount,
            canUseAdditional
          );
        },
      }}
    />
  );
};
