import {
  AgreementPdfRequest,
  BankAccountsResponse,
  ICountry,
  ICurrency,
  InvestmentVehicleBankAccount,
  SelectableBankAccount,
} from "../types/bankAccountDataTypes";
import { IESignature } from "../types/dataTypes";
import { isSomething } from "../types/typeGuards";
import { Json, Maybe } from "../types/typeUtils";
import { getUser } from "./auth";
import { EnvironmentResolver } from "./environmentResolver";
import { handleBlobResponse, handleResponse } from "./utils";

export type BankAccountsResponseDto = Json<BankAccountsResponse>;
export type ICountryResponseDto = Json<ICountry>;
export type ICurrencyResponseDto = Json<ICurrency>;

export const getBankAccountsForClient = async (
  clientId: number
): Promise<Maybe<BankAccountsResponseDto>> => {
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_BANK_ACCOUNTS_URL_BASE +
    `/gp-bank-accounts?clientId=${clientId}`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return await handleResponse(response);
};

export const getBankAccountsForIV = async (
  investmentVehicleAxiomId: number,
  isAdmin: boolean
): Promise<Maybe<BankAccountsResponseDto>> => {
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_BANK_ACCOUNTS_URL_BASE +
    `/gp-bank-accounts/investment-vehicles/${investmentVehicleAxiomId}?isAdminMode=${isAdmin}`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return await handleResponse(response);
};

export const getAgreementDocument = async (
  request: AgreementPdfRequest,
  isAdmin: boolean
): Promise<Maybe<{ fileName: string; blob: Blob }>> => {
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_BANK_ACCOUNTS_URL_BASE +
    `/gp-bank-accounts/pdf?isAdminMode=${isAdmin}`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user?.access_token}`,
    },
    body: JSON.stringify(request),
  });

  const blob = await handleBlobResponse(response);

  if (!blob) return undefined;

  const date = new Date(response.headers.get("Date") as string);
  const dateString = [
    date.getFullYear(),
    ("0" + (date.getMonth() + 1)).slice(-2),
    ("0" + date.getDate()).slice(-2),
  ].join("");

  return {
    fileName: `Bank Account Agreement_${dateString}.pdf`,
    blob: blob,
  };
};

export const getBankAccountCountries = async (): Promise<
  Maybe<ICountryResponseDto[]>
> => {
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_BANK_ACCOUNTS_URL_BASE + `/country`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return await handleResponse(response);
};

export const getBankAccountCurrencies = async (): Promise<
  Maybe<ICurrencyResponseDto[]>
> => {
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_BANK_ACCOUNTS_URL_BASE + `/currency`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return await handleResponse(response);
};

export const postSubmitBankAccounts = async (
  investmentVehicleMdmId: number,
  bankAccounts: SelectableBankAccount[],
  eSignature: IESignature,
  isAdmin: boolean
) => {
  const investmentVehicleBankAccounts: InvestmentVehicleBankAccount[] = [];
  bankAccounts.forEach((bankAccount) => {
    investmentVehicleBankAccounts.push({
      investmentVehicleMdmId,
      bankAccountAssignment: bankAccount.assignments,
      main: bankAccount.main,
      intermediary: isSomething(bankAccount.intermediary)
        ? {
            ...bankAccount.intermediary.value,
            country: bankAccount.main.country,
            currency: bankAccount.main.currency,
          }
        : undefined,
    });
  });
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_BANK_ACCOUNTS_URL_BASE +
    `/gp-bank-accounts?isAdminMode=${isAdmin}`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json-patch+json",
    },
    method: "POST",
    body: JSON.stringify({
      investmentVehicleBankAccounts,
      documents: [],
      eSignature,
      effectiveDate: new Date().toISOString(),
    }),
  });

  return await handleResponse(response);
};
