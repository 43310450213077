import {
  DataCard,
  DataCardProps,
  DataLoadStatus,
  isEmptyResponse,
  isInProgress,
  isUnsuccessful,
  reqElectionIVConfiguration,
  reqElectionRoundConfigurationData,
  reqElectionWorkflowState,
  useFetchDatasetIfIdDefined,
} from "common";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { IBaseStore } from "../../../../redux/store";
import styles from "./ElectionCard.module.scss";

export interface CardProperties extends DataCardProps {
  additionalDataLoadStatuses?: DataLoadStatus[];
  additionalRetryFunctions?: (() => void)[];
  error?: boolean;
  highlight?: boolean;
}

export const ElectionCard = (props: CardProperties) => {
  const {
    children,
    additionalDataLoadStatuses,
    additionalRetryFunctions,
    className,
    error,
    highlight,
    hide,
    hideIfNoData,
    failed,
    loading,
    noData,
    onReload,
  } = props;

  const {
    electionRoundConfigurationLoadStatus,
    electionWorkflowStateLoadStatus,
    electionIVConfigurationLoadStatus,
    activeElection,
  } = useSelector((state: IBaseStore) => state.selectedElection);

  const loadStatuses = [
    electionRoundConfigurationLoadStatus,
    electionWorkflowStateLoadStatus,
    electionIVConfigurationLoadStatus,
    ...(additionalDataLoadStatuses || []),
  ];

  const ivConfigRetry = useFetchDatasetIfIdDefined(
    reqElectionIVConfiguration,
    activeElection,
    electionIVConfigurationLoadStatus
  );
  const roundConfigRetry = useFetchDatasetIfIdDefined(
    reqElectionRoundConfigurationData,
    activeElection,
    electionRoundConfigurationLoadStatus
  );
  const workflowStateRetry = useFetchDatasetIfIdDefined(
    reqElectionWorkflowState,
    activeElection,
    electionWorkflowStateLoadStatus
  );

  const defaultRetryFunctions = useMemo(() => {
    const retryFuncs = [ivConfigRetry, roundConfigRetry, workflowStateRetry];
    if (additionalRetryFunctions) {
      retryFuncs.concat(additionalRetryFunctions);
    }
    return retryFuncs;
  }, [
    additionalRetryFunctions,
    ivConfigRetry,
    roundConfigRetry,
    workflowStateRetry,
  ]);

  return (
    <DataCard
      failed={failed ?? isUnsuccessful(...loadStatuses)}
      loading={loading ?? isInProgress(...loadStatuses)}
      noData={noData ?? isEmptyResponse(...loadStatuses)}
      onReload={onReload ?? defaultRetryFunctions}
      className={`${className} ${
        error
          ? styles.cardError
          : highlight
          ? styles.cardHighlight
          : styles.card
      }`}
      hide={hide}
      hideIfNoData={hideIfNoData}
    >
      {children}
    </DataCard>
  );
};
