import { BankAccountConstants } from "../constants/BankAccountConstants";
import {
  AccountType,
  BankAccountAssignment,
  BankAccountsResponse,
  BankIdType,
  GPBankAccount,
  GPPendingBankAccount,
  IBankAccountsStore,
  SelectableBankAccount,
  UserAddedBankAccount,
} from "../types/bankAccountDataTypes";
import { isSomething } from "../types/typeGuards";
import { nothing, Optional, some } from "../types/typeUtils";

export const pendingAccountToSelectableAccount = (
  pending: GPPendingBankAccount
): SelectableBankAccount => {
  return {
    main: pending.main,
    intermediary: pending.intermediary,
    investmentVehicle: pending.investmentVehicle,
    requestId: isSomething(pending.requestId) ? pending.requestId.value : 0,
    assignments: pending.assignments,
    lastUpdated: pending.submittedDate,
  };
};

export const userAddedToSelectableAccount = (
  userAdded: UserAddedBankAccount
): SelectableBankAccount => {
  return {
    main: userAdded.main,
    intermediary: userAdded.intermediary,
    requestId: 0,
    assignments: [userAdded.assignment],
    lastUpdated: nothing,
  };
};

export const wssAccountToSelectableAccount = (
  wss: GPBankAccount
): SelectableBankAccount => {
  return {
    main: wss.bankAccount.main,
    intermediary: wss.bankAccount.intermediary,
    investmentVehicle: wss.investmentVehicle,
    requestId: 0,
    assignments: wss.bankAccount.assignments,
    lastUpdated: some(wss.bankAccount.main.lastModified),
  };
};

export const isEligibleContributionAccount = (account: GPBankAccount) => {
  return (
    account.bankAccount.main.currency === "USD" &&
    account.bankAccount.main.country === "US" &&
    account.bankAccount.main.accountType === AccountType.CHECKING &&
    !isSomething(account.bankAccount.intermediary)
  );
};

export function getBankAccountOptions(
  state: IBankAccountsStore,
  assignment: BankAccountAssignment,
  includePending: boolean
): SelectableBankAccount[] {
  const options = state.serverData.bankAccounts
    .filter((acc) =>
      assignment === BankAccountAssignment.CONTRIBUTION
        ? isEligibleContributionAccount(acc)
        : true
    )
    .map(wssAccountToSelectableAccount);
  const userAdded = state.userAddedAccounts.filter(
    (account) =>
      account.assignment === assignment || account.eligibleForBothAssignments
  );

  if (includePending) {
    const pending = state.serverData.pendingBankAccounts.find((account) =>
      account.assignments.includes(assignment)
    );
    if (pending) return [pendingAccountToSelectableAccount(pending)];
  }

  if (userAdded) {
    options.push(...userAdded.map(userAddedToSelectableAccount));
  }
  return options;
}

export const customOnBankIdChange = (
  value: string,
  selectedBankIdType: BankIdType
) => {
  if (selectedBankIdType === BankIdType.ABA_ROUTING_NUMBER) {
    // should only be numeric input
    return value.replace(/\D/g, "");
  } else if (selectedBankIdType === BankIdType.SWIFT) {
    // should be alphanumeric input
    return value.replace(/\W/g, "");
  } else if (selectedBankIdType === BankIdType.SORT) {
    // should be numeric or numeric with - separating every 2 digits
    const formattedValue = value.replace(/[^0-9-]/g, "");
    return formattedValue.replace(/(?<!\d)\-(?!\d{2})/g, "");
  } else {
    return value;
  }
};

export const customBankIdValidation = (
  data: string,
  selectedBankIdType: BankIdType
) => {
  if (data.length === 0) {
    return BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD;
  }
  if (selectedBankIdType === BankIdType.ABA_ROUTING_NUMBER) {
    if (data.length !== 9) {
      return BankAccountConstants.MUST_BE_NINE_DIGITS;
    }
    return true;
  } else if (selectedBankIdType === BankIdType.SWIFT) {
    if (data.length < 8 || data.length > 11) {
      return BankAccountConstants.MUST_BE_8_11_ALPHANUMERIC;
    }
    return true;
  } else if (selectedBankIdType === BankIdType.SORT) {
    // checks for 6 digits or format XX-XX-XX
    const regex = /^(?:\d{6}|(?:\d{2}-){2}\d{2})$/;
    if (!regex.test(data)) {
      return BankAccountConstants.MUST_BE_SIX_DIGITS;
    }
    return true;
  } else if (data.length > 150) {
    return BankAccountConstants.MUST_BE_LESS_THAN_150_CHAR;
  }
  return true;
};

export const findActiveAccount = (
  assignment: BankAccountAssignment,
  serverResponse: BankAccountsResponse
): Optional<SelectableBankAccount> => {
  const pendingAccount = serverResponse.pendingBankAccounts.find((account) =>
    account.assignments.includes(assignment)
  );
  if (pendingAccount) {
    return some(pendingAccountToSelectableAccount(pendingAccount));
  }
  const wssAccount = serverResponse.bankAccounts.find((account) =>
    account.bankAccount.assignments.includes(assignment)
  );
  if (wssAccount) {
    return some(wssAccountToSelectableAccount(wssAccount));
  }
  return nothing;
};
