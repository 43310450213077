import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { BankAccountConstants } from "../../constants/BankAccountConstants";
import { DataLoadStatus } from "../../constants/enums";
import {
  BankAccountAssignment,
  BankAccountsResponse,
  ChangeAccountAction,
  IBankAccountPermittedClient,
  IBankAccountsStore,
  ICountry,
  ICurrency,
  SelectableBankAccount,
  UserAddedBankAccount,
} from "../../types/bankAccountDataTypes";
import { isSomething } from "../../types/typeGuards";
import { nothing, Optional, some } from "../../types/typeUtils";
import {
  findActiveAccount,
  getBankAccountOptions,
  userAddedToSelectableAccount,
} from "../../utils/bankAccountUtils";
import { setActiveElection } from "../actions/electionsActions";

const BLANK_SERVER_RESPONSE = {
  bankAccounts: [],
  pendingBankAccounts: [],
  investmentVehicles: [],
};

export const initialState: IBankAccountsStore = {
  isBankAccountsAdminMode: false,
  activeBankAccountsClient: nothing,
  bankAccountsLoadStatus: DataLoadStatus.NOT_REQUESTED,
  bankAccountsInvestmentVehicleId: nothing,
  serverData: BLANK_SERVER_RESPONSE,
  selectedContributionAccount: nothing,
  selectedDistributionAccount: nothing,
  userAddedAccounts: [],
  countries: [],
  countriesLoadStatus: DataLoadStatus.NOT_REQUESTED,
  currencies: [],
  currenciesLoadStatus: DataLoadStatus.NOT_REQUESTED,
  unsubmittedChanges: {
    [BankAccountAssignment.CONTRIBUTION]: false,
    [BankAccountAssignment.DISTRIBUTION]: false,
  },
  initialState: nothing,
};

export interface ReqBankAccountBody {
  id: number;
  isAdmin: boolean;
}

// Define this outside of slice because we want it to take a number as payload,
// but we would only use that in the saga.
export const addBankAccount =
  createAction<UserAddedBankAccount>("ADD_BANK_ACCOUNT");
export const setSelectedContributionAccount =
  createAction<SelectableBankAccount>("SELECT_CONTRIBUTION_ACCOUNT");
export const setSelectedDistributionAccount =
  createAction<SelectableBankAccount>("SELECT_DISTRIBUTION_ACCOUNT");
export const reqBankAccounts =
  createAction<ReqBankAccountBody>("REQ_BANK_ACCOUNTS");
export const reqBankAccountForClient =
  createAction<IBankAccountPermittedClient>("REQ_BANK_ACCOUNTS_FOR_CLIENT");
export const setActiveBankAccountClient = createAction<
  Optional<IBankAccountPermittedClient>
>("SET_ACTIVE_BANK_ACCOUNT_CLIENT");

export const bankAccountsSlice = createSlice({
  name: "bankAccounts",
  initialState,
  reducers: {
    recvNoBankAccounts: (state) => {
      state.bankAccountsLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      state.bankAccountsInvestmentVehicleId = nothing;
      state.serverData = BLANK_SERVER_RESPONSE;
      state.selectedContributionAccount = nothing;
      state.selectedDistributionAccount = nothing;
    },
    recvBankAccountsForClient: (
      state,
      action: PayloadAction<BankAccountsResponse>
    ) => {
      state.bankAccountsLoadStatus = DataLoadStatus.SUCCESSFUL;
      state.serverData = action.payload;
    },
    recvBankAccounts: (state, action: PayloadAction<BankAccountsResponse>) => {
      state.bankAccountsLoadStatus = DataLoadStatus.SUCCESSFUL;
      state.serverData = action.payload;
      const selectedDistributionAcc = findActiveAccount(
        BankAccountAssignment.DISTRIBUTION,
        action.payload
      );
      const selectedContributionAcc = findActiveAccount(
        BankAccountAssignment.CONTRIBUTION,
        action.payload
      );
      state.selectedContributionAccount = selectedContributionAcc;
      state.selectedDistributionAccount = selectedDistributionAcc;
      state.initialState = some({
        selectedContributionAccount: selectedContributionAcc,
        selectedDistributionAccount: selectedDistributionAcc,
        userAddedAccounts: [],
        unsubmittedChanges: {
          [BankAccountAssignment.CONTRIBUTION]: false,
          [BankAccountAssignment.DISTRIBUTION]: false,
        },
      });
    },
    errBankAccounts: (state) => {
      state.bankAccountsLoadStatus = DataLoadStatus.UNSUCCESSFUL;
    },
    recvNoBankAccountCountries: (state) => {
      state.countriesLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      state.countries = [];
    },
    recvBankAccountCountries: (state, action: PayloadAction<ICountry[]>) => {
      state.countriesLoadStatus = DataLoadStatus.SUCCESSFUL;
      state.countries = action.payload;
    },
    errBankAccountCountries: (state) => {
      state.countriesLoadStatus = DataLoadStatus.UNSUCCESSFUL;
    },
    recvNoBankAccountCurrencies: (state) => {
      state.currenciesLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      state.currencies = [];
    },
    recvBankAccountCurrencies: (state, action: PayloadAction<ICurrency[]>) => {
      state.currenciesLoadStatus = DataLoadStatus.SUCCESSFUL;
      state.currencies = action.payload;
    },
    errBankAccountCurrencies: (state) => {
      state.currenciesLoadStatus = DataLoadStatus.UNSUCCESSFUL;
    },
    reqBankAccountCountries: (state) => {
      state.countriesLoadStatus = DataLoadStatus.LOADING;
      state.countries = [];
    },
    reqBankAccountCurrencies: (state) => {
      state.currenciesLoadStatus = DataLoadStatus.LOADING;
      state.currencies = [];
    },
    undoBankAccountChanges: (state) => {
      if (!isSomething(state.initialState)) return;
      const {
        selectedContributionAccount,
        selectedDistributionAccount,
        unsubmittedChanges,
        userAddedAccounts,
      } = state.initialState.value;

      state.selectedContributionAccount = selectedContributionAccount;
      state.selectedDistributionAccount = selectedDistributionAccount;
      state.unsubmittedChanges = unsubmittedChanges;
      state.userAddedAccounts = userAddedAccounts;
    },
    changeContributionAccount: (state, action: ChangeAccountAction) => {
      const { bankAccountUniqueId } = action.payload;
      if (
        [
          BankAccountConstants.NO_ACCOUNT_ASSIGNED,
          BankAccountConstants.NO_US_ACCOUNT,
        ].includes(bankAccountUniqueId)
      ) {
        state.selectedContributionAccount = nothing;
        state.unsubmittedChanges[BankAccountAssignment.CONTRIBUTION] = false;
        return;
      }

      const options = getBankAccountOptions(
        state,
        BankAccountAssignment.CONTRIBUTION,
        false
      );
      const bankAccount = options.find(
        (acc) => acc.main.bankAccountUniqueId === bankAccountUniqueId
      );

      if (bankAccount) {
        const contributionBankAccount = {
          ...bankAccount,
          assignments: [BankAccountAssignment.CONTRIBUTION],
        };
        state.selectedContributionAccount = some(contributionBankAccount);
      } else {
        state.selectedContributionAccount = nothing;
      }

      // only set unsubmitted changes to true if chosen account is not the initial one
      const initialContributionAccount = isSomething(state.initialState)
        ? state.initialState.value.selectedContributionAccount
        : nothing;
      if (
        isSomething(initialContributionAccount) &&
        initialContributionAccount.value.main.bankAccountUniqueId ===
        bankAccountUniqueId
      ) {
        state.unsubmittedChanges[BankAccountAssignment.CONTRIBUTION] = false;
      } else {
        state.unsubmittedChanges[BankAccountAssignment.CONTRIBUTION] = true;
      }
    },
    changeDistributionAccount: (state, action: ChangeAccountAction) => {
      const { bankAccountUniqueId } = action.payload;
      const options = getBankAccountOptions(
        state,
        BankAccountAssignment.DISTRIBUTION,
        false
      );
      const bankAccount = options.find(
        (acc) => acc.main.bankAccountUniqueId === bankAccountUniqueId
      );

      if (bankAccount) {
        const distributionBankAccount = {
          ...bankAccount,
          assignments: [BankAccountAssignment.DISTRIBUTION],
        };
        state.selectedDistributionAccount = some(distributionBankAccount);
      } else {
        state.selectedDistributionAccount = nothing;
      }

      // only set unsubmitted changes to true if chosen account is not the initial one
      const initialDistributionAccount = isSomething(state.initialState)
        ? state.initialState.value.selectedDistributionAccount
        : nothing;
      if (
        isSomething(initialDistributionAccount) &&
        initialDistributionAccount.value.main.bankAccountUniqueId ===
        bankAccountUniqueId
      ) {
        state.unsubmittedChanges[BankAccountAssignment.DISTRIBUTION] = false;
      } else {
        state.unsubmittedChanges[BankAccountAssignment.DISTRIBUTION] = true;
      }
    },
    submitBankAccountChanges: (state) => {
      state.unsubmittedChanges[BankAccountAssignment.DISTRIBUTION] = false;
      state.unsubmittedChanges[BankAccountAssignment.CONTRIBUTION] = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setActiveBankAccountClient, (state, action) => {
      if (isSomething(action.payload)) {
        state.activeBankAccountsClient = action.payload;
      }
    });
    builder.addCase(reqBankAccounts, (state, action) => {
      state.isBankAccountsAdminMode = action.payload.isAdmin;
      state.bankAccountsLoadStatus = DataLoadStatus.LOADING;
      state.bankAccountsInvestmentVehicleId = some(action.payload.id);
      state.serverData = BLANK_SERVER_RESPONSE;
      state.selectedContributionAccount = nothing;
      state.selectedDistributionAccount = nothing;
    });

    builder.addCase(reqBankAccountForClient, (state, action) => {
      state.bankAccountsLoadStatus = DataLoadStatus.LOADING;
      state.activeBankAccountsClient = some(action.payload);
      state.serverData = BLANK_SERVER_RESPONSE;
      state.selectedContributionAccount = nothing;
      state.selectedDistributionAccount = nothing;
    });
    builder.addCase(addBankAccount, (state, action) => {
      state.userAddedAccounts.push(action.payload);
      const selectableAccount = userAddedToSelectableAccount(action.payload);
      if (action.payload.assignment === BankAccountAssignment.CONTRIBUTION) {
        state.selectedContributionAccount = some(selectableAccount);
      } else {
        state.selectedDistributionAccount = some(selectableAccount);
      }
      state.unsubmittedChanges[action.payload.assignment] = true;
    });
    builder.addCase(setSelectedContributionAccount, (state, action) => {
      state.selectedContributionAccount = some(action.payload);
    });
    builder.addCase(setSelectedDistributionAccount, (state, action) => {
      state.selectedDistributionAccount = some(action.payload);
    });
    builder.addCase(setActiveElection, (state, action) => {
      (state.bankAccountsLoadStatus = DataLoadStatus.NOT_REQUESTED),
        (state.bankAccountsInvestmentVehicleId = some(
          action.payload.investmentVehicleId
        )),
        (state.serverData = BLANK_SERVER_RESPONSE),
        (state.selectedContributionAccount = nothing),
        (state.selectedDistributionAccount = nothing),
        (state.userAddedAccounts = []),
        (state.unsubmittedChanges = {
          [BankAccountAssignment.CONTRIBUTION]: false,
          [BankAccountAssignment.DISTRIBUTION]: false,
        });
    });
  },
  selectors: {
    getEligibleContributionAccounts: (sliceState): SelectableBankAccount[] => {
      return getBankAccountOptions(
        sliceState,
        BankAccountAssignment.CONTRIBUTION,
        true
      );
    },
    getEligibleDistributionAccounts: (sliceState): SelectableBankAccount[] => {
      return getBankAccountOptions(
        sliceState,
        BankAccountAssignment.DISTRIBUTION,
        true
      );
    },
    hasPendingContribution: (sliceState): boolean => {
      return sliceState.serverData.pendingBankAccounts.some((account) =>
        account.assignments.includes(BankAccountAssignment.CONTRIBUTION)
      );
    },
    hasPendingDistribution: (sliceState): boolean => {
      return sliceState.serverData.pendingBankAccounts.some((account) =>
        account.assignments.includes(BankAccountAssignment.DISTRIBUTION)
      );
    },
    hasUnsubmittedChangesContribution: (sliceState): boolean => {
      return sliceState.unsubmittedChanges[BankAccountAssignment.CONTRIBUTION];
    },
    hasUnsubmittedChangesDistribution: (sliceState): boolean => {
      return sliceState.unsubmittedChanges[BankAccountAssignment.DISTRIBUTION];
    },
  },
});

export const bankAccountsReducer = bankAccountsSlice.reducer;
export const {
  errBankAccounts,
  recvBankAccounts,
  recvBankAccountsForClient,
  recvNoBankAccounts,
  errBankAccountCountries,
  recvBankAccountCountries,
  recvNoBankAccountCountries,
  errBankAccountCurrencies,
  recvBankAccountCurrencies,
  recvNoBankAccountCurrencies,
  reqBankAccountCountries,
  reqBankAccountCurrencies,
  changeContributionAccount,
  changeDistributionAccount,
  undoBankAccountChanges,
  submitBankAccountChanges,
} = bankAccountsSlice.actions;
export const {
  getEligibleContributionAccounts,
  getEligibleDistributionAccounts,
  hasUnsubmittedChangesContribution,
  hasUnsubmittedChangesDistribution,
  hasPendingContribution,
  hasPendingDistribution,
} = bankAccountsSlice.selectors;
