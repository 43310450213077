import { Box } from "@mui/material";
import { AdminClientLabels } from "common";
import React from "react";
import { Route, Routes } from "react-router-dom";

import { HasEntitlementContainer } from "../../common/components/HasEntitlementContainer/HasEntitlementContainer";
import CommitmentsWrapper from "../Commitments/CommitmentsWrapper";
import DashboardWrapper from "../Dashboard/DashboardWrapper";
import InvestmentsAndCarryPageWrapper from "../InvestmentsAndCarry/InvestmentsAndCarryPageWrapper";
import { NavigationTabs } from "../SubHeader/NavigationTabs/NavigationTabs";
import { SubHeader } from "../SubHeader/SubHeader";
import styles from "./ClientData.module.scss";

export const ClientData = () => {
  return (
    <>
      <HasEntitlementContainer
        entitlementLoadStatusName="internalInvestmentAdminEntitlementLoadStatus"
        entitlementName="hasInternalInvestmentAdminEntitlement"
      >
        <SubHeader />
        <Routes>
          <Route
            path={"/:clientId/*"}
            element={
              <>
                <NavigationTabs />
                <Box className={styles.internalInvestmentsContent}>
                  <Routes>
                    <Route path="/dashboard" element={<DashboardWrapper />} />
                    <Route
                      path="/investments-and-carry/*"
                      element={<InvestmentsAndCarryPageWrapper />}
                    />
                    <Route
                      path="/commitments"
                      element={<CommitmentsWrapper />}
                    />
                  </Routes>
                </Box>
              </>
            }
          />
          <Route
            path={""}
            element={
              <Box className={styles.landingPage}>
                <Box
                  component="img"
                  alt={"Landing Page background"}
                  src="/assets/images/AdminLanding.png"
                  className={styles.landingImage}
                />
                <h1 className={styles.landingText}>
                  {AdminClientLabels.PLEASE_SEARCH}
                </h1>
              </Box>
            }
          />
        </Routes>
      </HasEntitlementContainer>
    </>
  );
};
