export const FooterConstants = {
  FEEDBACK: "Feedback",
  FEEDBACK_HREF: "mailto:bxwealth.support@blackstone.com",
  TWITTER: "X (Twitter)",
  TWITTER_LINK: "https://x.com/blackstone/",
  LINKEDIN: "LinkedIn",
  LINKEDIN_HREF: "http://www.linkedin.com/company/7834",
  PRIVACY: "Privacy",
  PRIVACY_HREF: "https://www.blackstone.com/privacy/",
  COOKIES: "Cookie Notice",
  COOKIES_HREF: "https://www.blackstone.com/privacy/cookie-notice/",
  PHISHING: "Phishing and Fraud Awareness",
  PHISHING_HREF: "https://www.blackstone.com/phishing-and-fraud-awareness/",
};
