import {
  DataCard,
  IBaseStore,
  IMonthlyVestingEvent,
  isEmptyResponse,
  isInProgress,
  isSomething,
  isUnsuccessful,
  reqEquityData,
  reqStockData,
  useFetchDatasetIfIdDefined,
  useFetchDatasetWithoutId,
  VestingEventsRange,
} from "common";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  getAsOfDate,
  getVestingEventsData,
} from "../../../../utils/vestingEventsUtil";
import { VestingEventsBar } from "./VestingEventsBar/VestingEventsBar";
import styles from "./VestingEventsCard.module.scss";
import { VestingEventsChart } from "./VestingEventsChart/VestingEventsChart";

/**
 * Calculates a date three years from now
 * @returns  A date
 */
const calculateDateRangeEnd = (): Date => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  date.setFullYear(date.getFullYear() + 3);
  return date;
};

/**
 * Filters a list of monthly vesting events by date until three years from now
 * @param monthlyVestingEvents - The list of monthly vesting events
 * @returns  A list of monthly vesting events
 */
const filterVestingEventsByTreeYears = (
  monthlyVestingEvents: IMonthlyVestingEvent[]
): IMonthlyVestingEvent[] => {
  return monthlyVestingEvents.reduce(
    (
      monthlyVestingEvents: IMonthlyVestingEvent[],
      monthlyVestingEvent: IMonthlyVestingEvent
    ) => {
      const dateEnd = calculateDateRangeEnd();
      const currentYear = monthlyVestingEvent.date.getFullYear();
      const currentMonth = monthlyVestingEvent.date.getMonth();
      const endYear = dateEnd.getFullYear();
      const endMonth = dateEnd.getMonth();
      if (
        currentYear < endYear ||
        (currentYear == endYear && currentMonth <= endMonth)
      ) {
        const newVestingEvent: IMonthlyVestingEvent = Object.assign(
          {},
          monthlyVestingEvent,
          {
            key: monthlyVestingEvents.length,
          }
        );
        monthlyVestingEvents.push(newVestingEvent);
      }
      return monthlyVestingEvents;
    },
    []
  );
};

export const VestingEventsCard = () => {
  const [vestingEventsFiltered, setVestingEventsFiltered] = useState<
    IMonthlyVestingEvent[]
  >([]);
  const [showDateRangeToggle, setShowRangeDateToggle] =
    useState<boolean>(false);

  const {
    activeEquityDataId,
    equityData,
    equityDataLoadStatus,
    stockData,
    stockDataLoadStatus,
  } = useSelector((store: IBaseStore) => store.equityData);

  const { selectedStockSymbol } = useSelector(
    (store: IBaseStore) => store.viewData
  );

  const data = useMemo(() => {
    if (!isInProgress(equityDataLoadStatus, stockDataLoadStatus)) {
      const selectedEquityData = equityData[selectedStockSymbol];
      const selectedStockData = stockData[selectedStockSymbol];
      if (isSomething(selectedEquityData) && isSomething(selectedStockData)) {
        return getVestingEventsData(
          selectedEquityData.value,
          selectedStockData.value
        );
      }
    }
    return [];
  }, [
    equityData,
    equityDataLoadStatus,
    selectedStockSymbol,
    stockData,
    stockDataLoadStatus,
  ]);

  /**
   * Sets the state of vestingEventsFiltered by vesting events range
   * @param vestingEventsRange - The vesting events range
   * @returns
   */
  const setDataByRangeDate = (vestingEventsRange: VestingEventsRange) => {
    switch (vestingEventsRange) {
      case VestingEventsRange.ALL:
        setVestingEventsFiltered(data);
        break;
      default:
        const threeYearDataRange = filterVestingEventsByTreeYears(data);
        setVestingEventsFiltered(threeYearDataRange);
        break;
    }
  };

  useMemo(() => {
    const threeYearDataRange = filterVestingEventsByTreeYears(data);
    setShowRangeDateToggle(data.length > threeYearDataRange.length);
    setVestingEventsFiltered(threeYearDataRange);
  }, [data]);

  const equityRetryFunction = useFetchDatasetIfIdDefined(
    reqEquityData,
    activeEquityDataId,
    equityDataLoadStatus
  );

  const stockRetryFunction = useFetchDatasetWithoutId(
    reqStockData,
    stockDataLoadStatus
  );

  return (
    <DataCard
      failed={isUnsuccessful(equityDataLoadStatus, stockDataLoadStatus)}
      loading={isInProgress(equityDataLoadStatus, stockDataLoadStatus)}
      noData={isEmptyResponse(equityDataLoadStatus, stockDataLoadStatus)}
      id={styles.vestingEventsCard}
      onReload={[equityRetryFunction, stockRetryFunction]}
    >
      <VestingEventsBar
        showDateRangeToggle={showDateRangeToggle}
        setDataByRangeDate={setDataByRangeDate}
        asOfDate={getAsOfDate(equityData[selectedStockSymbol])}
      />
      <VestingEventsChart monthlyVestingEvents={vestingEventsFiltered} />
    </DataCard>
  );
};
