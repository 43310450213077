import { Button, Stack, Typography } from "@mui/material";
import {
  ElectionAccordionObject,
  ElectionsLabels,
  isSomething,
  Markdown,
  selectCanUseFinancing,
  selectFinancingContent,
  selectFinancingOptionsDocumentId,
} from "common";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { IBaseStore } from "../../../../../redux/store";
import { AccordionElectionCard } from "../../Shared/AccordionElectionCard/AccordionElectionCard";
import styles from "./FinancingOptionsCard.module.scss";

export const FinancingOptionsCard = () => {
  const cardContent = useSelector((state: IBaseStore) =>
    selectFinancingContent(state)
  );

  const canUseFinancing = useSelector((state: IBaseStore) =>
    selectCanUseFinancing(state)
  );

  const financingOptionsDocumentId = useSelector((state: IBaseStore) =>
    selectFinancingOptionsDocumentId(state)
  );

  const handleDocumentDownload = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      // clicking download should not expand/collapse card
      e.stopPropagation();
      // TODO: Replace this with actual document fetch logic
      alert(
        `Work In Progress: This button will download Financing Options Document with Id ${financingOptionsDocumentId}`
      );
    },
    [financingOptionsDocumentId]
  );

  const Header = () => {
    return (
      <Stack className={styles.header}>
        <Typography className={styles.title}>
          {ElectionsLabels.FINANCING_OPTIONS}
        </Typography>
        {financingOptionsDocumentId && (
          <Button
            size="medium"
            variant="text"
            onClick={(e) => handleDocumentDownload(e)}
          >
            {ElectionsLabels.FINANCING_OPTIONS_DOWNLOAD_BUTTON_TEXT}
          </Button>
        )}
      </Stack>
    );
  };

  return canUseFinancing && isSomething(cardContent) ? (
    <AccordionElectionCard
      headerComponent={<Header />}
      cardContentComponent={<Markdown text={cardContent.value} />}
      accordionId={ElectionAccordionObject.ELECT_FINANCING_OPTIONS}
    />
  ) : (
    <></>
  );
};
