import { SelectChangeEvent, Stack } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import {
  DEFAULT_COLUMN_DEF,
  ElectionAdminLabels,
  ElectorViewLabels,
  isInProgress,
  isSomething,
  isValidPageSize,
  LoadingIndicator,
  NoDataAvailableError,
  nothing,
  PageSize,
  PaginationBar,
  SearchBar,
  some,
  updateElectorsGridPage,
  updateElectorsGridPageSize,
  useGridExtensions,
} from "common";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { NoElectionParticipantsMessage } from "../../../../common/components/NoElectionParticipantsMessage/NoElectionParticipantsMessage";
import { AdminUIStore } from "../../../../redux/store";
import ElectionResultsDownloadButton from "../../../ElectionResult/ElectionResultsDownloadButton";
import { useElectorViewGrid } from "../../Hooks/ElectorViewGridHooks";
import styles from "./ElectorViewGrid.module.scss";

const ElectorViewGrid: React.FC = () => {
  const dispatch = useDispatch();
  const { setHeaderHeight, resizeColumns, onGridReady } = useGridExtensions();
  const {
    electionRoundId,
    columnDefs,
    currentPageItems,
    totalRows,
    pageSize,
    page,
    onSearch,
  } = useElectorViewGrid();

  const { electorsGridOptions, electionsForElectionRoundLoadStatus } =
    useSelector((state: AdminUIStore) => state.elections);
  const { searchTerm } = electorsGridOptions;

  if (!electionRoundId) {
    return <NoDataAvailableError />;
  }

  return (
    <Stack className={styles.container}>
      {isInProgress(electionsForElectionRoundLoadStatus) ? (
        <div className={styles.loadingIndicator}>
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <Stack direction="row" className={styles.gridToolbar}>
            <SearchBar
              placeholder={ElectionAdminLabels.SEARCH_FOR_ELECTOR}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newSearchTerm =
                  event.target.value != "" ? some(event.target.value) : nothing;
                onSearch(newSearchTerm);
              }}
              // we want to filter results on user hitting enter key
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === "Enter") {
                  onSearch(searchTerm);
                }
              }}
              value={isSomething(searchTerm) ? searchTerm.value : undefined}
              className={styles.searchBar}
              searchIconPosition="start"
              onClear={() => {
                onSearch(nothing);
              }}
            />
            <ElectionResultsDownloadButton
              id={electionRoundId}
              label={ElectorViewLabels.ELECTION_RESULTS}
              disabled={currentPageItems.length == 0}
            />
          </Stack>
          <Stack className="ag-theme-alpine" id={styles.dataGrid}>
            <AgGridReact
              cacheQuickFilter={true}
              columnDefs={columnDefs}
              defaultColDef={DEFAULT_COLUMN_DEF}
              domLayout="autoHeight"
              enableCellTextSelection={true}
              onColumnResized={setHeaderHeight}
              onDisplayedColumnsChanged={resizeColumns}
              onFirstDataRendered={setHeaderHeight}
              onGridReady={onGridReady}
              onGridSizeChanged={resizeColumns}
              onModelUpdated={resizeColumns}
              onRowDataUpdated={resizeColumns}
              // only show rows for the given page
              rowData={currentPageItems}
              noRowsOverlayComponent={NoElectionParticipantsMessage}
              scrollbarWidth={10}
              suppressAggFuncInHeader={true}
              suppressBrowserResizeObserver={true}
              suppressCellFocus={true}
              suppressContextMenu={true}
              suppressDragLeaveHidesColumns={true}
              suppressMenuHide={true}
              suppressMovableColumns={true}
              tooltipShowDelay={0}
            />
          </Stack>
          <PaginationBar
            totalRows={totalRows}
            rowsOnCurrentPage={currentPageItems.length}
            pageSize={pageSize}
            currentPage={page}
            onPageSizeChange={(event: SelectChangeEvent<PageSize>) => {
              if (isValidPageSize(event.target.value)) {
                dispatch(updateElectorsGridPageSize(event.target.value));
                dispatch(updateElectorsGridPage(1));
              }
            }}
            onPageChange={(_, page: number) =>
              dispatch(updateElectorsGridPage(page))
            }
          />
        </>
      )}
    </Stack>
  );
};

export default ElectorViewGrid;
