import {
  SectionHeader,
  UnrealizedValueLabels,
} from "../../constants/LabelAndTooltipConstants";
import {
  IFundUnrealizedValue,
  IStrategyUnrealizedValueExcelRow,
  UnrealizedValue,
  UnrealizedValues,
} from "../../types/electionDataTypes";
import { WorksheetGeneratorParams } from "../../types/excelTypes";
import { isSomething } from "../../types/typeGuards";
import {
  ExcelConstants,
  formatBottomRow,
  formatHeaderRows,
  getAsOfHeader,
  hideCarryColumn,
  hideGainLossColumn,
  hideRemainingCapitalInvestedColumn,
} from "./excelUtils";

const calculateUnrealizedValuesTotals = (
  unrealized: UnrealizedValues
): UnrealizedValue => {
  let remainigTotal = 0;
  let gainLossTotal = 0;
  let carryTotal = 0;
  let unrealizedTotal = 0;
  unrealized.unrealizedValues.forEach(function (currentUnrealized) {
    remainigTotal += currentUnrealized.remainingCapitalInvested;
    gainLossTotal += currentUnrealized.gainLoss;
    carryTotal += currentUnrealized.carriedInterest;
    unrealizedTotal += currentUnrealized.total;
  });

  return {
    strategyId: 0,
    strategyName: UnrealizedValueLabels.ALL,
    total: unrealizedTotal,
    remainingCapitalInvested: remainigTotal,
    gainLoss: gainLossTotal,
    carriedInterest: carryTotal,
    endDate: unrealized.asOfDate,
    fundData: [],
  };
};

export const buildUnrealizedValuesWorksheet = (
  params: WorksheetGeneratorParams
) => {
  const { workbook, selectedElection } = params;
  if (
    selectedElection !== undefined &&
    isSomething(selectedElection.electionInvestmentPortfolio) &&
    isSomething(
      selectedElection.electionInvestmentPortfolio.value.unrealizedValuesData
    )
  ) {
    const unrealizedValues =
      selectedElection.electionInvestmentPortfolio.value.unrealizedValuesData
        .value;
    const worksheet = workbook.addWorksheet(SectionHeader.UNREALIZED_VALUE, {
      views: [
        {
          state: "frozen",
          ySplit: 2,
        },
      ],
    });
    worksheet.columns = [
      {
        header: UnrealizedValueLabels.STRATEGY,
        key: ExcelConstants.KEYS.STRATEGY_NAME,
        width: ExcelConstants.SIZES.SMALL,
      },
      {
        header: UnrealizedValueLabels.FUND,
        key: ExcelConstants.KEYS.FUND_NAME,
        width: ExcelConstants.SIZES.SMALL,
      },
      {
        header: UnrealizedValueLabels.REMAINING_CAPITAL_INVESTED,
        key: ExcelConstants.KEYS.REMAINING_CAPITAL_INVESTED,
        width: ExcelConstants.SIZES.MEDIUM,
        style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
      },
      {
        header: UnrealizedValueLabels.GAIN_LOSS,
        key: ExcelConstants.KEYS.GAIN_LOSS,
        width: ExcelConstants.SIZES.SMALL,
        style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
      },
      {
        header: UnrealizedValueLabels.CARRY,
        key: ExcelConstants.KEYS.CARRIED_INTEREST,
        width: ExcelConstants.SIZES.SMALL,
        style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
      },
      {
        header: UnrealizedValueLabels.UNREALIZED_TOTAL,
        key: ExcelConstants.KEYS.TOTAL,
        width: ExcelConstants.SIZES.SMALL,
        style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
      },
    ];
    const sortedRowsByName: IStrategyUnrealizedValueExcelRow[] = [
      ...unrealizedValues.unrealizedValues,
    ]
      .sort((a, b) => a.strategyName.localeCompare(b.strategyName))
      .map((value: UnrealizedValue) => {
        return {
          ...value,
          fundName: value.strategyName,
        };
      });

    sortedRowsByName.forEach((element: IStrategyUnrealizedValueExcelRow) => {
      const strategyRow = worksheet.addRow(element);
      strategyRow.font = { ...strategyRow.font, bold: true };
      const fundRows: IStrategyUnrealizedValueExcelRow[] = element.fundData.map(
        (fundUnrealizedValue: IFundUnrealizedValue) => {
          return {
            strategyId: element.strategyId,
            strategyName: element.strategyName,
            total: fundUnrealizedValue.unrealizedValue.total,
            remainingCapitalInvested:
              fundUnrealizedValue.unrealizedValue.remainingCapitalInvested,
            gainLoss: fundUnrealizedValue.unrealizedValue.gainLoss,
            carriedInterest:
              fundUnrealizedValue.unrealizedValue.carriedInterest,
            endDate: new Date(),
            fundName: fundUnrealizedValue.fund.fundShortName,
            fundData: [],
          };
        }
      );
      for (const fund of fundRows) {
        const fundRow = worksheet.addRow(fund);
        const groupByCell = fundRow.getCell("B");
        groupByCell.alignment = { indent: 1 };
      }
    });

    worksheet.addRow({
      ...calculateUnrealizedValuesTotals(unrealizedValues),
      fundName: UnrealizedValueLabels.TOTAL,
    });

    if (hideRemainingCapitalInvestedColumn(unrealizedValues)) {
      const remainingCol = worksheet.getColumnKey(
        ExcelConstants.KEYS.REMAINING_CAPITAL_INVESTED
      );
      worksheet.spliceColumns(remainingCol.number, 1);
    }

    if (hideGainLossColumn(unrealizedValues)) {
      const gainLossCol = worksheet.getColumnKey(ExcelConstants.KEYS.GAIN_LOSS);
      worksheet.spliceColumns(gainLossCol.number, 1);
    }

    if (hideCarryColumn(unrealizedValues)) {
      const carryCol = worksheet.getColumnKey(ExcelConstants.KEYS.CARRY);
      worksheet.spliceColumns(carryCol.number, 1);
    }

    //Add Unrealized Value (as of YYYY/MM/DD) in the first column
    worksheet.insertRow(1, Array<undefined>(11));
    const unrealizedHeader = worksheet.getCell("C1");
    unrealizedHeader.value = `Unrealized Value ${getAsOfHeader(
      unrealizedValues.asOfDate
    )}`;
    unrealizedHeader.style = {
      ...unrealizedHeader.style,
      alignment: { horizontal: "center" },
    };
    worksheet.mergeCells("C1:E1");

    // Bold second row of the file containing the titles for each column
    const columnNames = worksheet.getRow(2);
    columnNames.font = { bold: true };

    formatHeaderRows(worksheet);
    formatBottomRow(worksheet);
  }
  return workbook;
};
