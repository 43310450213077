import { WarningRounded } from "@mui/icons-material";
import LinkIcon from "@mui/icons-material/Link";
import {
  Alert,
  AlertTitle,
  Box,
  FormControl,
  Grid,
  Link,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import {
  AccountNumberType,
  AccountType,
  AddBankAccountState,
  BankAccountConstants,
  BankAccountTooltips,
  BankIdType,
  customBankIdValidation,
  customOnBankIdChange,
  IBaseStore,
  ICountry,
  ICurrency,
  selectBankAccountCountries,
  selectBankAccountCurrencies,
} from "common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useSelector } from "react-redux";

import styles from "./AddBankAccountForm.module.scss";
import { DropdownInput } from "./DropdownInput/DropdownInput";
import { AccountIdTypeSampleImage } from "./Image/AccountIdTypeSampleImage";
import IntermediaryAccountForm from "./IntermediaryAccountForm/IntermediaryAccountForm";
import { AccountTypeRadioButtonInput } from "./RadioButtonInput/AccountTypeRadioButtonInput";
import { TextFieldInput } from "./TextFieldInput/TextFieldInput";

export interface IAddBankAccountFormProps {
  addBankAccountForm: UseFormReturn<AddBankAccountState>;
  isContribution: boolean;
  isConfirmClose: boolean;
}

export const AddBankAccountForm = (props: IAddBankAccountFormProps) => {
  const [showIntermediaryAccForm, setShowIntermediaryAccForm] = useState(false);
  const { addBankAccountForm, isContribution, isConfirmClose } = props;

  const bankAccountCountries = useSelector((state: IBaseStore) =>
    selectBankAccountCountries(state)
  );

  const bankAccountCurrencies = useSelector((state: IBaseStore) =>
    selectBankAccountCurrencies(state)
  );

  const getCountryIdByName = (countryName: string): number => {
    const country: ICountry | undefined = bankAccountCountries.find(
      (country: ICountry) => country.name === countryName
    );
    return country ? country.id : -1;
  };

  const selectedBankIdType: BankIdType = addBankAccountForm.watch("bankIdType");

  const usCurrencyCode = useMemo(() => {
    const usCurrency =
      bankAccountCurrencies.find(
        (currency) =>
          currency.id === BankAccountConstants.USD_CURRENCY_ID_NUMBER
      ) ?? "";
    return usCurrency ? usCurrency.currencyCode : "";
  }, [bankAccountCurrencies]);

  const [accountNumberTypeOptions, setAccountNumberTypeOptions] = useState<
    AccountNumberType[]
  >([AccountNumberType.ACCOUNT_NUMBER]);
  const [bankIdTypeOptions, setBankIdTypeOptions] = useState<BankIdType[]>([
    BankIdType.ABA_ROUTING_NUMBER,
  ]);

  const customOnCountryChange = (event: SelectChangeEvent<string>) => {
    const countryCode = getCountryIdByName(event.target.value);
    const accountNumberType: AccountNumberType =
      countryCode === BankAccountConstants.US_COUNTRY_ID_NUMBER
        ? AccountNumberType.ACCOUNT_NUMBER
        : AccountNumberType.BBAN;
    const bankIdType: BankIdType =
      countryCode === BankAccountConstants.US_COUNTRY_ID_NUMBER
        ? BankIdType.ABA_ROUTING_NUMBER
        : BankIdType.SWIFT;
    const newAccountNumberTypeOptions: AccountNumberType[] =
      countryCode === BankAccountConstants.US_COUNTRY_ID_NUMBER
        ? [AccountNumberType.ACCOUNT_NUMBER]
        : [AccountNumberType.BBAN, AccountNumberType.IBAN];
    const newBankIdTypeOptions: BankIdType[] =
      countryCode === BankAccountConstants.US_COUNTRY_ID_NUMBER
        ? [BankIdType.ABA_ROUTING_NUMBER]
        : [BankIdType.SWIFT, BankIdType.SORT, BankIdType.OTHER];

    addBankAccountForm.setValue("country", event.target.value);
    addBankAccountForm.setValue("accountNumberType", accountNumberType);
    addBankAccountForm.setValue("bankIdType", bankIdType);
    setAccountNumberTypeOptions(newAccountNumberTypeOptions);
    setBankIdTypeOptions(newBankIdTypeOptions);
  };

  const bankIdOnChange = useCallback(
    (value: string) => {
      return customOnBankIdChange(value, selectedBankIdType);
    },
    [selectedBankIdType]
  );

  const bankIdValidation = useCallback(
    (data: string) => {
      return customBankIdValidation(data, selectedBankIdType);
    },
    [selectedBankIdType]
  );

  useEffect(() => {
    // need to re-validate bank Id when it changes or bank id type changes
    if (
      (addBankAccountForm.getFieldState("bankId").isDirty ||
        addBankAccountForm.getFieldState("bankIdType").isDirty) &&
      addBankAccountForm.getValues("bankId").length > 0
    ) {
      addBankAccountForm.trigger("bankId");
    }
  }, [selectedBankIdType, addBankAccountForm]);

  return (
    <>
      <FormControl className={styles.addBankAccountForm}>
        <Box>
          {isConfirmClose && (
            <Alert
              className={styles.confirmAlert}
              variant="standard"
              severity="warning"
              icon={<WarningRounded />}
            >
              <AlertTitle className={styles.title}>
                {BankAccountConstants.CONFIRM_CLOSE_WARNING}
              </AlertTitle>
            </Alert>
          )}
          <Grid container className={styles.FormColumnsContainer}>
            <Grid item xs={12} lg={6}>
              <Stack className={styles.inputRow}>
                <Grid item xs={12} md={6} className={styles.rowItems}>
                  <Controller
                    control={addBankAccountForm.control}
                    name={"accountHolderName"}
                    render={({ field, fieldState: { error } }) => (
                      <TextFieldInput
                        value={field.value}
                        field={field}
                        label={BankAccountConstants.ACCOUNT_HOLDER}
                        placeHolderText={
                          BankAccountConstants.ACCOUNT_HOLDER_PLACEHOLDER
                        }
                        triggerOnBlur={() => {
                          addBankAccountForm.trigger("accountHolderName");
                        }}
                        tooltip={BankAccountTooltips.ACCOUNT_HOLDER}
                        error={error}
                      />
                    )}
                    rules={{
                      required:
                        BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD,
                      maxLength: {
                        value: 150,
                        message:
                          BankAccountConstants.MUST_BE_LESS_THAN_150_CHAR,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={styles.rowItems}>
                  <Controller
                    control={addBankAccountForm.control}
                    name={"accountType"}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <AccountTypeRadioButtonInput
                        value={value}
                        onChange={onChange}
                        disabled={isContribution}
                        label={BankAccountConstants.ACCOUNT_TYPE}
                        tooltip={BankAccountTooltips.ACCOUNT_TYPE}
                        options={[AccountType.CHECKING, AccountType.SAVINGS]}
                        error={error}
                      />
                    )}
                    rules={{
                      required:
                        BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD,
                    }}
                  />
                </Grid>
              </Stack>
              <Stack className={styles.inputRow}>
                <Grid item xs={12} md={6} className={styles.rowItems}>
                  <Controller
                    control={addBankAccountForm.control}
                    name={"accountNumberType"}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DropdownInput
                        value={value}
                        onChange={onChange}
                        label={BankAccountConstants.ACCOUNT_ID_TYPE}
                        options={accountNumberTypeOptions}
                        disabled={false}
                        tooltip={<AccountIdTypeSampleImage />}
                        error={error}
                      />
                    )}
                    rules={{
                      required:
                        BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={styles.rowItems}>
                  <Controller
                    control={addBankAccountForm.control}
                    name={"accountNumber"}
                    render={({ field, fieldState: { error } }) => (
                      <TextFieldInput
                        value={field.value}
                        field={field}
                        label={BankAccountConstants.ACCOUNT_ID_NUMBER}
                        placeHolderText={
                          BankAccountConstants.ACCOUNT_ID_NUMBER_PLACEHOLDER
                        }
                        triggerOnBlur={() => {
                          addBankAccountForm.trigger("accountNumber");
                        }}
                        error={error}
                        shouldObfuscate={true}
                      />
                    )}
                    rules={{
                      required:
                        BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD,
                      maxLength: {
                        value: 150,
                        message:
                          BankAccountConstants.MUST_BE_LESS_THAN_150_CHAR,
                      },
                    }}
                  />
                </Grid>
              </Stack>
              <Stack className={styles.inputRow}>
                <Controller
                  control={addBankAccountForm.control}
                  name={"confirmAccountId"}
                  render={({ field, fieldState: { error } }) => (
                    <TextFieldInput
                      value={field.value}
                      field={field}
                      label={BankAccountConstants.CONFIRM_ACCOUNT_ID}
                      placeHolderText={
                        BankAccountConstants.ACCOUNT_ID_NUMBER_PLACEHOLDER
                      }
                      triggerOnBlur={() => {
                        addBankAccountForm.trigger("confirmAccountId");
                      }}
                      error={error}
                      shouldObfuscate={true}
                    />
                  )}
                  rules={{
                    required:
                      BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD,
                    maxLength: {
                      value: 150,
                      message: BankAccountConstants.MUST_BE_LESS_THAN_150_CHAR,
                    },
                    validate: (value: string) => {
                      const accountId =
                        addBankAccountForm.getValues().accountNumber;
                      if (accountId !== value) {
                        return BankAccountConstants.MUST_MATCH_ACCOUNT_ID;
                      }
                      return true;
                    },
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Stack className={styles.inputRow}>
                <Grid item xs={12} md={6} className={styles.rowItems}>
                  <Controller
                    control={addBankAccountForm.control}
                    name={"country"}
                    render={({ field: { value }, fieldState: { error } }) => (
                      <DropdownInput
                        value={value}
                        onChange={customOnCountryChange}
                        label={BankAccountConstants.COUNTRY}
                        options={bankAccountCountries.map(
                          (country: ICountry) => country.name
                        )}
                        disabled={isContribution}
                        error={error}
                      />
                    )}
                    rules={{
                      required:
                        BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={styles.rowItems}>
                  <Controller
                    control={addBankAccountForm.control}
                    name={"currency"}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DropdownInput
                        value={value}
                        onChange={onChange}
                        label={BankAccountConstants.CURRENCY}
                        options={bankAccountCurrencies.map(
                          (currency: ICurrency) => currency.currencyCode
                        )}
                        disabled={isContribution}
                        error={error}
                        warningMessage={
                          value === usCurrencyCode
                            ? undefined
                            : BankAccountConstants.NON_US_CURRENCY_WARNING_MESSAGE
                        }
                      />
                    )}
                    rules={{
                      required:
                        BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD,
                    }}
                  />
                </Grid>
              </Stack>
              <Stack className={styles.inputRow}>
                <Grid item xs={12} md={6} className={styles.rowItems}>
                  <Controller
                    control={addBankAccountForm.control}
                    name={"bankIdType"}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DropdownInput
                        value={value}
                        onChange={onChange}
                        label={BankAccountConstants.BANK_ID_TYPE}
                        options={bankIdTypeOptions}
                        disabled={false}
                        tooltip={<AccountIdTypeSampleImage />}
                        error={error}
                      />
                    )}
                    rules={{
                      required:
                        BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={styles.rowItems}>
                  <Controller
                    control={addBankAccountForm.control}
                    name={"bankId"}
                    render={({ field, fieldState: { error } }) => (
                      <TextFieldInput
                        value={field.value}
                        field={field}
                        label={BankAccountConstants.BANK_ID_NUMBER}
                        placeHolderText={
                          BankAccountConstants.BANK_ID_NUMBER_PLACEHOLDER
                        }
                        triggerOnBlur={() => {
                          addBankAccountForm.trigger("bankId");
                        }}
                        error={error}
                        customHandleInputChange={bankIdOnChange}
                      />
                    )}
                    rules={{
                      validate: (data: string) => {
                        return bankIdValidation(data);
                      },
                    }}
                  />
                </Grid>
              </Stack>
              <Stack className={styles.inputRow}>
                <Controller
                  control={addBankAccountForm.control}
                  name={"bankName"}
                  render={({ field, fieldState: { error } }) => (
                    <TextFieldInput
                      value={field.value}
                      field={field}
                      label={BankAccountConstants.BANK_NAME}
                      placeHolderText={BankAccountConstants.ENTER_BANK_NAME}
                      triggerOnBlur={() => {
                        addBankAccountForm.trigger("bankName");
                      }}
                      error={error}
                    />
                  )}
                  rules={{
                    required:
                      BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD,
                    maxLength: {
                      value: 150,
                      message: BankAccountConstants.MUST_BE_LESS_THAN_150_CHAR,
                    },
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          <Stack direction="row" className={styles.LinkAccountsContainer}>
            {isContribution ? null : (
              <Controller
                control={addBankAccountForm.control}
                name={"hasIntermediaryAccount"}
                render={({ field: { onChange } }) => (
                  <Link
                    className={styles.link}
                    component="button"
                    onClick={() => {
                      setShowIntermediaryAccForm((prevState) => {
                        onChange(!prevState);
                        return !prevState;
                      });
                    }}
                  >
                    <LinkIcon />
                    {showIntermediaryAccForm
                      ? BankAccountConstants.UNLINK_ACCOUNT
                      : BankAccountConstants.LINK_ACCOUNT}
                  </Link>
                )}
              />
            )}
          </Stack>
          <IntermediaryAccountForm
            isCollapsed={!showIntermediaryAccForm}
            form={addBankAccountForm}
          />
        </Box>
      </FormControl>
    </>
  );
};
