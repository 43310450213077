import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";

import {
  DataLoadStatus,
  ElectionDocumentType,
  ElectionRoundType,
} from "../../constants/enums";
import {
  getClientMappings,
  getElectionIVConfiguration,
  getElectionRoundConfigurationData,
  getElectionsForClient,
  getElectionsForElectionRound,
  getElectionWorkflowState,
  getInvestmentPortfolio,
  IElectionIVConfigurationSource,
  IElectionRoundConfigurationSource,
  IElectionWorkflowStateSource,
  IInvestmentPortfolioSource,
  IUpdateWorkflowStateSource,
  putElectionWorkflowState,
} from "../../services/electionsService";
import { ISubmissionResponseSource } from "../../services/submissionService";
import {
  BankAccountStageConfiguration,
  ClientId,
  ElectionWorkflowStageId,
  ElectStageConfiguration,
  EligibilityStageConfiguration,
  IBankAccountStage,
  IElectionClientMapping,
  IElectionDocument,
  IElectionInvestmentPortfolio,
  IElectionIVConfiguration,
  IElectionRound,
  IElectionRoundConfiguration,
  IElectionsForClient,
  IElectionsForElectionRound,
  IElectionStageConfigurations,
  IElectionStagesState,
  IElectionWorkflowState,
  IElectStage,
  IEligibilityStage,
  IEligibleStatus,
  IFundUnrealizedValue,
  IGetElectionDetailsRequestPayload,
  IIVStrategy,
  IOverviewStage,
  IPermittedClient,
  IReviewAndSignStage,
  IStrategy,
  ISubmissionResponse,
  IWriteElectionWorkflowStatePayload,
  OverviewStageConfiguration,
  PriorElections,
  ReviewAndSignStageConfiguration,
  StrategiesStageConfiguration,
  UnrealizedValue,
  UnrealizedValues,
} from "../../types/electionDataTypes";
import {
  Json,
  JsonConverter,
  LoadError,
  Maybe,
  nothing,
  Optional,
  some,
} from "../../types/typeUtils";
import {
  convertFromOptional,
  convertFromOptionalDateObject,
  convertToDateObject,
  convertToOptional,
  convertToOptionalDateObject,
} from "../../utils/converters";
import {
  errElectionIVConfiguration,
  errElectionRoundConfigurationData,
  errElectionsForClient,
  errElectionsForElectionRound,
  errElectionsInvestmentPortfolio,
  errElectionWorkflowState,
  errElectionWorkflowStateUpdate,
  recvApiElectionWorkflowState,
  recvElectionIVConfiguration,
  recvElectionRoundConfigurationData,
  recvElectionsForClient,
  recvElectionsForElectionRound,
  recvElectionsInvestmentPortfolio,
  recvElectionWorkflowStateUpdate,
  recvLocalElectionWorkflowState,
  reqPutElectionWorkflowState,
} from "../actions/electionsActions";
import {
  errElectionClientMappings,
  recvElectionClientMappings,
} from "../actions/internalInvestmentActions";

export type IElectionSourceDataForClient = Json<IElectionsForClient>;
export type IElectionSourceDataForElectionRound =
  Json<IElectionsForElectionRound>;

const convertOverviewConfiguration: JsonConverter<
  OverviewStageConfiguration
> = (sourceConfig) => {
  const { electionProcess, remainingExpectedCommitment } = sourceConfig;

  return {
    helpBannerTittle: some("Welcome to the 2024 Side-By-Side Elections"),
    helpBanner: convertToOptional(sourceConfig.helpBanner),
    footnotes: convertToOptional(sourceConfig.footnotes),
    legalAttestation: convertToOptional(sourceConfig.legalAttestation),
    remainingExpectedCommitment: {
      tooltip: convertToOptional(remainingExpectedCommitment.tooltip),
      footnote: convertToOptional(remainingExpectedCommitment.footnote),
    },
    electionProcess: {
      introduction: convertToOptional(electionProcess.introduction),
      overview: convertToOptional(electionProcess.overview),
      eligibility: convertToOptional(electionProcess.eligibility),
      strategies: convertToOptional(electionProcess.strategies),
      elections: convertToOptional(electionProcess.elections),
      bankAccount: convertToOptional(electionProcess.bankAccount),
      reviewAndSign: convertToOptional(electionProcess.reviewAndSign),
    },
    offerAmountTooltip: convertToOptional(sourceConfig.offerAmountTooltip),
  };
};

const convertEligibilityConfiguration: JsonConverter<
  EligibilityStageConfiguration
> = (sourceConfig) => {
  return {
    helpBannerTittle: some("Side-By-Side Program Eligibility"),
    helpBanner: convertToOptional(sourceConfig.helpBanner),
    footnotes: convertToOptional(sourceConfig.footnotes),
    residentialInformationTooltip: convertToOptional(
      sourceConfig.residentialInformationTooltip
    ),
    accreditedInvestorStatusTooltip: convertToOptional(
      sourceConfig.accreditedInvestorStatusTooltip
    ),
    qualifiedPurchaserStatusTooltip: convertToOptional(
      sourceConfig.qualifiedPurchaserStatusTooltip
    ),
    notEligibleTooltip: convertToOptional(sourceConfig.notEligibleTooltip),
  };
};

const convertStrategiesConfiguration: JsonConverter<
  StrategiesStageConfiguration
> = (sourceConfig) => {
  return {
    helpBannerTittle: some("Forecasted Capital Deployment"),
    helpBanner: convertToOptional(sourceConfig.helpBanner),
    overviewTooltip: convertToOptional(sourceConfig.overviewTooltip),
    lpCommitmentTooltip: convertToOptional(sourceConfig.lpCommitmentTooltip),
    blackstoneCommitmentTooltip: convertToOptional(
      sourceConfig.blackstoneCommitmentTooltip
    ),
    totalSubjectToElectionTooltip: convertToOptional(
      sourceConfig.totalSubjectToElectionTooltip
    ),
    forecastedDeploymentPercentageTooltip: convertToOptional(
      sourceConfig.forecastedDeploymentPercentageTooltip
    ),
    forecastedDeploymentTooltip: convertToOptional(
      sourceConfig.forecastedDeploymentTooltip
    ),
    footnotes: convertToOptional(sourceConfig.footnotes),
  };
};

const convertElectConfiguration: JsonConverter<ElectStageConfiguration> = (
  sourceConfig
) => {
  return {
    helpBannerTittle: some("Side-By-Side Election"),
    helpBanner: convertToOptional(sourceConfig.helpBanner),
    electionsCardTooltip: convertToOptional(sourceConfig.electionsCardTooltip),
    footnotes: convertToOptional(sourceConfig.footnotes),
    reallocationTooltip: convertToOptional(sourceConfig.reallocationTooltip),
    remainingCommitmentTooltip: convertToOptional(
      sourceConfig.remainingCommitmentTooltip
    ),
    mandatoryCommitmentTooltip: convertToOptional(
      sourceConfig.mandatoryCommitmentTooltip
    ),
    totalForecastedInvestmentTooltip: convertToOptional(
      sourceConfig.totalForecastedInvestmentTooltip
    ),
    financingCardContent: convertToOptional(sourceConfig.financingCardContent),
  };
};

const convertBankAccountConfiguration: JsonConverter<
  BankAccountStageConfiguration
> = (sourceConfig) => {
  return {
    helpBannerTittle: some("Bank Account Information"),
    helpBanner: convertToOptional(sourceConfig.helpBanner),
    footnotes: convertToOptional(sourceConfig.footnotes),
  };
};

const convertReviewAndSignConfiguration: JsonConverter<
  ReviewAndSignStageConfiguration
> = (sourceConfig) => {
  return {
    helpBannerTittle: some("Review, Sign & Submit"),
    helpBanner: convertToOptional(sourceConfig.helpBanner),
    footnotes: convertToOptional(sourceConfig.footnotes),
    submitMessage: convertToOptional(sourceConfig.submitMessage),
  };
};

const convertStageConfigurations: JsonConverter<
  IElectionStageConfigurations
> = (stageConfigs) => {
  return {
    overview: convertOverviewConfiguration(stageConfigs.overview),
    eligibility: convertEligibilityConfiguration(stageConfigs.eligibility),
    strategies: convertStrategiesConfiguration(stageConfigs.strategies),
    elect: convertElectConfiguration(stageConfigs.elect),
    bankAccount: convertBankAccountConfiguration(stageConfigs.bankAccount),
    reviewAndSign: convertReviewAndSignConfiguration(
      stageConfigs.reviewAndSign
    ),
  };
};

const convertOverviewStateFromSource: JsonConverter<IOverviewStage> = (
  sourceConfig
) => {
  const { hasAcceptedLegalAttestation } = sourceConfig;

  return {
    hasAcceptedLegalAttestation: hasAcceptedLegalAttestation,
  };
};

const convertEligibilityStateFromSource: JsonConverter<IEligibilityStage> = (
  sourceConfig
) => {
  const residentialInformation = {
    isUSResident: convertToOptional<boolean>(
      sourceConfig.residentialInformation.isUSResident
    ),
    isNYResident: convertToOptional<boolean>(
      sourceConfig.residentialInformation.isNYResident
    ),
  };

  const eligibleStatus = sourceConfig.eligibleStatus as IEligibleStatus;

  return {
    residentialInformation,
    eligibleStatus,
  };
};

const electionConfigFromSource: JsonConverter<IElectStage> = (sourceConfig) => {
  const elections = sourceConfig.elections.map((election) => {
    return {
      strategyId: election.strategyId,
      electedAmount: convertToOptional<number>(election.electedAmount),
    };
  });

  const useReallocation = convertToOptional<boolean>(
    sourceConfig.useReallocation
  );

  return {
    elections,
    useReallocation,
  };
};

const convertBankAccountStateFromSource: JsonConverter<IBankAccountStage> = (
  sourceConfig
) => {
  const { hasUSBankAccount } = sourceConfig;

  return {
    hasUSBankAccount: convertToOptional<boolean>(hasUSBankAccount),
  };
};

const convertReviewAndSignStateFromSource: JsonConverter<
  IReviewAndSignStage
> = (sourceConfig) => {
  const signature = convertToOptional<string>(sourceConfig.signature);
  const submitDate = convertToOptionalDateObject(sourceConfig.submitDate);

  return {
    signature,
    submitDate,
  };
};

const convertElectionStagesFromSource: JsonConverter<IElectionStagesState> = (
  sourceConfig
) => {
  return {
    overview: convertOverviewStateFromSource(sourceConfig.overview),
    eligibility: convertEligibilityStateFromSource(sourceConfig.eligibility),
    elect: electionConfigFromSource(sourceConfig.elect),
    bankAccount: convertBankAccountStateFromSource(sourceConfig.bankAccount),
    reviewAndSign: convertReviewAndSignStateFromSource(
      sourceConfig.reviewAndSign
    ),
  };
};

const convertOverviewStateToSource = (
  sourceConfig: IOverviewStage,
  resetElectionWorkflow: boolean
): Json<IOverviewStage> => {
  const { hasAcceptedLegalAttestation } = sourceConfig;

  return {
    hasAcceptedLegalAttestation: resetElectionWorkflow
      ? false
      : hasAcceptedLegalAttestation,
  };
};

const convertEligibilityStateToSource = (
  sourceConfig: IEligibilityStage,
  resetElectionWorkflow: boolean
): Json<IEligibilityStage> => {
  const residentialInformation = resetElectionWorkflow
    ? {
        isUSResident: null,
        isNYResident: null,
      }
    : {
        isUSResident: convertFromOptional<boolean>(
          sourceConfig.residentialInformation.isUSResident
        ),
        isNYResident: convertFromOptional<boolean>(
          sourceConfig.residentialInformation.isNYResident
        ),
      };

  const eligibleStatus = resetElectionWorkflow
    ? {
        accreditedInvestorStatus: {
          hasIndividualNetWorth: false,
          hasIndividualIncome: false,
          isProfessional: false,
        },
        qualifiedPurchaserStatus: {
          hasInvestmentsInBlackstoneFunds: false,
          isCompany: false,
          isTrust: false,
          isIndividualInvestment: false,
        },
        isNotEligible: false,
      }
    : (sourceConfig.eligibleStatus as IEligibleStatus);

  return {
    residentialInformation,
    eligibleStatus,
  };
};

const electionConfigToSource = (
  sourceConfig: IElectStage,
  resetElectionWorkflow: boolean
): Json<IElectStage> => {
  const elections = sourceConfig.elections.map((election) => {
    return {
      strategyId: election.strategyId,
      electedAmount: resetElectionWorkflow
        ? null
        : convertFromOptional<number>(election.electedAmount),
    };
  });

  const useReallocation = resetElectionWorkflow
    ? null
    : convertFromOptional<boolean>(sourceConfig.useReallocation);

  return {
    elections,
    useReallocation,
  };
};

const convertBankAccountStateToSource = (
  sourceConfig: IBankAccountStage,
  resetElectionWorkflow: boolean
): Json<IBankAccountStage> => {
  const { hasUSBankAccount } = sourceConfig;

  return {
    hasUSBankAccount: resetElectionWorkflow
      ? null
      : convertFromOptional<boolean>(hasUSBankAccount),
  };
};

const convertReviewAndSignStateToSource = (
  sourceConfig: IReviewAndSignStage,
  resetElectionWorkflow: boolean
): Json<IReviewAndSignStage> => {
  if (resetElectionWorkflow) {
    return {
      signature: null,
      submitDate: null,
    };
  }
  return {
    signature: convertFromOptional<string>(sourceConfig.signature),
    submitDate: convertFromOptionalDateObject(sourceConfig.submitDate),
  };
};

const convertElectionStagesToSource = (
  sourceConfig: IElectionStagesState,
  resetElectionWorkflow: boolean
): Json<IElectionStagesState> => {
  return {
    overview: convertOverviewStateToSource(
      sourceConfig.overview,
      resetElectionWorkflow
    ),
    eligibility: convertEligibilityStateToSource(
      sourceConfig.eligibility,
      resetElectionWorkflow
    ),
    elect: electionConfigToSource(sourceConfig.elect, resetElectionWorkflow),
    bankAccount: convertBankAccountStateToSource(
      sourceConfig.bankAccount,
      resetElectionWorkflow
    ),
    reviewAndSign: convertReviewAndSignStateToSource(
      sourceConfig.reviewAndSign,
      resetElectionWorkflow
    ),
  };
};

/**
 * Parses a list of document JSON string, constructing the list of document objects
 * @param documents - The documents json
 * @returns A list of document objects.
 */
const parseJsonToDocuments = (
  documents: IElectionDocument[]
): IElectionDocument[] => {
  return documents.map((document: IElectionDocument) => {
    return {
      id: document.id,
      name: document.name,
      type: document.type as ElectionDocumentType,
    };
  });
};

/**
 * Parses a electionround configuration JSON string, constructing the election round configuration object
 * @param sourceConfig - The election round configuration json
 * @returns A election rounbd configuration object.
 */
const parseJsonToElectionRoundConfig = (
  sourceConfig: IElectionRoundConfigurationSource
): IElectionRoundConfiguration => {
  sourceConfig.electionTeam.sort((a, b) =>
    a.lastName.localeCompare(b.lastName)
  );
  return {
    ...sourceConfig,
    type: sourceConfig.type as ElectionRoundType,
    documents: parseJsonToDocuments(sourceConfig.documents),
    stages: convertStageConfigurations(sourceConfig.stages),
    annualElectionStartDate: convertToOptionalDateObject(
      sourceConfig.annualElectionStartDate
    ),
    annualElectionEndDate: convertToOptionalDateObject(
      sourceConfig.annualElectionEndDate
    ),
    taxFormsDueDate: convertToDateObject(sourceConfig.taxFormsDueDate),
    electionSubmissionDeadline: convertToDateObject(
      sourceConfig.electionSubmissionDeadline ?? sourceConfig.systemCloseDate
    ),
    systemOpenDate: convertToDateObject(sourceConfig.systemOpenDate),
    systemCloseDate: convertToDateObject(sourceConfig.systemCloseDate),
    electionTeam: [...sourceConfig.electionTeam],
    strategies: parseJsonToElectionIStrategies(sourceConfig.strategies),
  };
};

const parseJsonToElectionIStrategies = (source: Json<IStrategy>[]) =>
  source.map((strategy) => ({
    ...strategy,
    funds: strategy.funds.map((fund) => ({
      ...fund,
      investmentPeriodYears: convertToOptional(fund.investmentPeriodYears),
      totalSubjectToElection: convertToOptional(fund.totalSubjectToElection),
      forecastedDeployment: convertToOptional(fund.forecastedDeployment),
    })),
  }));

const parseJsonToElectionIVConfiguration = (
  source: Json<IElectionIVConfiguration>
): IElectionIVConfiguration => {
  return {
    ...source,
    loanBalance: convertToOptional(source.loanBalance),
    loanLimit: convertToOptional(source.loanLimit),
    offeredAmount: convertToOptional(source.offeredAmount),
    financingPercentage: convertToOptional(source.financingPercentage),
    additionalRequestLimit: convertToOptional(source.additionalRequestLimit),
    strategies: source.strategies.map(convertIIVStrategyData),
  };
};

const convertIIVStrategyData = (data: Json<IIVStrategy>): IIVStrategy => {
  return {
    strategyId: data.strategyId,
    strategyName: data.strategyName ? some(data.strategyName) : nothing,
    mandatoryCommitment: data.mandatoryCommitment
      ? some(data.mandatoryCommitment)
      : nothing,
    remainingCommitment: data.remainingCommitment
      ? some(data.remainingCommitment)
      : nothing,
    min: data.min ? some(data.min) : nothing,
    max: data.max ? some(data.max) : nothing,
  };
};

/**
 * Parses a election for client JSON string, constructing the election for client object
 * @param electionData - The election for client json
 * @returns A election for client object.
 */
const parseJsonToElectionsForClient = (
  electionData: IElectionSourceDataForClient[]
): IElectionsForClient[] => {
  const electionList = electionData.map((election) => {
    const electionSubmissionDeadline: Optional<Date> =
      (election.electionSubmissionDeadline &&
        some(convertToDateObject(election.electionSubmissionDeadline))) ||
      nothing;
    return {
      ...election,
      systemOpenDate: convertToDateObject(election.systemOpenDate),
      systemCloseDate: convertToDateObject(election.systemCloseDate),
      electionSubmissionDeadline,
    } as IElectionsForClient;
  });
  return electionList;
};

/**
 * Parses a election for election round JSON string, constructing the election for election round object
 * @param electionData - The election for client json
 * @returns A election for election round object.
 */
const parseJsonToElectionsForElectionRound = (
  electionData: IElectionSourceDataForElectionRound[]
): IElectionsForElectionRound[] => {
  const electionList = electionData.map((election) => {
    return {
      investmentVehicle: election.investmentVehicle,
      mdmInvestmentVehicleId: election.mdmInvestmentVehicleId,
      currentStage: election.currentStage,
      reopenedDates: election.reopenedDates,
      submissionDateTimes: election.submissionDateTimes,
    } as IElectionsForElectionRound;
  });
  return electionList;
};

/**
 * Parses a election submission response JSON string, constructing the election submission response object
 * @param electionSubmission - The election submission response
 * @returns A election submission reponse object.
 */
export const parseJsonToSubmissionResponse = (
  electionSubmission: ISubmissionResponseSource
): ISubmissionResponse => {
  const currentStage = electionSubmission.savedWorkflowStateResponse
    .currentStage as ElectionWorkflowStageId;
  const overviewStage = convertOverviewStateFromSource(
    electionSubmission.savedWorkflowStateResponse.electionStages.overview
  );
  const eligibilityStage = convertEligibilityStateFromSource(
    electionSubmission.savedWorkflowStateResponse.electionStages.eligibility
  );
  const electStage = electionConfigFromSource(
    electionSubmission.savedWorkflowStateResponse.electionStages.elect
  );
  const bankAccountStage = convertBankAccountStateFromSource(
    electionSubmission.savedWorkflowStateResponse.electionStages.bankAccount
  );
  const reviewAndSignStage = convertReviewAndSignStateFromSource(
    electionSubmission.savedWorkflowStateResponse.electionStages.reviewAndSign
  );

  const savedWorkflowStateResponse: IElectionWorkflowState = {
    ...electionSubmission.savedWorkflowStateResponse,
    currentStage,
    electionStages: {
      overview: overviewStage,
      eligibility: eligibilityStage,
      elect: electStage,
      bankAccount: bankAccountStage,
      reviewAndSign: reviewAndSignStage,
    },
  };

  const electionRoundConfiguration = parseJsonToElectionRoundConfig(
    electionSubmission.electionRoundConfiguration
  );

  const electionIVConfiguration = parseJsonToElectionIVConfiguration(
    electionSubmission.electionIVConfiguration
  );

  return {
    ...electionSubmission,
    savedWorkflowStateResponse,
    electionRoundConfiguration,
    electionIVConfiguration,
  } as ISubmissionResponse;
};

export function* fetchElectionConfiguration(
  action: PayloadAction<IGetElectionDetailsRequestPayload>
) {
  try {
    const electionRoundConfigurationSource: IElectionRoundConfigurationSource =
      yield call(
        getElectionRoundConfigurationData,
        action.payload.electionRoundId
      );

    if (electionRoundConfigurationSource === undefined) {
      yield put(recvElectionRoundConfigurationData(nothing));
    } else {
      yield put(
        recvElectionRoundConfigurationData(
          some(parseJsonToElectionRoundConfig(electionRoundConfigurationSource))
        )
      );
    }
  } catch {
    yield put(errElectionRoundConfigurationData(DataLoadStatus.UNSUCCESSFUL));
  }
}

export function* fetchElectionIVConfiguration(
  action: PayloadAction<IGetElectionDetailsRequestPayload>
) {
  try {
    const electionIVConfigSource: Maybe<IElectionIVConfigurationSource> =
      yield call(
        getElectionIVConfiguration,
        action.payload.electionRoundId,
        action.payload.investmentVehicleId
      );

    if (electionIVConfigSource === undefined) {
      yield put(recvElectionIVConfiguration(nothing));
    } else {
      yield put(
        recvElectionIVConfiguration(
          some(parseJsonToElectionIVConfiguration(electionIVConfigSource))
        )
      );
    }
  } catch {
    yield put(errElectionIVConfiguration(DataLoadStatus.UNSUCCESSFUL));
  }
}

export const convertFundData = (
  fundData: Json<IFundUnrealizedValue>[]
): IFundUnrealizedValue[] => {
  return fundData
    .map((fundUnrealizedValue: Json<IFundUnrealizedValue>) => {
      return {
        fund: {
          fundID: fundUnrealizedValue.fund.fundID,
          fundName: fundUnrealizedValue.fund.fundName,
          fundShortName: fundUnrealizedValue.fund.fundShortName,
          mDMFundID: fundUnrealizedValue.fund.mDMFundID,
          businessID: fundUnrealizedValue.fund.businessID,
        },
        unrealizedValue: {
          asOfDate: new Date(),
          optionalAndMandatoryInvestments:
            fundUnrealizedValue.unrealizedValue.optionalAndMandatoryInvestments,
          remainingCapitalInvested:
            fundUnrealizedValue.unrealizedValue.remainingCapitalInvested,
          gainLoss: fundUnrealizedValue.unrealizedValue.gainLoss,
          carriedInterest: fundUnrealizedValue.unrealizedValue.carriedInterest,
          total: fundUnrealizedValue.unrealizedValue.total,
        },
      };
    })
    .filter((datum: IFundUnrealizedValue) => {
      // filter out funds with no unrealized value
      return (
        datum.unrealizedValue.carriedInterest !== 0 ||
        datum.unrealizedValue.gainLoss !== 0 ||
        datum.unrealizedValue.remainingCapitalInvested !== 0 ||
        datum.unrealizedValue.total !== 0
      );
    });
};

export function* fetchInvestmentPortfolio(action: PayloadAction<ClientId>) {
  try {
    const investmentPortfolioSource: Maybe<IInvestmentPortfolioSource> =
      yield call(getInvestmentPortfolio, action.payload);

    if (investmentPortfolioSource === undefined) {
      yield put(recvElectionsInvestmentPortfolio(nothing));
    } else {
      const convertedInvestmentPortfolio =
        convertToElectionsInvestmentPortfolio(investmentPortfolioSource);
      yield put(
        recvElectionsInvestmentPortfolio(some(convertedInvestmentPortfolio))
      );
    }
  } catch {
    yield put(errElectionsInvestmentPortfolio(DataLoadStatus.UNSUCCESSFUL));
  }
}

const convertToElectionsInvestmentPortfolio = (
  investmentPortfolioSource: Json<IElectionInvestmentPortfolio>
) => {
  const unrealizedValues: Optional<UnrealizedValues> =
    investmentPortfolioSource.unrealizedValuesData === undefined ||
    investmentPortfolioSource.unrealizedValuesData === null
      ? nothing
      : some(
          convertUnrealizedValues(
            investmentPortfolioSource.unrealizedValuesData
          )
        );
  const priorElectionsData: Optional<PriorElections> =
    investmentPortfolioSource.priorElectionsData === undefined ||
    investmentPortfolioSource.priorElectionsData === null
      ? nothing
      : some(
          convertPriorElections(investmentPortfolioSource.priorElectionsData)
        );

  const investmentPortfolio: IElectionInvestmentPortfolio = {
    unrealizedValuesData: unrealizedValues,
    priorElectionsData: priorElectionsData,
  };

  return investmentPortfolio;
};

const convertUnrealizedValues = (
  unrealizedValuesSource: Json<UnrealizedValues>
): UnrealizedValues => {
  return {
    asOfDate: convertToDateObject(unrealizedValuesSource.asOfDate),
    unrealizedValues: unrealizedValuesSource.unrealizedValues
      .map((unrealizedValueSource) => ({
        ...unrealizedValueSource,
        endDate: convertToDateObject(unrealizedValueSource.endDate),
        fundData: convertFundData(unrealizedValueSource.fundData),
      }))
      .filter((datum: UnrealizedValue) => {
        // filter out strateges with no fund data
        return datum.fundData.length > 0;
      }),
  };
};

const convertPriorElections = (
  priorElectionsSource: Json<PriorElections>
): PriorElections => {
  return {
    priorElections:
      priorElectionsSource.priorElections.sort((a, b) =>
        a.strategyName.localeCompare(b.strategyName)
      ) || [],
    asOfDate: convertToDateObject(priorElectionsSource.asOfDate),
  };
};

export function* fetchElectionsForClient(
  action: PayloadAction<IPermittedClient>
) {
  try {
    const electionResponse: Maybe<IElectionSourceDataForClient[]> = yield call(
      getElectionsForClient,
      action.payload.clientId
    );

    if (electionResponse == undefined) {
      yield put(recvElectionsForClient(nothing));
    } else {
      const convertedElectionForClientData =
        parseJsonToElectionsForClient(electionResponse);
      yield put(recvElectionsForClient(some(convertedElectionForClientData)));
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errElectionsForClient(DataLoadStatus.UNSUCCESSFUL));
    }
  }
}

export function* fetchElectionsForElectionRound(
  action: PayloadAction<IElectionRound>
) {
  try {
    const electionResponse: Maybe<IElectionSourceDataForElectionRound[]> =
      yield call(getElectionsForElectionRound, action.payload.electionRoundId);
    if (electionResponse == undefined) {
      yield put(recvElectionsForElectionRound(nothing));
    } else {
      const convertedElectionForElectionRoundData =
        parseJsonToElectionsForElectionRound(electionResponse);
      yield put(
        recvElectionsForElectionRound(
          some(convertedElectionForElectionRoundData)
        )
      );
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errElectionsForElectionRound(DataLoadStatus.UNSUCCESSFUL));
    }
  }
}

export const convertElectionWorkflowStateToUpdateSource = (
  electionWorkflowState: IElectionWorkflowState,
  resetElectionWorkflow = false
): IUpdateWorkflowStateSource => {
  return {
    electionRoundId: electionWorkflowState.electionRoundId,
    investmentVehicleId: electionWorkflowState.investmentVehicleId,
    version: electionWorkflowState.version,
    electionRoundConfigurationVersion:
      electionWorkflowState.electionRoundConfigurationVersion,
    ivConfigurationVersion: electionWorkflowState.ivConfigurationVersion,
    targetStage: resetElectionWorkflow
      ? ElectionWorkflowStageId.OVERVIEW
      : electionWorkflowState.currentStage,
    electionStages: convertElectionStagesToSource(
      electionWorkflowState.electionStages,
      resetElectionWorkflow
    ),
  };
};

const convertElectionWorkflowStateSource = (
  electionWorkflowState: IElectionWorkflowStateSource
): IElectionWorkflowState => {
  return {
    ...electionWorkflowState,
    electionStages: convertElectionStagesFromSource(
      electionWorkflowState.electionStages
    ),
  };
};

export function* writeElectionWorkflowState(
  action: PayloadAction<IWriteElectionWorkflowStatePayload>
) {
  try {
    const electionWorkflowStateSource: Maybe<IElectionWorkflowStateSource> =
      yield call(
        putElectionWorkflowState,
        action.payload.electionRoundId,
        action.payload.investmentVehicleId,
        action.payload.isAdmin,
        action.payload.targetState
      );

    if (electionWorkflowStateSource === undefined) {
      yield put(recvLocalElectionWorkflowState(nothing));
      yield put(recvApiElectionWorkflowState(nothing));
      yield put(recvElectionWorkflowStateUpdate(nothing));
    } else {
      const electionWorkflowState = convertElectionWorkflowStateSource(
        electionWorkflowStateSource
      );

      yield put(recvLocalElectionWorkflowState(some(electionWorkflowState)));
      yield put(recvApiElectionWorkflowState(some(electionWorkflowState)));
      yield put(recvElectionWorkflowStateUpdate(some(electionWorkflowState)));
    }
  } catch {
    yield put(errElectionWorkflowStateUpdate(DataLoadStatus.UNSUCCESSFUL));
  }
}

export function* fetchElectionWorkflowState(
  action: PayloadAction<IGetElectionDetailsRequestPayload>
) {
  try {
    const electionWorkflowStateSource: Maybe<IElectionWorkflowStateSource> =
      yield call(
        getElectionWorkflowState,
        action.payload.electionRoundId,
        action.payload.investmentVehicleId,
        action.payload.isAdmin
      );
    const electionRoundSource: Maybe<IElectionRoundConfigurationSource> =
      yield call(
        getElectionRoundConfigurationData,
        action.payload.electionRoundId
      );
    const electionIVConfigSource: Maybe<IElectionIVConfigurationSource> =
      yield call(
        getElectionIVConfiguration,
        action.payload.electionRoundId,
        action.payload.investmentVehicleId
      );

    if (electionWorkflowStateSource === undefined) {
      yield put(recvLocalElectionWorkflowState(nothing));
      yield put(recvApiElectionWorkflowState(nothing));
    } else {
      const notStarted =
        electionWorkflowStateSource.currentStage <
        ElectionWorkflowStageId.OVERVIEW;

      const electionWorkflowState = convertElectionWorkflowStateSource(
        electionWorkflowStateSource
      );

      if (
        notStarted &&
        electionRoundSource !== undefined &&
        electionIVConfigSource !== undefined
      ) {
        yield put(
          reqPutElectionWorkflowState({
            investmentVehicleId: electionWorkflowState.investmentVehicleId,
            electionRoundId: electionWorkflowState.electionRoundId,
            targetState: convertElectionWorkflowStateToUpdateSource({
              ...electionWorkflowState,
              electionRoundConfigurationVersion: electionRoundSource.version,
              ivConfigurationVersion: electionIVConfigSource.version,
              currentStage: ElectionWorkflowStageId.OVERVIEW,
            }),
            isAdmin: action.payload.isAdmin,
          })
        );
      } else {
        yield put(recvLocalElectionWorkflowState(some(electionWorkflowState)));
        yield put(recvApiElectionWorkflowState(some(electionWorkflowState)));
      }
    }
  } catch (e) {
    yield put(errElectionWorkflowState(DataLoadStatus.UNSUCCESSFUL));
  }
}

export function* fetchClientMappings() {
  try {
    const clientMappings: Maybe<IElectionClientMapping[]> = yield call(
      getClientMappings
    );

    if (clientMappings === undefined || clientMappings.length === 0) {
      yield put(recvElectionClientMappings(nothing));
    } else {
      const convertClientMapping = (
        clientMapping: Json<IElectionClientMapping>
      ): IElectionClientMapping => ({
        axiomClientId: clientMapping.axiomClientId,
        axiomInvestmentVehicleId: clientMapping.axiomInvestmentVehicleId,
        clientName: clientMapping.clientName,
        clientShortName: clientMapping.clientShortName,
        investmentVehicleName: clientMapping.investmentVehicleName,
        investmentVehicleShortName: clientMapping.investmentVehicleShortName,
        mdmClientId: clientMapping.mdmClientId,
        mdmInvestmentVehicleId: clientMapping.mdmInvestmentVehicleId,
      });
      yield put(
        recvElectionClientMappings(
          some(clientMappings.map(convertClientMapping))
        )
      );
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errElectionClientMappings(DataLoadStatus.UNSUCCESSFUL));
    }
  }
}
