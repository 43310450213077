import { useMediaQuery } from "@mui/material";
import {
  BreakpointConstants,
  DataCard,
  EquityLabel,
  EquityTooltip,
  getCurrencyFormattedTwoDPValueWithZeroDefault,
  getCurrencyFormattedValue,
  getCurrencyFormattedValueWithZeroDefault,
  IBaseStore,
  IDistributionsDatum,
  isEmptyResponse,
  isInProgress,
  isSomething,
  IStockDataValue,
  isUnsuccessful,
  reqDistributionsData,
  reqEquityData,
  reqStockData,
  stockPriceTooltip,
  useFetchDatasetIfIdDefined,
  useFetchDatasetWithoutId,
} from "common";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { unitTypes } from "../../../../constants/enums";
import {
  getSingleCardData as getSingleCardData,
  showVestedExchangeable,
} from "../../../../utils/calloutUtils";
import {
  formatDateMMMDDYYYYOrNull,
  get2DPNumberFormattedWithZeroDefault,
} from "../../../../utils/formatters";
import { Callout } from "./Callout/Callout";
import { RestrictedEquityCallout } from "./Callout/RestrictedEquityCallout";
import { StockCallout } from "./Callout/StockCallout";
import styles from "./Callouts.module.scss";

interface ICalloutsProps {
  hasViewRestrictedEquity?: boolean;
}

export const Callouts = (props: ICalloutsProps) => {
  const { hasViewRestrictedEquity } = props;

  const {
    activeEquityDataId,
    distributionsData,
    distributionsDataLoadStatus,
    equityData,
    equityDataLoadStatus,
    stockData,
    stockDataLoadStatus,
  } = useSelector((store: IBaseStore) => store.equityData);

  const { selectedStockSymbol } = useSelector(
    (store: IBaseStore) => store.viewData
  );

  const isMobile = useMediaQuery(
    `(max-width:${BreakpointConstants.EXTRA_SMALL_MAX_WIDTH}px)`
  );

  const distributionAmount = useMemo(() => {
    const relevantData = distributionsData[selectedStockSymbol];
    if (isSomething(relevantData)) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const yearStart = new Date(currentDate.getFullYear(), 0, 1);

      return relevantData.value
        .filter(
          (data: IDistributionsDatum) =>
            data.distributionDate >= yearStart &&
            data.distributionDate <= currentDate
        )
        .reduce(
          (total: number, data: IDistributionsDatum) =>
            total + data.dividendAmount,
          0
        );
    }
    return 0;
  }, [distributionsData, selectedStockSymbol]);

  const activeEquityData = useMemo(() => {
    return equityData[selectedStockSymbol];
  }, [equityData, selectedStockSymbol]);

  const latestStockPrice: IStockDataValue = useMemo(() => {
    const selectedStockInfo = stockData[selectedStockSymbol];
    if (isSomething(selectedStockInfo)) {
      return selectedStockInfo.value[0];
    }
    return {
      asOfDate: new Date(),
      value: 0,
    };
  }, [stockData, selectedStockSymbol]);

  const stockTrendLine: IStockDataValue[] = useMemo(() => {
    const selectedStockInfo = stockData[selectedStockSymbol];
    if (isSomething(selectedStockInfo)) {
      const asOfDateRange: Date[] = [
        ...new Set(
          selectedStockInfo.value.map((datum) =>
            new Date(datum.asOfDate).setHours(0, 0, 0, 0)
          )
        ),
      ]
        .slice(0, 5)
        .map((datum) => new Date(datum));

      //get hourly range from fetched dates to filter trend data upon.
      //takes the latest that we have for a given hour.
      const hourlyDate: Date[] = [
        ...new Set(
          selectedStockInfo.value.map((datum) =>
            new Date(datum.asOfDate).setMinutes(0, 0, 0)
          )
        ),
      ].map((datum) => new Date(datum));

      let hourlyDateRange: Date[] = [];
      const stockTrend: IStockDataValue[] = [];
      asOfDateRange?.forEach((dateKey) => {
        const d: Date[] = hourlyDate?.filter(
          (datum) =>
            new Date(new Date(datum).setHours(0, 0, 0, 0)).toUTCString() ===
            dateKey.toUTCString()
        );
        if (d !== undefined) {
          hourlyDateRange = hourlyDateRange.concat(d);
        }
      });

      //get stock trend data for the given range
      hourlyDateRange.forEach((dateKey) => {
        const dt: IStockDataValue = selectedStockInfo.value.filter(
          (datum) =>
            new Date(
              new Date(datum.asOfDate).setMinutes(0, 0, 0)
            ).toUTCString() === dateKey.toUTCString()
        )[0];
        if (dt != undefined) {
          stockTrend.push(dt);
        }
      });
      return stockTrend.sort(
        (a, b) => a.asOfDate.valueOf() - b.asOfDate.valueOf()
      );
    }
    return [];
  }, [stockData, selectedStockSymbol]);

  const equityRetryFunction = useFetchDatasetIfIdDefined(
    reqEquityData,
    activeEquityDataId,
    equityDataLoadStatus
  );

  const distributionRetryFunction = useFetchDatasetIfIdDefined(
    reqDistributionsData,
    activeEquityDataId,
    distributionsDataLoadStatus
  );

  const stockRetryFunction = useFetchDatasetWithoutId(
    reqStockData,
    stockDataLoadStatus
  );

  const retryFunctions = [
    equityRetryFunction,
    distributionRetryFunction,
    stockRetryFunction,
  ];

  const getRestrictedEquityTotal = () => {
    if (!isSomething(activeEquityData)) {
      return 0;
    }

    const {
      totalUnvestedUnits,
      vestedRestrictedUnits,
      vestedExchangeableUnits,
    } = activeEquityData.value;

    const restrictedUnits = isSomething(vestedRestrictedUnits)
      ? vestedRestrictedUnits.value
      : 0;
    const exchangeableUnits = isSomething(vestedExchangeableUnits)
      ? vestedExchangeableUnits.value
      : 0;

    return totalUnvestedUnits + restrictedUnits + exchangeableUnits;
  };

  const noSubValuesProps = getSingleCardData(
    hasViewRestrictedEquity,
    activeEquityData
  );

  const vestedRestrictedTotal = isSomething(activeEquityData)
    ? isSomething(activeEquityData.value.vestedRestrictedUnits)
      ? activeEquityData.value.vestedRestrictedUnits.value
      : 0
    : 0;

  const vestedExchangeableTotal = isSomething(activeEquityData)
    ? isSomething(activeEquityData.value.vestedExchangeableUnits)
      ? activeEquityData.value.vestedExchangeableUnits.value
      : 0
    : 0;

  const unvestedAsOfDate = isSomething(activeEquityData)
    ? ` ${EquityTooltip.AS_OF} ${formatDateMMMDDYYYYOrNull(
        activeEquityData.value.asOfDate
      )}`
    : "";

  const restrictedAndExchangeableAsOfDate =
    isSomething(activeEquityData) &&
    isSomething(activeEquityData.value.vestedAndExchangeableAsOfDate)
      ? ` ${EquityTooltip.AS_OF} ${formatDateMMMDDYYYYOrNull(
          activeEquityData.value.vestedAndExchangeableAsOfDate.value
        )}`
      : "";

  const createTooltipContent = (tooltipArray: string[]): React.ReactNode => {
    return (
      <>
        {tooltipArray.map((text, index) => (
          <div key={index}>{text}</div>
        ))}
      </>
    );
  };

  return (
    <div id={styles.callouts}>
      {hasViewRestrictedEquity && noSubValuesProps.hasSubValue ? (
        <>
          <DataCard
            className={styles.card}
            id={
              showVestedExchangeable(activeEquityData)
                ? styles.restrictedEquityCard
                : styles.restrictedEquitySmallCard
            }
            failed={isUnsuccessful(equityDataLoadStatus, stockDataLoadStatus)}
            loading={isInProgress(equityDataLoadStatus, stockDataLoadStatus)}
            noData={isEmptyResponse(equityDataLoadStatus, stockDataLoadStatus)}
            onReload={retryFunctions}
          >
            {isSomething(activeEquityData) && (
              <RestrictedEquityCallout
                total={getCurrencyFormattedValueWithZeroDefault(
                  getRestrictedEquityTotal() * latestStockPrice.value
                )}
                unvestedTotal={getCurrencyFormattedValueWithZeroDefault(
                  activeEquityData.value.totalUnvestedUnits *
                    latestStockPrice.value
                )}
                unvestedTooltipsParagraphs={createTooltipContent(
                  EquityTooltip.UNVESTED_VALUE_TOOLTIP
                )}
                vestedRestrictedTotal={getCurrencyFormattedValueWithZeroDefault(
                  vestedRestrictedTotal * latestStockPrice.value
                )}
                vestedRestrictedTooltipsParagraphs={createTooltipContent(
                  EquityTooltip.VESTED_RESTRICTED_VALUE_TOOLTIP
                )}
                vestedExchangeableTotal={getCurrencyFormattedValueWithZeroDefault(
                  vestedExchangeableTotal * latestStockPrice.value
                )}
                vestedExchangeableTooltipsParagraphs={createTooltipContent(
                  EquityTooltip.VESTED_EXCHANGEABLE_VALUE_TOOLTIP
                )}
                showTooltip={true}
                unit={unitTypes.CURRENCY}
                showVestedExchangeable={showVestedExchangeable(
                  activeEquityData
                )}
              />
            )}
          </DataCard>
          <DataCard
            className={styles.card}
            id={
              showVestedExchangeable(activeEquityData)
                ? styles.restrictedEquityCard
                : styles.restrictedEquitySmallCard
            }
            failed={isUnsuccessful(equityDataLoadStatus, stockDataLoadStatus)}
            loading={isInProgress(equityDataLoadStatus, stockDataLoadStatus)}
            noData={isEmptyResponse(equityDataLoadStatus, stockDataLoadStatus)}
            onReload={retryFunctions}
          >
            {isSomething(activeEquityData) && (
              <RestrictedEquityCallout
                total={get2DPNumberFormattedWithZeroDefault(
                  getRestrictedEquityTotal()
                )}
                unvestedTotal={get2DPNumberFormattedWithZeroDefault(
                  activeEquityData.value.totalUnvestedUnits
                )}
                unvestedTooltipsParagraphs={[unvestedAsOfDate]}
                vestedRestrictedTotal={get2DPNumberFormattedWithZeroDefault(
                  vestedRestrictedTotal
                )}
                vestedRestrictedTooltipsParagraphs={[
                  restrictedAndExchangeableAsOfDate,
                ]}
                vestedExchangeableTotal={get2DPNumberFormattedWithZeroDefault(
                  vestedExchangeableTotal
                )}
                vestedExchangeableTooltipsParagraphs={[
                  restrictedAndExchangeableAsOfDate,
                ]}
                showTooltip={true}
                unit={unitTypes.UNIT}
                showVestedExchangeable={showVestedExchangeable(
                  activeEquityData
                )}
              />
            )}
          </DataCard>
        </>
      ) : (
        <>
          <DataCard
            className={styles.card}
            failed={isUnsuccessful(equityDataLoadStatus, stockDataLoadStatus)}
            loading={isInProgress(equityDataLoadStatus, stockDataLoadStatus)}
            noData={isEmptyResponse(equityDataLoadStatus, stockDataLoadStatus)}
            onReload={retryFunctions}
          >
            {isSomething(activeEquityData) && (
              <Callout
                label={noSubValuesProps.valueLabel}
                value={getCurrencyFormattedValueWithZeroDefault(
                  noSubValuesProps.value * latestStockPrice.value
                )}
                showHeaderTooltip={true}
                headerTooltipParagraphs={[noSubValuesProps.valuetooltips]}
              />
            )}
          </DataCard>
          <DataCard
            className={styles.card}
            failed={isUnsuccessful(equityDataLoadStatus)}
            loading={isInProgress(equityDataLoadStatus)}
            noData={isEmptyResponse(equityDataLoadStatus)}
            onReload={retryFunctions}
          >
            {isSomething(activeEquityData) && (
              <Callout
                label={noSubValuesProps.unitLabel}
                value={noSubValuesProps.value.toLocaleString("en-US")}
              />
            )}
          </DataCard>
        </>
      )}
      <DataCard
        className={styles.card}
        id={styles.stockCard}
        failed={isUnsuccessful(stockDataLoadStatus)}
        loading={isInProgress(stockDataLoadStatus)}
        noData={isEmptyResponse(stockDataLoadStatus)}
        onReload={retryFunctions}
      >
        <StockCallout
          label={`${selectedStockSymbol} ${EquityLabel.STOCK_PRICE}`}
          headerTooltipParagraphs={stockPriceTooltip(latestStockPrice.asOfDate)}
          value={getCurrencyFormattedTwoDPValueWithZeroDefault(
            latestStockPrice.value
          )}
          showHeaderTooltip={true}
          showChart={!isMobile}
          chartData={stockTrendLine}
        />
      </DataCard>
      <DataCard
        className={styles.card}
        failed={isUnsuccessful(equityDataLoadStatus, stockDataLoadStatus)}
        loading={isInProgress(equityDataLoadStatus, stockDataLoadStatus)}
        noData={isEmptyResponse(equityDataLoadStatus, stockDataLoadStatus)}
        onReload={retryFunctions}
      >
        {isSomething(activeEquityData) && (
          <Callout
            label={EquityLabel.NEXT_VESTING_DATE}
            value={formatDateMMMDDYYYYOrNull(
              activeEquityData.value.nextVestingDate
            )}
          />
        )}
      </DataCard>
      <DataCard
        className={styles.card}
        failed={isUnsuccessful(distributionsDataLoadStatus)}
        loading={isInProgress(distributionsDataLoadStatus)}
        onReload={retryFunctions}
      >
        <Callout
          label={EquityLabel.YTD_DISTRIBUTIONS}
          value={getCurrencyFormattedValue(distributionAmount)}
          showHeaderTooltip={true}
          headerTooltipParagraphs={[EquityTooltip.YTD_DISTRIBUTIONS]}
        />
      </DataCard>
    </div>
  );
};
