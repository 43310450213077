import { Stack, Typography } from "@mui/material";
import React from "react";

import { FooterConstants } from "../../constants/FooterConstants";
import styles from "./Footer.module.scss";

const footerLinks = [
  {
    label: FooterConstants.FEEDBACK,
    href: FooterConstants.FEEDBACK_HREF,
  },
  {
    label: FooterConstants.TWITTER,
    href: FooterConstants.TWITTER_LINK,
  },
  {
    label: FooterConstants.LINKEDIN,
    href: FooterConstants.LINKEDIN_HREF,
  },
  {
    label: FooterConstants.PRIVACY,
    href: FooterConstants.PRIVACY_HREF,
  },
  {
    label: FooterConstants.COOKIES,
    href: FooterConstants.COOKIES_HREF,
  },
  {
    label: FooterConstants.PHISHING,
    href: FooterConstants.PHISHING_HREF,
  },
];

export const Footer = () => {
  return (
    <footer>
      <Stack
        direction="row"
        divider={
          <Typography className={styles.divider} variant="body1">
            |
          </Typography>
        }
        spacing={1}
        className={styles.linkBar}
      >
        {footerLinks.map((fl, key) => (
          <a key={key} href={fl.href} target="_blank" rel="noreferrer">
            <Typography variant="body1">{fl.label}</Typography>
          </a>
        ))}
      </Stack>
    </footer>
  );
};
