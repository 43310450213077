import { ArrowBackIosNew } from "@mui/icons-material";
import { Breadcrumbs } from "@mui/material";
import {
  convertElectionWorkflowStateToUpdateSource,
  ElectionsLabels,
  ElectionWorkflowStageId,
  isSomething,
  reqClearActiveElection,
  reqPutElectionWorkflowState,
  selectCurrentStageId,
} from "common";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { IBaseStore } from "../../../../redux/store";
import { UnsavedChangesModal } from "../../ElectionWorkflow/Shared/UnsavedChangesModal/UnsavedChangesModal";
import styles from "./BackToElectionsButton.module.scss";

export interface IBackToElectionsButtonProps {
  checkForUnsavedChanges?: boolean;
  label?: string;
  backUrl: string;
}

export const BackToElectionsButton = (props: IBackToElectionsButtonProps) => {
  const {
    checkForUnsavedChanges = false,
    label = ElectionsLabels.BACK_TO_ELECTIONS,
    backUrl,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState<boolean>(false);

  // keeps track of the user's current stage
  const {
    electionWorkflowStateLocal,
    submissionRequested,
    electionRoundConfiguration,
    electionIVConfiguration,
  } = useSelector((state: IBaseStore) => state.selectedElection);

  const { isSBSElectionSaveEnabled } = useSelector(
    (state: IBaseStore) => state.viewData
  );

  const { unsubmittedChanges } = useSelector(
    (store: IBaseStore) => store.bankAccounts
  );

  const hasUnsubmittedChangesBankAccounts =
    Object.values(unsubmittedChanges).some(Boolean);

  // keeps track of the user's current stage
  const currentStageId = useSelector((state: IBaseStore) =>
    selectCurrentStageId(state)
  );

  const activeStageId = useMemo(() => {
    if (
      currentStageId === ElectionWorkflowStageId.COMPLETED &&
      submissionRequested
    ) {
      return ElectionWorkflowStageId.REVIEW_AND_SIGN;
    }
    return currentStageId;
  }, [currentStageId, submissionRequested]);

  const navigateBackToElections = useCallback(() => {
    dispatch(reqClearActiveElection());
    navigate(backUrl);
  }, [dispatch, navigate, backUrl]);

  const handleGo = useCallback(
    (persistLocalState: boolean) => {
      if (
        persistLocalState &&
        isSomething(electionWorkflowStateLocal) &&
        isSomething(electionRoundConfiguration) &&
        isSomething(electionIVConfiguration)
      ) {
        dispatch(
          reqPutElectionWorkflowState({
            ...electionWorkflowStateLocal.value,
            targetState: convertElectionWorkflowStateToUpdateSource({
              ...electionWorkflowStateLocal.value,
              electionRoundConfigurationVersion:
                electionRoundConfiguration.value.version,
              ivConfigurationVersion: electionIVConfiguration.value.version,
            }),
          })
        );
      }
      navigateBackToElections();
      window.scrollTo(0, 0);
    },
    [
      electionWorkflowStateLocal,
      electionRoundConfiguration,
      electionIVConfiguration,
      navigateBackToElections,
      dispatch,
    ]
  );

  const handleClickBackToElections = () => {
    if (
      (checkForUnsavedChanges && isSBSElectionSaveEnabled) ||
      hasUnsubmittedChangesBankAccounts
    ) {
      setIsUnsavedChangesModalOpen(true);
    } else {
      navigateBackToElections();
    }
  };

  const handleAcceptUnsavedChangesModal = () => {
    switch (currentStageId) {
      case ElectionWorkflowStageId.BANK_ACCOUNT:
        handleGo(false);
        break;
      default:
        handleGo(true);
        break;
    }
  };

  const handleDeclineUnsavedChangesModal = () => {
    switch (currentStageId) {
      case ElectionWorkflowStageId.BANK_ACCOUNT:
        return;
      default:
        handleGo(false);
        break;
    }
  };

  return (
    <>
      <Breadcrumbs>
        <div className={styles.breadcrumb} onClick={handleClickBackToElections}>
          <div className={styles.arrow}>
            <ArrowBackIosNew />
          </div>
          {label}
        </div>
      </Breadcrumbs>
      <UnsavedChangesModal
        activeStageId={activeStageId}
        open={isUnsavedChangesModalOpen}
        setOpen={setIsUnsavedChangesModalOpen}
        handleGoWithoutSave={handleDeclineUnsavedChangesModal}
        handleGoWithSave={handleAcceptUnsavedChangesModal}
      />
    </>
  );
};
