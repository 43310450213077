import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";

import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator";
import { DataLoadStatus } from "../../../../constants/enums";
import { SummaryLabel } from "../../../../constants/LabelAndTooltipConstants";
import { StringConstants } from "../../../../constants/StringConstants";
import { selectCallout } from "../../../../redux/selectors";
import {
  SelectRestrictedEquityTotal,
  selectUnvestedEquityTotal,
} from "../../../../redux/selectors/equitySelectors";
import { EnvironmentResolver } from "../../../../services/environmentResolver";
import { getCalloutBannerData } from "../../../../utils/calloutBannerUtils";
import { getCurrencyFormattedValue } from "../../../../utils/formatters";
import styles from "./CalloutBanner.module.scss";

interface ICalloutDataPoint {
  label: string;
  value: number;
}

interface ICalloutBannerProps {
  internalInvestmentDataLoadStatus: DataLoadStatus;
  hasViewStockEntitlement: boolean;
  hasViewRestrictedEquity: boolean;
  showUserWelcome?: boolean;
}

export const CalloutBanner = (props: ICalloutBannerProps) => {
  // components renders only if user is already authenticated
  const {
    internalInvestmentDataLoadStatus,
    hasViewStockEntitlement,
    hasViewRestrictedEquity,
    showUserWelcome = true,
  } = props;
  const [profileImageSource, setProfileImageSource] = useState<string | null>(
    null
  );

  const user = useAuth().user;
  const firstName = user?.profile.firstName ?? "";
  const lastName = user?.profile.lastName ?? "";

  const calloutData = useSelector(selectCallout).calloutData;
  const restrictedEquity = useSelector(SelectRestrictedEquityTotal);
  const unvestedEquity = useSelector(selectUnvestedEquityTotal);

  const equity = hasViewRestrictedEquity ? restrictedEquity : unvestedEquity;

  const { callouts, showLoading, total } = getCalloutBannerData(
    calloutData,
    internalInvestmentDataLoadStatus,
    hasViewStockEntitlement,
    equity
  );

  const Callouts = () => {
    return showLoading ? (
      <LoadingIndicator className={styles.loading} />
    ) : (
      <div
        className={`${styles.callouts} ${
          !showUserWelcome ? styles.noUser : ""
        }`}
      >
        <div
          className={`${styles.total} ${!showUserWelcome ? styles.noUser : ""}`}
        >
          <div className={styles.label}>{SummaryLabel.TOTAL}</div>
          <div className={styles.value}>{getCurrencyFormattedValue(total)}</div>
        </div>
        <div className={styles.breakdown}>
          {callouts.map((callout: ICalloutDataPoint, idx) => {
            return (
              <div className={styles.callout} key={idx}>
                <div className={styles.data}>
                  <div className={styles.label}>{callout.label}</div>
                  <div className={styles.value}>
                    {getCurrencyFormattedValue(callout.value)}
                  </div>
                </div>
                <div className={styles.separator}></div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  // We only want to render a space for a profile picture if we are sure the user has one.
  // This code checks if an image can be succesfully retrieved, and if so, sets the source
  // to use for the actual image so we can conditionally render the photo.
  useEffect(() => {
    const image = new Image();
    image.src = `${EnvironmentResolver.ENV.REACT_APP_PROFILE_IMAGES_URL_BASE}/${user?.profile.employeeNumber}`;

    if (image.complete) {
      setProfileImageSource(image.src);
    } else {
      image.onload = () => setProfileImageSource(image.src);
    }
  }, [user]);

  return (
    <div className={styles.header}>
      <div
        className={`${styles.headerContent} ${
          !showUserWelcome ? styles.noUser : ""
        }`}
      >
        {showUserWelcome && (
          <div className={styles.welcomeMessage}>
            {profileImageSource !== null && (
              <div>
                <img src={profileImageSource} />
              </div>
            )}
            <div>
              <h3>{StringConstants.WELCOME}</h3>
              <h1>{`${firstName} ${lastName}`}</h1>
            </div>
          </div>
        )}
        <Callouts />
      </div>
    </div>
  );
};
