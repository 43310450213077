import {
  AuthenticatedPage,
  IInvestmentVehicleByClientByPeriod,
  isInProgress,
  LoadingIndicator,
  NoPermissionPage,
  PathFragment,
  PeriodHash,
  reqInternalInvestmentData,
  reqSelectedClient,
  reqSelectedEmployee,
  setActiveEquityDataId,
  useFetchEntitlements,
  userIsValid,
} from "common";
import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { PageName, Pages } from "../../../constants/Pages/Pages";
import { RelativePath } from "../../../constants/Paths";
import { Login } from "../../../features/Authentication/Login";
import { AdminUIStore } from "../../../redux/store";
import { findInternalInvestmentClientForClientId } from "../../../utils/adminClientUtils";
import { findEmployeeForId } from "../../../utils/adminEmployeeUtils";
import { isPageVisible } from "../../../utils/routingUtils";
import styles from "./RouterProvider.module.scss";

export const RouterProvider = () => {
  const auth = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = location;

  const {
    internalInvestmentAdminEntitlementLoadStatus,
    equityAdminEntitlementLoadStatus,
    hasInternalInvestmentAdminEntitlement,
    hasEquityAdminEntitlement,
  } = useSelector((state: AdminUIStore) => state.entitlements);

  const { internalInvestmentClients } = useSelector(
    (state: AdminUIStore) => state.clients
  );

  const { employees } = useSelector((state: AdminUIStore) => state.employees);

  useFetchEntitlements(auth.user, [
    internalInvestmentAdminEntitlementLoadStatus,
    equityAdminEntitlementLoadStatus,
  ]);

  // Extract the clientId from the pathname
  const clientIdOrEmployeeId = pathname.split("/")[2];
  const isClientPage = pathname.split("/")[1] === PathFragment.ADMIN_CLIENT;
  const isEmployeePage = pathname.split("/")[1] === PathFragment.ADMIN_EQUITY;

  useEffect(() => {
    // check if client Id in url is valid if not redirect to landing page
    if (isClientPage && clientIdOrEmployeeId) {
      const clientMetadata = findInternalInvestmentClientForClientId(
        clientIdOrEmployeeId,
        internalInvestmentClients
      );

      // check if clientID is valid
      if (clientMetadata) {
        const client: IInvestmentVehicleByClientByPeriod = {
          clientId: clientMetadata.id,
          investmentVehicleId: undefined,
          periodId: PeriodHash.LATEST,
        };

        dispatch(reqInternalInvestmentData(client));
        dispatch(reqSelectedClient(clientMetadata));
      } else {
        <Navigate to={RelativePath.CLIENT_DATA} />;
      }
    }

    // check if employee Id in url is valid if not redirect to equity landing page
    if (isEmployeePage && clientIdOrEmployeeId) {
      const employee = findEmployeeForId(clientIdOrEmployeeId, employees);

      // check if employee id exists in valid list of employees
      if (employee) {
        dispatch(reqSelectedEmployee(employee));
        dispatch(setActiveEquityDataId(employee.id));
      } else {
        <Navigate to={Pages.EquityLandingPage.path} />;
      }
    }

    if (isEmployeePage) {
      <Navigate to={Pages.EquityLandingPage.path} />;
    } else {
      <Navigate to={RelativePath.CLIENT_DATA} />;
    }
  }, [
    clientIdOrEmployeeId,
    dispatch,
    employees,
    hasEquityAdminEntitlement,
    hasInternalInvestmentAdminEntitlement,
    internalInvestmentClients,
    isClientPage,
    isEmployeePage,
  ]);

  const SetRouteByEntitlements = () => {
    // check if entitlements are still loading
    if (
      isInProgress(
        internalInvestmentAdminEntitlementLoadStatus,
        equityAdminEntitlementLoadStatus
      )
    ) {
      return (
        <div className={styles.loadingIndicator}>
          <LoadingIndicator />
        </div>
      );
    }
    const urlPath = location.pathname;
    const urlPathLower = urlPath.toLowerCase();
    if (
      urlPath !== urlPathLower &&
      Object.keys(Pages)
        .map((object) => Pages[object as PageName].path)
        .some((item) => item === urlPathLower)
    ) {
      /*
      if url string contains uppercase and corresponds to a valid page when made lowercase, navigate to the lowercase route.
        This forces all urls to automatically become lowercase, even if typed in capitals
      */
      return <Navigate to={urlPathLower} />;
    }

    // if entitlements are loaded route to either landing page or no permissions
    return isClientPage &&
      hasInternalInvestmentAdminEntitlement &&
      clientIdOrEmployeeId ? (
      <Navigate
        to={RelativePath.DASHBOARD.replace(":clientId", clientIdOrEmployeeId)}
      />
    ) : isClientPage && hasInternalInvestmentAdminEntitlement ? (
      <Navigate to={RelativePath.CLIENT_DATA} />
    ) : isEmployeePage && hasEquityAdminEntitlement && clientIdOrEmployeeId ? (
      <Navigate
        to={RelativePath.EQUITY.replace(":employeeId", clientIdOrEmployeeId)}
      />
    ) : isEmployeePage && hasEquityAdminEntitlement ? (
      <Navigate to={Pages.EquityLandingPage.path} />
    ) : hasInternalInvestmentAdminEntitlement ? (
      <Navigate to={RelativePath.CLIENT_DATA} />
    ) : (
      <NoPermissionPage />
    );
  };

  if (
    userIsValid(auth.user) &&
    isInProgress(internalInvestmentAdminEntitlementLoadStatus)
  ) {
    return (
      <div className={styles.loadingIndicator}>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <Routes>
      <Route path={RelativePath.LOGIN} element={<Login />} />
      {Object.keys(Pages).map((pageKey) => {
        if (isPageVisible(pageKey as PageName, hasEquityAdminEntitlement)) {
          const page = Pages[pageKey as PageName];
          return (
            <Route
              key={page.path}
              path={page.path}
              element={
                <div className={styles.page}>
                  <AuthenticatedPage
                    pageElement={page.element}
                    // currently hard-coded to 200 since we do not have a page load error in the admin UI
                    // TODO: separate page-load logic from Auth logic
                    pageLoadStatus={200}
                  />
                </div>
              }
            />
          );
        }
      })}
      <Route path="*" element={SetRouteByEntitlements()} />
    </Routes>
  );
};
