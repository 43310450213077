import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css";

import { Box, Card, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import {
  ColDef,
  ICellRendererParams,
  ITooltipParams,
  RowClassParams,
} from "ag-grid-community";
import {
  AgGridReact,
  CustomCellRendererProps,
  CustomTooltipProps,
} from "ag-grid-react";
import {
  AccountAssignmentRow,
  BankAccountAssignment,
  BankAccountConstants,
  Banner,
  BannerType,
  changeContributionAccount,
  changeDistributionAccount,
  Extractable,
  extractable,
  getEligibleContributionAccounts,
  getEligibleDistributionAccounts,
  hasPendingContribution,
  hasPendingDistribution,
  hasUnsubmittedChangesContribution,
  hasUnsubmittedChangesDistribution,
  IBankAccount,
  IBaseStore,
  isSomething,
  nothing,
  Optional,
  optionalDateUTCDashForNullFormatter,
  optionalStringDashForNullFormatter,
  selectHasUSBankAccount,
  some,
  useGridExtensions,
} from "common";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AddBankAccountDialog } from "../AddBankAccountDialog/AddBankAccountDialog";
import { ReviewAndSignDialog } from "../ReviewAndSignDialog/ReviewAndSignDialog";
import styles from "./AccountAssignmentGrid.module.scss";
import { AddAccountCell } from "./Renderers/AddAccountCell";
import { AddAccountHeader } from "./Renderers/AddAccountHeader";

export interface IAddAccounCellProps
  extends ICellRendererParams<AccountAssignmentRow> {
  openBankAccountDialog: (isContribution: boolean) => void;
}
const SubmitChangesPrompt = () => {
  return (
    <Banner bannerType={BannerType.WARNING}>
      {BankAccountConstants.SUBMIT_CHANGES_PROMPT}
    </Banner>
  );
};

export interface IAccountAssignmentGridProps {
  purposesWithError: BankAccountAssignment[];
  retryFunction: () => void;
}

export const AccountAssignmentGrid = (props: IAccountAssignmentGridProps) => {
  const { onGridReady, resizeColumns } = useGridExtensions();
  const dispatch = useDispatch();

  const {
    selectedContributionAccount,
    selectedDistributionAccount,
    unsubmittedChanges,
  } = useSelector((store: IBaseStore) => store.bankAccounts);

  const eligibleContributionAccounts = useSelector(
    getEligibleContributionAccounts
  );
  const eligibleDistributionAccounts = useSelector(
    getEligibleDistributionAccounts
  );
  const unsubmittedContribution = useSelector(
    hasUnsubmittedChangesContribution
  );
  const unsubmittedDistribution = useSelector(
    hasUnsubmittedChangesDistribution
  );
  const hasPendingContributionAccount = useSelector(hasPendingContribution);
  const hasPendingDistributionAccount = useSelector(hasPendingDistribution);

  const hasUSBankAccount = useSelector((state: IBaseStore) =>
    selectHasUSBankAccount(state)
  );

  const hasUnsubmittedChanges = useMemo(() => {
    return Object.values(unsubmittedChanges).some(Boolean);
  }, [unsubmittedChanges]);
  const [isAddBankAccountDialogOpen, setIsAddBankAccountDialogOpen] =
    useState<boolean>(false);
  const [isReviewAndSignDialogOpen, setIsReviewAndSignDialogOpen] =
    useState<boolean>(false);

  const [isContribution, setIsContribution] = useState<boolean>(false);

  const openBankAccountDialog = useCallback(
    (isContrbution: boolean) => {
      setIsContribution(isContrbution);
      setIsAddBankAccountDialogOpen(true);
    },
    [setIsContribution, setIsAddBankAccountDialogOpen]
  );

  const openReviewAndSignDialog = () => {
    setIsReviewAndSignDialogOpen(true);
  };

  const topPinnedRowData: AccountAssignmentRow[] = useMemo(
    () => [
      {
        purpose: BankAccountAssignment._,
        bankName: some("_"),
        bankAccount: some("alert"),
        eligibleAccounts: [],
        lastSubmitted: some(new Date()),
        disabled: true,
        unsubmitted: false,
        requestId: nothing,
      },
    ],
    []
  );

  const columnDefs: ColDef<AccountAssignmentRow>[] = useMemo(() => {
    return [
      {
        field: "purpose",
        minWidth: 240,
        maxWidth: 240,
        resizable: false,
        cellRenderer: (
          props: CustomCellRendererProps<
            AccountAssignmentRow,
            BankAccountAssignment
          >
        ) => {
          const { bankAccount, purpose } = props.data ?? {};
          return bankAccount &&
            isSomething<string>(bankAccount) &&
            bankAccount.value === "alert" ? (
            <SubmitChangesPrompt />
          ) : (
            purpose
          );
        },
      },
      {
        field: "bankAccount",
        minWidth: 380,
        maxWidth: 380,
        resizable: false,
        valueFormatter: optionalStringDashForNullFormatter,
        cellRenderer: (
          props: CustomCellRendererProps<
            AccountAssignmentRow,
            Optional<string>
          > & { purposesWithError: BankAccountAssignment[] }
        ) => {
          const { purpose, hasUSBankAccount } = props.data ?? {};
          const isContribution = purpose === BankAccountAssignment.CONTRIBUTION;

          const options = (props.data?.eligibleAccounts ?? []).map(
            ({ main: account }) => ({
              value: account.bankAccountUniqueId,
              text: `${
                account.bankName.length > 20
                  ? account.bankName.substring(0, 20)
                  : account.bankName
              }...${account.accountNumber.slice(-4)}`,
              disabled: false,
            })
          );

          if (options.length === 0 && isContribution) {
            options.push({
              value: BankAccountConstants.NO_US_ACCOUNT,
              text: BankAccountConstants.NO_US_ACCOUNT,
              disabled: false,
            });
          }

          options.push({
            value: BankAccountConstants.NO_ACCOUNT_ASSIGNED,
            text: BankAccountConstants.NO_ACCOUNT_ASSIGNED,
            disabled: true,
          });

          const dispatcherFn = isContribution
            ? changeContributionAccount
            : changeDistributionAccount;

          const bankAccountUniqueId = () => {
            if (hasUSBankAccount && isSomething(hasUSBankAccount)) {
              if (hasUSBankAccount.value === false)
                return BankAccountConstants.NO_US_ACCOUNT;
            }

            if (props.value && isSomething(props.value)) {
              return props.value.value;
            }
            return BankAccountConstants.NO_ACCOUNT_ASSIGNED;
          };

          return (
            <Select
              color="secondary"
              error={purpose && props.purposesWithError.includes(purpose)}
              value={bankAccountUniqueId()}
              fullWidth
              disabled={props.data?.disabled}
              onChange={(e: SelectChangeEvent) =>
                dispatch(
                  dispatcherFn({
                    bankAccountUniqueId: e.target.value,
                    isEligibleForBothAssignments: undefined,
                  })
                )
              }
            >
              {options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          );
        },
        cellRendererParams: {
          purposesWithError: props.purposesWithError,
        },
      },
      {
        field: "lastSubmitted",
        valueFormatter: optionalDateUTCDashForNullFormatter,
        minWidth: 240,
        maxWidth: 240,
        resizable: false,
      },
      {
        headerComponent: AddAccountHeader,
        minWidth: 240,
        maxWidth: 240,
        headerComponentParams: {
          openReviewAndSignDialog,
          disabled: !hasUnsubmittedChanges,
        },
        cellRenderer: AddAccountCell,
        cellRendererParams: {
          openBankAccountDialog: openBankAccountDialog,
        } as IAddAccounCellProps,
      },
    ];
  }, [
    openBankAccountDialog,
    dispatch,
    hasUnsubmittedChanges,
    props.purposesWithError,
  ]);

  const rowData: AccountAssignmentRow[] = useMemo(() => {
    let extractableContribution: Extractable<IBankAccount> =
      extractable(nothing);
    let extractableDistribution: Extractable<IBankAccount> =
      extractable(nothing);
    if (isSomething(selectedContributionAccount)) {
      extractableContribution = extractable(
        some(selectedContributionAccount.value.main)
      );
    }
    if (isSomething(selectedDistributionAccount)) {
      extractableDistribution = extractable(
        some(selectedDistributionAccount.value.main)
      );
    }

    const lastSubmittedContributionDate: Optional<Optional<Date>> = extractable(
      selectedContributionAccount
    ).extractByKey("lastUpdated");
    const lastSubmittedDistributionDate: Optional<Optional<Date>> = extractable(
      selectedDistributionAccount
    ).extractByKey("lastUpdated");

    return [
      {
        purpose: BankAccountAssignment.CONTRIBUTION,
        bankName: extractableContribution.extractByKey("bankName"),
        bankAccount: extractableContribution.extractByKey(
          "bankAccountUniqueId"
        ),
        eligibleAccounts: eligibleContributionAccounts,
        lastSubmitted: isSomething(lastSubmittedContributionDate)
          ? lastSubmittedContributionDate.value
          : nothing,
        disabled: hasPendingContributionAccount,
        unsubmitted: unsubmittedContribution,
        requestId: extractable(selectedContributionAccount).extractByKey(
          "requestId"
        ),
        hasUSBankAccount,
      },
      {
        purpose: BankAccountAssignment.DISTRIBUTION,
        bankName: extractableDistribution.extractByKey("bankName"),
        bankAccount: extractableDistribution.extractByKey(
          "bankAccountUniqueId"
        ),
        eligibleAccounts: eligibleDistributionAccounts,
        lastSubmitted: isSomething(lastSubmittedDistributionDate)
          ? lastSubmittedDistributionDate.value
          : nothing,
        disabled: hasPendingDistributionAccount,
        unsubmitted: unsubmittedDistribution,
        requestId: extractable(selectedDistributionAccount).extractByKey(
          "requestId"
        ),
      },
    ];
  }, [
    hasUSBankAccount,
    eligibleContributionAccounts,
    eligibleDistributionAccounts,
    hasPendingContributionAccount,
    hasPendingDistributionAccount,
    selectedContributionAccount,
    selectedDistributionAccount,
    unsubmittedContribution,
    unsubmittedDistribution,
  ]);

  return (
    <Box className={`ag-theme-alpine`} id={styles.accountGrid} width="100%">
      <AgGridReact<AccountAssignmentRow>
        suppressContextMenu={true}
        tooltipMouseTrack={true}
        tooltipShowDelay={150}
        tooltipInteraction={true}
        defaultColDef={{
          tooltipValueGetter: (
            params: ITooltipParams<AccountAssignmentRow>
          ) => {
            const { data: { purpose, disabled, requestId: reqId } = {} } =
              params;
            if (purpose === BankAccountAssignment._) return;

            const requestId = reqId && isSomething(reqId) ? reqId.value : "";

            return disabled
              ? `Your account is under review by Treasury (Case Id: ${requestId})`
              : "";
          },
          tooltipComponent: (props: CustomTooltipProps) => {
            return <Card className={styles.rowTooltip}>{props.value}</Card>;
          },
          resizable: false,
          sortable: false,
          suppressHeaderMenuButton: true,
          suppressMovable: true,
        }}
        pinnedTopRowData={hasUnsubmittedChanges ? topPinnedRowData : []}
        columnDefs={columnDefs}
        rowData={rowData}
        domLayout="autoHeight"
        onGridReady={onGridReady}
        onRowDataUpdated={resizeColumns}
        onGridSizeChanged={resizeColumns}
        suppressRowDrag
        suppressCellFocus
        gridOptions={{
          getRowClass: (params: RowClassParams<AccountAssignmentRow>) => {
            if (params.node.isRowPinned()) {
              return styles.CustomRow;
            }
            if (params.data?.unsubmitted) {
              return styles.UnsubmittedChanges;
            }
          },
        }}
      />
      <AddBankAccountDialog
        open={isAddBankAccountDialogOpen}
        setOpen={setIsAddBankAccountDialogOpen}
        isContribution={isContribution}
      />
      <ReviewAndSignDialog
        open={isReviewAndSignDialogOpen}
        setOpen={setIsReviewAndSignDialogOpen}
        retryFunction={props.retryFunction}
      />
    </Box>
  );
};
