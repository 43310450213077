import dayjs from "dayjs";
import Cookies from "js-cookie";
import { User } from "oidc-client-ts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AuthContextProps } from "react-oidc-context";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { DataLoadStatus } from "../constants/enums";
import { RelativePath, SearchParam } from "../constants/Paths";
import { StringConstants } from "../constants/StringConstants";
import { reqAllEntitlements } from "../redux/actions/entitlementActions";
import { EnvironmentResolver } from "../services/environmentResolver";
import { isNotRequested } from "./dataLoadUtils";

const AUTH_TOKEN_COOKIE_NAME = "authToken";
const LOGIN_REDIRECT_PATH_COOKIE_NAME = "loginRedirectPath";

export const refreshTokenIfNearExpiration = async (auth: AuthContextProps) => {
  const expiration = auth.user?.expires_at;
  const twoMinutesFromNow = dayjs().add(2, "minutes").unix();
  if (expiration && expiration < twoMinutesFromNow) {
    const user = await auth.signinSilent();
    if (user) {
      auth.user = user;
      setAccessTokenAsCookie(user.access_token);
    }
  }
};

export const onSigninCallback = (user: void | User) => {
  if (user) {
    setAccessTokenAsCookie(user.access_token);
    const redirectPath = retrievePathForLoginRedirect() ?? RelativePath.HOME;
    window.location.replace(redirectPath);
  }
};

export const setAccessTokenAsCookie = (accessToken: string) => {
  Cookies.set(AUTH_TOKEN_COOKIE_NAME, `Bearer ${accessToken}`, {
    sameSite: "Strict",
    secure: true,
  });
};

export const storePathForLoginRedirect = (pathName: string) => {
  Cookies.set(LOGIN_REDIRECT_PATH_COOKIE_NAME, pathName, {
    sameSite: "Strict",
    secure: true,
    // don't want this value to linger if something goes wrong, clear after 2 minutes
    expires: dayjs().add(2, "minute").toDate(),
  });
};

export const retrievePathForLoginRedirect = () => {
  const redirectPath = Cookies.get(LOGIN_REDIRECT_PATH_COOKIE_NAME);
  // clear cookie so it doesn't stick around to affect future behavior
  Cookies.remove(LOGIN_REDIRECT_PATH_COOKIE_NAME);
  return redirectPath;
};

export const userIsValid = (user: User | null | undefined) => {
  if (user === null || user === undefined) return false;
  return !user.expired;
};

export const useFetchEntitlements = (
  user: User | null | undefined,
  requiredEntitlementLoadStatuses: DataLoadStatus[]
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // User may be undefined on initial context load.
    // We don't want to do anything until we know more.
    if (user === undefined) {
      return;
    }
    if (!userIsValid(user) && location.pathname !== RelativePath.LOGIN) {
      // Send user back to login page if user access token expired or no user.
      storePathForLoginRedirect(location.pathname + location.search);
      navigate(RelativePath.LOGIN);
    } else if (
      // Trigger a request to get entitlements if not already fetched.
      userIsValid(user) &&
      isNotRequested(...requiredEntitlementLoadStatuses)
    ) {
      dispatch(reqAllEntitlements());
    }
  }, [user, requiredEntitlementLoadStatuses, location, navigate, dispatch]);
};

interface SSOLoginTools {
  isAuthInProgress: boolean;
  initiateSeamlessSSO: () => void;
}

export const useSSOLogin = (auth: AuthContextProps): SSOLoginTools => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );
  const [isAuthInProgress, setIsAuthInProgress] = useState(false);

  const initiateSeamlessSSO = useCallback(async () => {
    if (
      !auth.isAuthenticated && // skip if user is already authenticated
      !auth.activeNavigator && // skip if user is authenticating now
      !auth.isLoading && // skip if we're loading user info after authentication
      searchParams.get(SearchParam.FROM_LOGOUT) !== "true" && //skip if user just logged out
      !searchParams.has(SearchParam.ERROR) //skip if login generated error
    ) {
      setIsAuthInProgress(true);
      auth.signinRedirect({
        extraQueryParams: {
          identity_provider: StringConstants.BLACKSTONE_IDP_NAME,
        },
      });
    } else if (
      auth.isAuthenticated &&
      searchParams.get(SearchParam.FROM_LOGOUT) === "true"
    ) {
      // remove any user from the context if we just tried to logout
      setIsAuthInProgress(false);
      auth.removeUser();
    } else if (auth.isAuthenticated) {
      // move the user into the application if they are authenticated
      setIsAuthInProgress(true);
      const redirectPath = retrievePathForLoginRedirect();
      navigate(redirectPath ?? RelativePath.HOME, { replace: true });
    } else if (auth.isLoading || auth.activeNavigator !== undefined) {
      // otherwise we're waiting for things to resolve
      setIsAuthInProgress(true);
    } else {
      // otherwise nothing is doing, we haven't started any auth yet
      setIsAuthInProgress(false);
    }
  }, [auth, navigate, searchParams]);

  return {
    isAuthInProgress,
    initiateSeamlessSSO,
  };
};

export const usePassiveCheck = () => {
  const [passiveCheckStatus, setPassiveCheckStatus] = useState<DataLoadStatus>(
    DataLoadStatus.NOT_REQUESTED
  );

  useEffect(() => {
    const hitPassiveCheck = async () => {
      if (passiveCheckStatus === DataLoadStatus.NOT_REQUESTED) {
        setPassiveCheckStatus(DataLoadStatus.LOADING);
        try {
          const passiveResponse = await fetch(
            EnvironmentResolver.ENV.REACT_APP_PASSIVE_CHECK_URL
          );
          setPassiveCheckStatus(
            passiveResponse.ok
              ? DataLoadStatus.SUCCESSFUL
              : DataLoadStatus.UNSUCCESSFUL
          );
        } catch {
          setPassiveCheckStatus(DataLoadStatus.UNSUCCESSFUL);
        }
      }
    };

    hitPassiveCheck();
  }, [passiveCheckStatus]);

  return passiveCheckStatus;
};
