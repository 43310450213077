// TODO: this file in general has portions of the data models
//  we will need for bank accounts. These models are not yet complete
//  but can be added to as more dev work is done

import { DataLoadStatus, EntityType } from "../constants/enums";
import { IESignature } from "./dataTypes";
import { ClientId } from "./electionDataTypes";
import { Optional } from "./typeUtils";

export interface IBankAccountsCapabilities {
  clientCapabilities: BankClientCapabilityMap;
  globalCapabilities: string[];
}

export type BankClientCapabilityMap = {
  [clientId: ClientId]: IBankAccountClient;
};

export interface IBankAccountClient {
  clientName: string;
  investmentVehicles: IBankAccountInvestmentVehicle[];
}

export interface IBankAccountInvestmentVehicle {
  investmentVehicleId: number;
  name: string;
  capabilities: string[];
}

export interface IBankAccountsEntitlements {
  globalCapabilities: string[];
  permittedBankAccountClients: IBankAccountPermittedClient[];
}

export interface IBankAccountPermittedClient {
  clientId: ClientId;
  clientName: string;
  investmentVehicles: IBankAccountInvestmentVehicle[];
}

export interface BankAccountsResponse {
  bankAccounts: GPBankAccount[];
  pendingBankAccounts: GPPendingBankAccount[];
  investmentVehicles: GPInvestmentVehicle[];
}

export interface GPBankAccount {
  investmentVehicle: NamedEntity;
  bankAccount: WSSBankAccount;
}

export interface GPInvestmentVehicle {
  axiomId: number;
  entityType: EntityType;
  mdmId: number;
  name: string;
}

export interface WSSBankAccount {
  main: WSSBankAccountDetail;
  intermediary: Optional<WSSBankAccountDetail>;
  assignments: BankAccountAssignment[];
}

export interface IBankAccount {
  bankAccountUniqueId: string;
  bankName: string;
  bankId: string;
  bankIdType: BankIdType;
  accountType: AccountType;
  accountHolderName: string;
  accountNumber: string;
  accountNumberType: AccountNumberType;
  country: string;
  currency: string;
}

export type WSSBankAccountDetail = IBankAccount & {
  routingNumber: string;
  swiftCode: string;
  lastModified: Date;
  paymentRule: string;
};

export interface NamedEntity {
  id: number;
  name: string;
}

export enum BankAccountAssignment {
  _ = "_", // Not an actual Bank Account Assignment type, used as way to get around AgGrid limitations and display a custom row
  CONTRIBUTION = "Contribution",
  DISTRIBUTION = "Distribution",
}

export enum AccountType {
  CHECKING = "Checking",
  SAVINGS = "Savings",
  INTERMEDIARY = "Intermediary",
  CORRESPONDENT = "Correspondent",
}

export enum AccountNumberType {
  ACCOUNT_NUMBER = "Account Number",
  IBAN = "IBAN",
  BBAN = "BBAN",
}

export enum BankIdType {
  ABA_ROUTING_NUMBER = "ABA/Routing Number",
  SWIFT = "SWIFT",
  SORT = "Sort",
  OTHER = "Other Routing Codes",
}

export interface GPPendingBankAccount {
  investmentVehicle: NamedEntity;
  main: PendingBankAccountDetail;
  intermediary: Optional<PendingBankAccountDetail>;
  assignments: BankAccountAssignment[];
  effectiveDate: Optional<Date>;
  submittedDate: Optional<Date>;
  requestId: Optional<number>;
}

export interface UserAddedBankAccount {
  main: IBankAccount;
  intermediary: Optional<IBankAccount>;
  assignment:
    | BankAccountAssignment.CONTRIBUTION
    | BankAccountAssignment.DISTRIBUTION;
  eligibleForBothAssignments: boolean;
}

export type PendingBankAccountDetail = IBankAccount;

export interface ICountry {
  id: number;
  name: string;
  countryCode: string;
}

export interface ICurrency {
  id: number;
  name: string;
  currencyCode: string;
  currencySymbol: string;
}

export interface IBankAccountsStore {
  isBankAccountsAdminMode: boolean;
  activeBankAccountsClient: Optional<IBankAccountPermittedClient>;
  bankAccountsLoadStatus: DataLoadStatus;
  bankAccountsInvestmentVehicleId: Optional<number>;
  serverData: BankAccountsResponse;
  selectedContributionAccount: Optional<SelectableBankAccount>;
  selectedDistributionAccount: Optional<SelectableBankAccount>;
  userAddedAccounts: UserAddedBankAccount[];
  countries: ICountry[];
  countriesLoadStatus: DataLoadStatus;
  currencies: ICurrency[];
  currenciesLoadStatus: DataLoadStatus;
  unsubmittedChanges: {
    [BankAccountAssignment.CONTRIBUTION]: boolean;
    [BankAccountAssignment.DISTRIBUTION]: boolean;
  };
  initialState: Optional<
    Pick<
      IBankAccountsStore,
      | "selectedContributionAccount"
      | "selectedDistributionAccount"
      | "userAddedAccounts"
      | "unsubmittedChanges"
    >
  >;
}

export type AddBankAccountState = PendingBankAccountDetail & {
  confirmAccountId: string;
  hasIntermediaryAccount: boolean;
  intermediaryAccount: PendingBankAccountDetail & {
    confirmAccountId: string;
  };
};

export type SelectableBankAccount = {
  main: IBankAccount;
  intermediary: Optional<IBankAccount>;
  assignments: BankAccountAssignment[];
  lastUpdated: Optional<Date>;
  requestId: number;
  investmentVehicle?: NamedEntity;
};

export type InvestmentVehicleBankAccount = {
  investmentVehicleMdmId: number;
  bankAccountAssignment: BankAccountAssignment[];
  main: IBankAccount;
  intermediary?: IBankAccount;
};

export type AgreementPdfRequest = {
  investmentVehicleBankAccounts: InvestmentVehicleBankAccount[];
  documents: {
    id: string;
    name: string;
    type: string;
  }[];
  effectiveDate: Date;
  requestedBy: string;
  eSignature: IESignature;
};

export interface AccountAssignmentRow {
  purpose: BankAccountAssignment;
  bankName: Optional<string>;
  bankAccount: Optional<string>;
  eligibleAccounts: SelectableBankAccount[];
  lastSubmitted: Optional<Date>;
  disabled: boolean;
  unsubmitted: boolean;
  requestId: Optional<number>;
  hasUSBankAccount?: Optional<boolean>;
}

export type ChangeAccountAction = {
  payload: {
    bankAccountUniqueId: string;
    isEligibleForBothAssignments?: boolean;
  };
};
