import { ValueFormatterParams } from "ag-grid-enterprise";

import { IEntity } from "../types/dataTypes";
import { isSomething } from "../types/typeGuards";
import { Optional } from "../types/typeUtils";
import {
  formatDateAndTimeMMMDDYYYY,
  formatDateMMMDDYYYY,
  formatDateMMMDDYYYYOrNull,
  formatDateTimeUTCMMMDDYYYY,
  formatPercentNoDecimal,
  formatPercentToOneDecimal,
  getCurrencyFormattedValue,
  getCurrencyFormattedValueWithZeroDefault,
  getEntityLabel,
  getFormattedDateMMMDDYYYYOrNA,
  getFormattedDateMMMYYYY,
  getFormattedDateWithDashForPastEvents,
} from "./formatters";

export const currencyFormatter = (params: ValueFormatterParams) => {
  return getCurrencyFormattedValue(params.value);
};
export const currencyFormatterWithZeroDefault = (
  params: ValueFormatterParams
) => {
  return getCurrencyFormattedValueWithZeroDefault(params.value);
};

export const currencyFormatterForOptionalNumberWithMiddleDashDefault = (
  params: ValueFormatterParams
) => {
  const hasValue = isSomething(params.value);

  return hasValue
    ? currencyFormatterWithZeroDefault({
        ...params,
        value: params.value.value,
      })
    : "-";
};

export const operatorFormatterForOptionalStringWithEmptyDefault = (
  params: ValueFormatterParams
) => {
  const hasValue = isSomething(params.value);

  return hasValue
    ? currencyFormatterWithZeroDefault({
        ...params,
        value: params.value.value,
      })
    : "";
};

export const dateFormatterWithDash = (params: ValueFormatterParams) => {
  return formatDateMMMDDYYYYOrNull(params.value);
};
export const dateFormatterWithEmpty = (params: ValueFormatterParams) => {
  return formatDateMMMDDYYYY(params.value);
};
export const dateAndTimeFormatterWithEmpty = (params: ValueFormatterParams) => {
  return formatDateAndTimeMMMDDYYYY(params.value);
};
export const dateFormatterWithPastEvents = (params: ValueFormatterParams) => {
  return getFormattedDateWithDashForPastEvents(params.value);
};
export const dateFormatterMMYYWithEmpty = (params: ValueFormatterParams) => {
  return getFormattedDateMMMYYYY(params.value);
};
export const dateFormatterMMMDDYYYY = (params: ValueFormatterParams) => {
  return formatDateMMMDDYYYY(params.value);
};
export const dateFormatterMMMDDYYYYOrNA = (params: ValueFormatterParams) => {
  return getFormattedDateMMMDDYYYYOrNA(params.value);
};
export const toLocaleString = (params: ValueFormatterParams) => {
  return params.value.toLocaleString("en-US");
};
export const toLocaleStringOrNA = (params: ValueFormatterParams) => {
  return params.value ? params.value.toLocaleString("en-US") : "N/A";
};
export const toLocaleStringOrDashForChildRows = (
  params: ValueFormatterParams
) => {
  return params.node?.group || params.node?.rowPinned
    ? params.value.toLocaleString("en-US")
    : "-";
};
export const toDocCatTypeNameString = (params: ValueFormatterParams) => {
  return params.value.category.name.toLocaleString("en-US");
};
export const toDocTypeNameString = (params: ValueFormatterParams) => {
  return params.value.name.toLocaleString("en-US");
};
export const toCommaDelineatedString = (params: ValueFormatterParams) => {
  if (params.value && params.value.length > 0) {
    return params.value.join(", ");
  }
  return "N/A";
};
export const toEntityCommaDelineatedString = (params: ValueFormatterParams) => {
  if (params.value && params.value.length > 0) {
    const entityNames = params.value.map((entity: IEntity) => entity.name);
    return entityNames.join(", ");
  }
  return "N/A";
};
export const toPartnershipEINCommaDelineatedString = (
  params: ValueFormatterParams
) => {
  if (params.value && params.value.length > 0) {
    const eins = params.value.map((entity: IEntity) => {
      return getEntityLabel(entity);
    });
    return eins.join(", ");
  }
  return "N/A";
};
export const toEntityNameString = (params: ValueFormatterParams) => {
  if (params.value) {
    return params.value.name;
  } else {
    return "N/A";
  }
};

export const toPercentWithOneDecimal = (params: ValueFormatterParams) => {
  return formatPercentToOneDecimal(params.value);
};

export const toPercentNoDecimal = (params: ValueFormatterParams) => {
  return formatPercentNoDecimal(params.value);
};

export const optionalStringDashForNullFormatter = <TData>(
  params: ValueFormatterParams<TData, Optional<string>>
) => {
  return params.value && isSomething(params.value) ? params.value.value : "-";
};

export const optionalDateDashForNullFormatter = <TData>(
  params: ValueFormatterParams<TData, Optional<Date>>
) => {
  return params.value && isSomething(params.value)
    ? formatDateMMMDDYYYY(params.value.value)
    : "-";
};

export const optionalDateUTCDashForNullFormatter = <TData>(
  params: ValueFormatterParams<TData, Optional<Date>>
) => {
  return params.value && isSomething(params.value)
    ? formatDateTimeUTCMMMDDYYYY(params.value.value)
    : "-";
};
