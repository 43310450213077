import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import { ElectionsLabels } from "common";
import React from "react";

import styles from "./ElectionDialog.module.scss";

export interface IElectionDialogProps {
  id?: string;
  title: string;
  content: JSX.Element;
  open: boolean;
  handleNext: () => void;
  handleClose?: () => void;
  nextButtonLabel?: string;
  cancelButtonLabel?: string;
  nextButtonDisabled?: boolean;
  showNextButton?: boolean;
  isErrorDialog?: boolean;
  showOverlayComponent?: boolean;
  overlayComponent?: JSX.Element;
}

export const ElectionDialog = (props: IElectionDialogProps) => {
  const {
    id,
    content,
    handleNext,
    handleClose,
    nextButtonLabel = ElectionsLabels.I_AGREE,
    cancelButtonLabel = ElectionsLabels.CANCEL,
    nextButtonDisabled = false,
    open,
    title,
    showNextButton = true,
    isErrorDialog = false,
    showOverlayComponent = false,
    overlayComponent = <></>,
  } = props;

  const onClose = () => {
    handleClose?.();
  };

  return (
    <Dialog id={id ?? ""} open={open} className={styles.dialog}>
      <div className={styles.headerAndContent}>
        <DialogTitle
          component="div"
          className={
            isErrorDialog
              ? `${styles.header} ${styles.errorHeader}`
              : styles.header
          }
        >
          <h2>{title}</h2>
          <IconButton className={styles.close} onClick={onClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={styles.dialogContentWrapper}>
          <div className={styles.dialogContent}>{content}</div>
        </DialogContent>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.dialogFooter}>
        <Button
          color={showNextButton ? "secondary" : "primary"}
          onClick={onClose}
        >
          {cancelButtonLabel}
        </Button>
        {showNextButton && (
          <Button
            type="submit"
            className={styles.agree}
            onClick={handleNext}
            disabled={nextButtonDisabled}
          >
            {nextButtonLabel}
          </Button>
        )}
      </div>
      {showOverlayComponent && (
        <div className={styles.overlay}>{overlayComponent}</div>
      )}
    </Dialog>
  );
};
