import { Font, StyleSheet } from "@react-pdf/renderer";

import colors from "../../../../styles/_colors.scss";

Font.register({
  family: "Sanomat",
  fonts: [
    { src: "/assets/fonts/sanomat/Sanomat-Regular.ttf" },
    { src: "/assets/fonts/sanomat/Sanomat-Medium.ttf", fontWeight: 400 },
    { src: "/assets/fonts/sanomat/Sanomat-Bold.ttf", fontWeight: 500 },
  ],
});

Font.register({
  family: "Guardian Sans",
  fonts: [
    { src: "/assets/fonts/guardian/GuardianSans-Regular.ttf" },
    { src: "/assets/fonts/guardian/GuardianSans-Medium.ttf", fontWeight: 400 },
    {
      src: "/assets/fonts/guardian/GuardianSans-SemiBold.ttf",
      fontWeight: 500,
    },
    { src: "/assets/fonts/guardian/GuardianSans-Bold.ttf", fontWeight: 600 },
  ],
});

Font.register({
  family: "DancingScript",
  fonts: [
    { src: "/assets/fonts/dancing/DancingScript-Regular.ttf" },
    { src: "/assets/fonts/dancing/DancingScript-Bold.ttf", fontWeight: 600 },
  ],
});

export const styles = StyleSheet.create({
  page: {
    padding: 32,
  },
  h1: {
    textAlign: "left",
    fontSize: 22,
    fontFamily: "Sanomat",
    paddingBottom: 8,
  },
  h2: {
    textAlign: "left",
    fontSize: 16,
    paddingBottom: 8,
    fontFamily: "Sanomat",
  },
  h3: {
    textAlign: "left",
    fontSize: 14,
    paddingBottom: 8,
    fontFamily: "Sanomat",
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 500,
    paddingBottom: 8,
    fontFamily: "Guardian Sans",
  },
  text: {
    fontSize: 12,
    fontFamily: "Guardian Sans",
  },
  link: {
    color: colors.copper,
    textDecoration: "underline",
  },
  paddingTop: {
    paddingTop: 8,
  },
  paragraph: {
    fontSize: 12,
    paddingBottom: 8,
    fontFamily: "Guardian Sans",
  },
  multipleText: {
    display: "flex",
    fontFamily: "Guardian Sans",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  footer: {
    position: "absolute",
    fontSize: 10,
    textAlign: "left",
    left: 32,
    bottom: 32,
  },
});
